import { useState } from 'react';

export const useSequenceSelection = studyData => {
  const { sequenceSelectionManager } = window.ohif.app;

  const [selectedSequences, setSelectedSequences] = useState(
    sequenceSelectionManager.getSequenceMapping(studyData.studyInstanceUID)
  );

  const setSequence = ({ modality, orientation, sequence }) => {
    setSelectedSequences(sequenceMapping => {
      sequenceMapping.setAtomicMapping(modality, orientation, sequence);
      return sequenceMapping;
    });
  };

  const getSequence = ({ modality, orientation }) => {
    return selectedSequences.selectAtomicMapping({ modality, orientation });
  };

  const getAvailableSequences = () => {
    return studyData.seriesList;
  };

  const getSeriesDescription = seriesInstanceUID => {
    return seriesInstanceUID === 'empty'
      ? 'empty'
      : studyData.seriesList.find(
          series => series.SeriesInstanceUID === seriesInstanceUID
        ).SeriesDescription;
  };

  const saveSequenceMapping = () => {
    sequenceSelectionManager.setSequenceMapping(selectedSequences);
    const saveSequencePromise = sequenceSelectionManager.saveSequenceMapping(
      studyData.studyInstanceUID
    );
    return saveSequencePromise;
  };

  return {
    selectedSequences,
    setSequence,
    getSequence,
    getAvailableSequences,
    getSeriesDescription,
    saveSequenceMapping,
  };
};
