import { AltaTableRow } from '@ohif/ui';
import {
  AdditionalSegmentationPanelComponents,
  StudyContextProvider,
  getSegmentationTypeConfig,
} from '../../../extensions/dicom-segmentation/src/config/AltaSegmentPanelConfig';
import { altaTemplateBuilder } from '../../ui/src/components/studyList/studyListTemplates';

const getModalWidth = (numberOfScreens, padding) => {
  return (100 / numberOfScreens).toFixed(0) - 2 * padding;
};

export const AltaConfigProvider = () => {
  const numberOfScreens = 2;
  const padding = 2;

  return {
    layoutOption: 'left',
    customModalStyle: {
      overlay: {
        justifyContent: 'left',
        paddingLeft: `${padding}%`,
      },
      content: {
        left: '0%',
        width: `${getModalWidth(numberOfScreens, padding)}%`,
      },
    },
    numberOfScreens: 2,
    studyList: {
      tableFilterBuilder: altaTemplateBuilder,
      TableRow: AltaTableRow,
      settings: {
        respectUserRoles: false,
        findEditableStudies: false,
        showStudiesWOReport: false,
        removeUnusableStudies: false,
      },
      showStudySkipper: false,
    },
    studyContextArea: {
      showStudyContext: true,
      showMarkAsInvalid: false,
      segmentationStudyContext: StudyContextProvider,
    },
    segmentations: {
      showLesions: true,
      showZones: true,
      segmentationPanelConfigProvider: getSegmentationTypeConfig,
    },
    hangingProtocol: [
      {
        type: 'sequenceMapping',
        value: { modality: 't2w', orientation: 'tra' },
      },
      {
        type: 'sequenceMapping',
        value: { modality: 'adc', orientation: 'tra' },
      },
      {
        type: 'sequenceMapping',
        value: { modality: 'dwi', orientation: 'tra' },
      },
      {
        type: 'sequenceMapping',
        value: { modality: 't2w', orientation: 'sag' },
      },
    ],
    tabs: ['appointment', 'patient', 'study'],
    canCreateReport: true,
    additionalSegmentationPanelComponents: AdditionalSegmentationPanelComponents,
  };
};
