import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './CustomSingleDatePicker.styl';
import React, { useState } from 'react';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';

export const CustomSingleDatePicker = ({ value, onDateChange }) => {
  const [focused, setFocused] = useState(false);

  return (
    <div>
      <SingleDatePicker
        date={moment(value)} // moment object or null
        onDateChange={onDateChange}
        focused={focused}
        onFocusChange={({ focused }) => setFocused(focused)}
        id="your_unique_id"
        numberOfMonths={1}
        isOutsideRange={() => false}
      />
    </div>
  );
};
