import moment from 'moment';

const parseDate = date => {
  return moment(date, 'MMM DD, YYYY').format('YYYYMMDD');
};

const studyFiltersToUriArgs = filters => {
  const filterAttributeMap = [
    { loc: 'AssignedUser', apiArg: 'assignedUser' },
    { loc: 'PatientID', apiArg: 'patientID' },
    { loc: 'PatientName', apiArg: 'patientName' },
    { loc: 'StudyDescription', apiArg: 'studyDescription' },
    { loc: 'Location', apiArg: 'location' },
    { loc: 'Examination', apiArg: 'examination' },
    { loc: 'LesionState', apiArg: 'lesionState' },
    { loc: 'ZoneState', apiArg: 'zoneState' },
    { loc: 'studyDateFrom', apiArg: 'studyDateFrom', parseItem: parseDate },
    { loc: 'studyDateTo', apiArg: 'studyDateTo', parseItem: parseDate },
    {
      loc: 'appointmentDateFrom',
      apiArg: 'appointmentDateFrom',
      parseItem: parseDate,
    },
    {
      loc: 'appointmentDateTo',
      apiArg: 'appointmentDateTo',
      parseItem: parseDate,
    },
    { loc: 'birthDateFrom', apiArg: 'birthDateFrom', parseItem: parseDate },
    { loc: 'birthDateTo', apiArg: 'birthDateTo', parseItem: parseDate },
    { loc: 'findEditableStudies', apiArg: 'findEditableStudies' },
    { loc: 'removeUnusableStudies', apiArg: 'removeUnusableStudies' },
    { loc: 'showStudiesWOReport', apiArg: 'showStudiesWOReport' },
    { loc: 'respectUserRoles', apiArg: 'respectUserRoles' },
    { loc: 'limit', apiArg: 'limit' },
    { loc: 'offset', apiArg: 'offset' },
  ];

  // remove empty filters and parse to api representation
  const cleanFilterArgs = filterAttributeMap.reduce((acc, curr) => {
    if (
      Object.keys(filters).includes(curr.loc) &&
      filters[curr.loc] !== '' &&
      filters[curr.loc] !== null
    ) {
      let item = filters[curr.loc];
      if (Object.keys(curr).includes('parseItem')) {
        item = curr.parseItem(item);
      }
      acc.push(`${curr.apiArg}=${item}`);
    }
    return acc;
  }, []);

  return cleanFilterArgs.join('&');
};

export { studyFiltersToUriArgs };
