import React, { useCallback, useEffect, useRef, useState } from 'react';

import { loadImage, REFRESH_VIEWPORT_TIMEOUT } from './utils';

export const HeatmapPreviewImage = ({
  label,
  data,
  enableViewport,
  disableViewport,
  setDataURL,
}) => {
  const [viewportElement, setViewportElement] = useState();
  const refreshViewport = useRef(null);

  useEffect(() => {
    enableViewport(viewportElement);

    return () => {
      disableViewport(viewportElement);
    };
  }, [disableViewport, enableViewport, viewportElement]);

  const loadAndUpdateViewports = useCallback(async () => {
    if (!viewportElement) {
      return;
    }

    const { image } = await loadImage({
      stack: data.stack,
      renderOptions: data.renderOptions,
      center: data.center,
      viewportElement,
    });

    // catch failed rendering & rerender
    if (image.getCanvas().toDataURL('image/png').length < 50000) {
      loadAndUpdateViewports();
    }

    setDataURL(image.getCanvas().toDataURL('image/png'));
  }, [viewportElement, refreshViewport]);

  useEffect(() => {
    if (refreshViewport.current !== null) {
      clearTimeout(refreshViewport.current);
    }

    refreshViewport.current = setTimeout(() => {
      refreshViewport.current = null;
      loadAndUpdateViewports();
    }, REFRESH_VIEWPORT_TIMEOUT);
  }, [viewportElement]);

  return (
    <div
      className="mri-preview-image"
      key={`Preview-${label}-${data.stack[data.center]}`}
    >
      <div
        style={{
          position: 'absolute',
          left: '9999px',
        }}
        ref={ref => setViewportElement(ref)}
        key={`Render-${label}-${data.stack[data.center]}`}
      ></div>
    </div>
  );
};
