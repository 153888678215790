import React from 'react';
import { useTranslation } from 'react-i18next';

import { markCaseAsHealty, generateSegmentation } from './operations';
import { useSegmentationConfig } from '../../../../context/SegmentationPanelContext';
import { useViewerConfig } from '../../../../../../../platform/viewer/config/ViewerConfigProvider';
import { SEGMENTATION_ORIGIN } from '../../../constants';

export const SegmentationMissingDialog = ({ segmentationType }) => {
  const [t] = useTranslation('SegmentationPanel');
  const { viewerConfig } = useViewerConfig();

  const { studyInstanceUID, isT2 } = useSegmentationConfig();
  // we have to get segmentation config from ViewerConfigProvider
  const config = viewerConfig.segmentations.segmentationPanelConfigProvider({
    segmentationType,
    segmentationOrigin: SEGMENTATION_ORIGIN.MANUAL,
  });

  return (
    <div className="missing-segmentation">
      <div>{t('We did not find a segmentation')}</div>
      {isT2 && (
        <>
          <div className="block">{`${t('Please choose one action')}:`}</div>
          <div className="button-area">
            {config.missingSegmentationDialog.showMarkAsHealty && (
              <button
                onClick={() => {
                  markCaseAsHealty({ studyInstanceUID, segmentationType });
                }}
              >
                {t('Mark as healty')}
              </button>
            )}
            <button
              onClick={() => {
                generateSegmentation({
                  studyInstanceUID,
                  segmentationType,
                  generateEmpty: true,
                });
              }}
            >
              {t('Generate empty segmentation')}
            </button>
            {config.missingSegmentationDialog.showAISegmentation && (
              <button
                onClick={() => {
                  generateSegmentation({ studyInstanceUID, segmentationType });
                }}
              >
                {t('Generate AI segmentation')}
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};
