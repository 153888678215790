import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from './../../elements/Icon';
import './RoundedButtonGroup.css';
import { useMedia } from './../../hooks';
import {
  CompactRoundedButtonGroupWrapper,
  OpenRoundedButtonGroupWrapper,
} from './RoundedButtonGroupWrapper';

export const RoundedButtonGroup = ({ options, value, onValueChanged }) => {
  const displaySize = useMedia(
    ['(min-width: 2000px)', '(max-width: 1999px)'],
    ['large', 'small'],
    'small'
  );

  const RBGWrapper =
    options.length > 1 && displaySize === 'small'
      ? CompactRoundedButtonGroupWrapper
      : OpenRoundedButtonGroupWrapper;

  const [state, setState] = useState({
    badgeNumbers: [],
  });

  useEffect(() => {
    options.forEach(option => {
      if (option.stateEvent) {
        document.addEventListener(option.stateEvent, onStateEvent);
      }
    });

    return () => {
      options.forEach(option => {
        if (option.stateEvent) {
          document.removeEventListener(option.stateEvent, onStateEvent);
        }
      });
    };
  }, []);

  const onClickOption = newValue => {
    if (value === newValue) {
      newValue = null;
    }

    if (onValueChanged) {
      onValueChanged(newValue);
    }
  };

  const onStateEvent = event => {
    const optionIndex = options.findIndex(o => o.value === event.detail.target);

    if (optionIndex > -1) {
      const badgeNumbers = state.badgeNumbers;
      badgeNumbers[optionIndex] = event.detail.badgeNumber;
      setState({ badgeNumbers });
    }
  };

  const renderButtons = () => {
    const buttons = options.map((option, index) => {
      const className = classnames({
        roundedButtonWrapper: true,
        noselect: true,
        active: value === option.value,
      });

      const optionText = option.label && <span>{option.label}</span>;
      const iconProps =
        typeof option.icon === 'string' ? { name: option.icon } : option.icon;

      const bottomLabel = option.bottomLabel && (
        <div className="bottomLabel">{option.bottomLabel}</div>
      );

      let badgeNumber = state.badgeNumbers[index];
      const badgeNumberOverflow = String(badgeNumber).length > 2;
      badgeNumber = badgeNumber
        ? badgeNumberOverflow
          ? 99
          : badgeNumber
        : null;

      return (
        <div
          key={index}
          className={className}
          onClick={() => onClickOption(option.value)}
        >
          <div className="roundedButton">
            {optionText}
            {badgeNumber && (
              <div className="badgeNumber-container">
                <span className="badgeNumber">
                  {badgeNumber}
                  {badgeNumberOverflow && '+'}
                </span>
              </div>
            )}
            {iconProps && <Icon {...iconProps} />}
          </div>
          {bottomLabel}
        </div>
      );
    });

    const activeIndex = options.findIndex(option => option.value === value);

    return { buttons, activeIndex };
  };

  return <RBGWrapper {...renderButtons()} />;
};

RoundedButtonGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
      stateEvent: PropTypes.string,
      icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
      ]),
    })
  ),
  value: PropTypes.string,
  onValueChanged: PropTypes.func,
};
