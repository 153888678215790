import classnames from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from './../../elements/Icon';
import './RoundedButtonGroup.css';

const ExtendedCompactRoundedButtonGroupWrapper = ({ buttons, detailRef }) => {
  return (
    <div ref={detailRef} className="CompactRoundedButtonGroup">
      {buttons}
    </div>
  );
};

const NoSelectedOptionPlaceholder = () => {
  const [t] = useTranslation('Header');

  return (
    <div className="roundedButtonWrapper">
      <div className="roundedButton">
        <div className="text">{t('No Selection')}</div>
      </div>
    </div>
  );
};

const CompactRoundedButtonGroupWrapper = ({ buttons, activeIndex }) => {
  const [extended, setExtended] = useState(false);
  const detailRef = useRef(null);
  const closeRef = useRef(null);

  useEffect(() => {
    if (extended) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [extended]);

  const handleClickOutside = event => {
    if (
      detailRef.current &&
      (!detailRef.current.contains(event.target) &&
        !closeRef.current.contains(event.target))
    ) {
      setExtended(false);
    }
  };

  const RoundButtonDropDown = () => {
    return (
      <div
        className="roundedButtonWrapper"
        onClick={() => setExtended(!extended)}
        ref={closeRef}
        key="rounded-button-dropdown"
      >
        <div className="roundedButton">
          <Icon name={extended ? 'arrow-up' : 'arrow-down'} />
        </div>
      </div>
    );
  };

  const selectedOption =
    activeIndex !== -1 ? buttons[activeIndex] : <NoSelectedOptionPlaceholder />;

  return (
    <div
      className={classnames(
        'RoundedButtonGroup',
        'clearfix center-table compact'
      )}
    >
      <div className="OpenRoundedButtonGroup">
        {[selectedOption, RoundButtonDropDown()]}
      </div>
      {extended && (
        <ExtendedCompactRoundedButtonGroupWrapper
          buttons={buttons}
          detailRef={detailRef}
        />
      )}
    </div>
  );
};

const OpenRoundedButtonGroupWrapper = ({ buttons }) => {
  return (
    <div className={classnames('RoundedButtonGroup', 'clearfix center-table')}>
      <div className="OpenRoundedButtonGroup">{buttons}</div>
    </div>
  );
};

export { CompactRoundedButtonGroupWrapper, OpenRoundedButtonGroupWrapper };
