import { checkIfSeriesIsEditable } from '../../../../../platform/viewer/src/utils/checkIfSeriesEditable';
import { SEGMENTATION_ORIGIN, SEGMENTATION_TYPE } from '../constants';

export const segmentationTypeToSegmentationString = segmentationType => {
  switch (segmentationType) {
    case SEGMENTATION_TYPE.LESION:
      return 'Lesions';
    case SEGMENTATION_TYPE.ZONE:
      return 'Prostate Volumes';

    default:
      break;
  }
};

export const generateSegmentTitle = ({ segmentationType, segmentIndex }) => {
  switch (segmentationType) {
    case SEGMENTATION_TYPE.LESION:
      return { title: 'Lesion', subtitle: segmentIndex + 1 };
    case SEGMENTATION_TYPE.ZONE:
      if (segmentIndex === 0)
        return { title: 'Transitional Zone', subtitle: null };
      else return { title: 'Peripheral Zone', subtitle: null };

    default:
      return { title: 'Unknown', subtitle: null };
  }
};

export const dicomFieldToSegmentationType = dicomField => {
  switch (dicomField) {
    case 'Lesion':
      return SEGMENTATION_TYPE.LESION;
    case 'Organ':
      return SEGMENTATION_TYPE.ZONE;
    case 'Zone':
      return SEGMENTATION_TYPE.ZONE;
    default:
      break;
  }
};

export const deriveSegmentationOrigin = ({ metadata }) => {
  const isEditable = checkIfSeriesIsEditable(metadata);

  return isEditable
    ? SEGMENTATION_ORIGIN.MANUAL
    : SEGMENTATION_ORIGIN.AUTOMATIC;
};
