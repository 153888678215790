import { Svg } from '@ohif/ui';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { SvgLoader, SvgProxy } from 'react-svgmt';

import { useTranslation } from 'react-i18next';

import { v4 } from 'uuid';

import './ProstateZoneSelector.styl';
import { getZoneConfig } from './config';

const PROSTATE_VIEWS = [
  { name: 'sv', displayName: 'SV', svgName: 'seminal-vesicles' },
  { name: 'base', displayName: 'Base', svgName: 'base' },
  { name: 'mid', displayName: 'Mid', svgName: 'mid' },
  { name: 'apex', displayName: 'Apex', svgName: 'apex' },
  { name: undefined, displayName: 'WG', svgName: undefined },
];

const EMPTY_SELECTION = {
  mid: [],
  base: [],
  apex: [],
  sv: [],
  isWholeGland: false,
};

function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

const rbgaArrayToExpression = rgbaArray => {
  return `rgba(${rgbaArray.join(',')})`;
};

export const ProstateZoneSelector = ({
  label,
  segmentMeta,
  selectedOption,
  onSelect,
}) => {
  const { UINotificationService } = window.ohif.app.servicesManager.services;
  const [t] = useTranslation('SegmentationPanel');

  const [rendered, setRendered] = useState(false);
  const [svgXML, setSvgXML] = useState(null);

  const [selectedView, setSelectedView] = useState(PROSTATE_VIEWS[2]);
  const [activeElements, setActiveElements] = useState(
    selectedOption || deepCopy(EMPTY_SELECTION)
  );

  useEffect(() => {
    if (!selectedView.name) {
      setRendered(false);
      return;
    }
    const element = document.getElementById(
      `prostate-zone-${selectedView.name}-${segmentMeta.segmentIndex}`
    );

    const serializer = new XMLSerializer();
    const svgXMLString = serializer.serializeToString(element);

    setSvgXML(svgXMLString);
    setRendered(true);
  }, [selectedView]);

  useEffect(() => {
    if (svgXML && svgXML !== null) {
      initSelection();
    }
  }, [svgXML]);

  const initSelection = () => {
    const container = document.getElementById(
      `injected-svg-${segmentMeta.segmentIndex}`
    );

    activeElements[selectedView.name].forEach(id => {
      var element = container.getElementById(id);
      if (element) {
        element.style.fill = rbgaArrayToExpression(segmentMeta.color);
        element.style.opacity = 0.5;
      } else {
        console.warn(`Element with id ${id} not found`);
      }
    });
  };

  const handleClick = (id, inActiveColor) => {
    const container = document.getElementById(
      `injected-svg-${segmentMeta.segmentIndex}`
    );
    var element = container.getElementById(id);

    if (activeElements[selectedView.name].includes(id)) {
      setActiveElements(os => {
        os[selectedView.name] = os[selectedView.name].filter(el => el !== id);
        return os;
      });
      element.style = '';
      return;
    }

    setActiveElements(os => {
      os[selectedView.name].push(id);
      os.isWholeGland = false;
      return os;
    });
    element.style.fill = rbgaArrayToExpression(segmentMeta.color);
    element.style.opacity = 0.5;
  };

  useEffect(() => {
    onSelect({ value: activeElements });
  }, [activeElements]);

  return (
    <div className="prostate-zone-selector" key={label}>
      <div className="zone-selection">
        {PROSTATE_VIEWS.map(view => (
          <div
            key={`${view}-${v4()}`}
            className={classnames('segment-panel-box prostate-view', {
              selected: view.displayName == selectedView.displayName,
            })}
            onClick={() => setSelectedView(view)}
          >
            {view.displayName}
          </div>
        ))}
      </div>
      <div className="selected-zone-hidden" style={{ display: 'none' }}>
        {selectedView.name && (
          <Svg
            key={`prostate-zone-${selectedView.name}-${segmentMeta.segmentIndex}`}
            name={selectedView.svgName}
            id={`prostate-zone-${selectedView.name}-${segmentMeta.segmentIndex}`}
          />
        )}
      </div>
      <div className="selected-zone">
        {selectedView.name && rendered && (
          <>
            <div className="handside-annotation-hook"></div>
            <SvgLoader
              svgXML={svgXML}
              id={`injected-svg-${segmentMeta.segmentIndex}`}
            >
              {getZoneConfig(selectedView.name).map(({ id, inActiveColor }) => (
                <SvgProxy
                  selector={`#${id}`}
                  onClick={() => handleClick(id, inActiveColor)}
                  key={id}
                ></SvgProxy>
              ))}
            </SvgLoader>
          </>
        )}
        {selectedView.displayName === 'WG' && (
          <button
            onClick={() => {
              setActiveElements(() => ({
                mid: [],
                base: [],
                apex: [],
                sv: [],
                isWholeGland: true,
              }));
              UINotificationService.show({
                type: 'success',
                message: 'Markierung erfolgreich gesetzt',
              });
            }}
          >
            {t('Set Whole Gland')}
          </button>
        )}
      </div>
    </div>
  );
};

export const constIsValidSeletion = selection => {
  if (!selection) {
    return false;
  }

  if (selection.isWholeGland) {
    return true;
  }

  const { mid, base, apex, sv } = selection;

  return mid.length > 0 || base.length > 0 || apex.length > 0 || sv.length > 0;
};
