/**
 *
 * @returns bool: true if the report can be generated, false otherwise
 */
export const canGenerateReport = ({ segmentations, report }) => {
  const { UINotificationService } = window.ohif.app.servicesManager.services;

  const reportExists = Object.keys(report).length > 0;

  if (!reportExists) {
    UINotificationService.show({
      type: 'warning',
      title: 'Report kann nicht erstellt werden',
      message:
        'Es konnten keine Report Daten bezüglich dieser Studie gefunden werden.',
    });
    return false;
  }

  // lesion needs to be processed
  const lesionComplete =
    segmentations.find(seg => seg.segmentType == 'lesion').editingInfo
      .confidenceLevel === 'processed';

  if (!lesionComplete || segmentations.length !== 2) {
    UINotificationService.show({
      type: 'warning',
      title: 'Report kann nicht erstellt werden',
      message:
        'Bitte vervollständigen Sie die notwendendigen Informationen. Überprüfen Sie dazu die Eingaben der Segmentierungen',
    });

    return false;
  }

  // we need adc, t2 and dwi sequence mappings for the mri images
  const { sequenceSelectionManager } = window.ohif.app;
  const sequenceSelection = sequenceSelectionManager.getSequenceMapping(
    segmentations[0].studyInstanceUID
  );
  const traMappings = sequenceSelection.selectAtomicMappings({
    orientation: 'tra',
  });

  if (traMappings.some(traMap => traMap.isEmpty())) {
    UINotificationService.show({
      type: 'warning',
      title: 'Report kann nicht erstellt werden',
      message:
        'Bitte vervollständigen Sie Sequenzauswahl in dem entsprechenden Menü.',
    });

    return false;
  }

  return true;
};
