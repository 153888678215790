import React from 'react';

import { useTranslation } from 'react-i18next';
import { TextArea } from '@ohif/ui';
import { useEditingInfo } from '../../../../../context/hooks/useEditingInformation';

export const CurrentEditingInformation = () => {
  const [t] = useTranslation('SegmentationPanel');

  const { getEditingInfo, setComment } = useEditingInfo();
  const editingInfo = getEditingInfo();

  return (
    <ul className="info-list">
      <li className="info-list-item">
        <span className="item-name">{t('Assigned User')}:</span>
        <span className="item-content">
          {editingInfo.hasRealUserAssigned()
            ? editingInfo.getUser()
            : t('No user assigned')}
        </span>
      </li>
      <li className="info-list-item">
        <span className="item-name">{t('Processing State')}:</span>
        <span className="item-content">
          {t(editingInfo.getConfidenceLevel())}
        </span>
      </li>
      <li className="info-list-item">
        <span className="item-name lb">{t('Comment')}:</span>
        <TextArea
          key={editingInfo.getComment()}
          value={editingInfo.getComment()}
          emptyValue={t('Write a comment')}
          onBlur={value => {
            setComment({ comment: value });
          }}
          rows={2}
        ></TextArea>
      </li>
    </ul>
  );
};
