import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Icon } from '@ohif/ui';

import { usePartialVolumeCalculation } from '../../../../context/SegmentationPanelContext';
import { SizeSelector } from '../SegmentDetailCard/components';

const EMPTY_VOLUME_MEASURES = {
  peripher: '',
  transitional: '',
};

export const ProstateVolumeFromPreExam = () => {
  const { UINotificationService } = window.ohif.app.servicesManager.services;

  const [t] = useTranslation('SegmentationPanel');

  const { getManualVolumeHandler } = usePartialVolumeCalculation();
  const manualVolumeHandler = getManualVolumeHandler();

  const [hasMeasures, setHasMeasures] = useState(
    manualVolumeHandler.hasPreliminaryVolumes()
  );

  const [preliminaryVolumes, setPreliminaryVolumes] = useState(
    manualVolumeHandler.getPreliminaryVolumes() || EMPTY_VOLUME_MEASURES
  );

  const resetMeasures = () => {
    setPreliminaryVolumes(() => ({ ...EMPTY_VOLUME_MEASURES }));
  };

  const handleOnBlur = ({ key, value }) => {
    setPreliminaryVolumes(state => {
      const newState = { ...state };
      newState[key] = value;
      return newState;
    });
  };

  return (
    <div className="segment-panel-box">
      <div className="seg-panel-card-header">
        <span className="heading small">
          {t('Enter volume from preliminary exam')}
        </span>
        <Icon
          className="icon"
          name={hasMeasures ? 'minus' : 'plus'}
          onClick={() => {
            if (hasMeasures) {
              resetMeasures();
              manualVolumeHandler.setPremliminaryVolumes({
                preliminaryVolumes: null,
              });
              UINotificationService.show({
                type: 'success',
                message: 'Volumen der Voruntersuchung entfernt',
              });
            }

            setHasMeasures(!hasMeasures);
          }}
        />
      </div>
      {hasMeasures && (
        <div className="seg-panel-card-body">
          <div className="volume-row">
            <div className="sub-volume-fields">
              <SizeSelector
                key={`transitional-preliminary-volume`}
                title={'Transitional'}
                value={preliminaryVolumes.transitional}
                unit={'ml'}
                setValue={value => {
                  handleOnBlur({ key: 'transitional', value });
                }}
              />
              <SizeSelector
                key={`peripheral-preliminary-volume`}
                title={'Peripher'}
                value={preliminaryVolumes.peripher}
                unit={'ml'}
                setValue={value => {
                  handleOnBlur({ key: 'peripher', value });
                }}
              />
            </div>
          </div>
          <div className="button-area">
            <button onClick={resetMeasures}>{t('Reset')}</button>
            <button
              onClick={() => {
                const values = [...Object.values(preliminaryVolumes)];
                const isNumericCheck = values.every(
                  value => /^\d+$/.test(value) && value !== ''
                );

                if (!isNumericCheck) {
                  UINotificationService.show({
                    type: 'warning',
                    title: 'Werte unvollständig',
                    message:
                      'Einige Volumen-Werte fehlen oder sind unzulässig, bitte überprüfen Sie Ihre Eingabe.',
                  });
                  return;
                }

                manualVolumeHandler.setPremliminaryVolumes({
                  preliminaryVolumes: preliminaryVolumes,
                });

                UINotificationService.show({
                  type: 'success',
                  message: 'Volumenmessungen der Voruntersuchung  gespeichert',
                });
              }}
            >
              {t('Save')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
