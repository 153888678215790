import {
  CategoryScale,
  Chart as ChartJS,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Tooltip,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import 'chartjs-adapter-moment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  TimeScale,
  annotationPlugin
);

import { InformationPanel } from '@ohif/ui';
import { useReportData } from '../context/reportContext';
import {
  calculateProstateVolumeInML,
  infoPanelHeader,
  infoPanelTextBody,
} from './utils';

const LINEARIZATION_FACTOR = 8;

function areSameDay(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

const calculateAge = (date, birthdateString) => {
  const birthdate = new Date(birthdateString);
  const ageInMS = date - birthdate;
  return Math.ceil(ageInMS / (1000 * 60 * 60 * 365 * 24));
};
const PSADiagram = () => {
  const { report, study } = useReportData();

  const dates = report.psaValues.psaSeries.map(series => {
    const date = new Date(series.testDate);
    date.setDate(1);
    return date;
  });

  const timeHorizonInDays = Math.ceil(
    (dates[dates.length - 1] - dates[0]) / (1000 * 60 * 60 * 24)
  );
  let lastPrintedTick = dates[0];
  const printedValues = [dates[0], dates[dates.length - 1]];

  const psaValues = report.psaValues.psaSeries.map(series => series.psaValue);

  const options = {
    devicePixelRatio: 2.5,
    plugins: {
      annotation: {
        annotations: {
          line1: {
            type: 'line',
            yMin: 4,
            yMax: 4,
            borderColor: 'red',
            borderWidth: 1,
          },
        },
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'month',
          displayFormats: {
            month: 'MM/YY',
          },
          tooltipFormat: 'MM/YY',
        },
        ticks: {
          source: 'labels',
          color: '#C4C4C4',
          font: {
            size: 11,
          },
          callback: (value, index) => {
            const date = dates[index];

            if (
              areSameDay(dates[0], date) ||
              areSameDay(dates[dates.length - 1], date)
            ) {
              return [value, `${calculateAge(date, study.birthDate)} Jahre`];
            }

            const daysSinceLastPrintedTick = Math.ceil(
              (date - lastPrintedTick) / (1000 * 60 * 60 * 24)
            );
            const daysTillLastMeasuredValues = Math.ceil(
              (dates[dates.length - 1] - date) / (1000 * 60 * 60 * 24)
            );

            if (
              daysSinceLastPrintedTick >=
                timeHorizonInDays / LINEARIZATION_FACTOR &&
              !printedValues.includes(date) &&
              daysTillLastMeasuredValues >=
                timeHorizonInDays / LINEARIZATION_FACTOR
            ) {
              printedValues.push(date);
              lastPrintedTick = date;
              return [value, `${calculateAge(date, study.birthDate)} Jahre`];
            }

            return '';
          },
        },
        grid: {
          color: context => {
            if (context.index === 0) return 'rgba(255, 255, 255, 0.2)';
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: '#C4C4C4',
        },
        grid: {
          width: 0.5,
          source: 'data',
          color: 'rgba(255, 255, 255, 0.2)',
        },
        border: {
          display: false,
        },
      },
    },
  };

  const data = {
    labels: dates,
    datasets: [
      {
        borderColor: '#C4C4C4',
        width: 0.5,
        pointRadius: 4,
        pointOpacity: 1,
        pointBackgroundColor: '#C4C4C4',
        data: psaValues,
      },
    ],
  };

  return <Line options={options} data={data} />;
};

export const PSAArea = () => {
  const { report, segmentations } = useReportData();
  const { t } = useTranslation('ReportPanel');

  const lastPSAValue = report.psaValues.psaSeries[
    report.psaValues.psaSeries.length - 1
  ].psaValue.toFixed(2);

  const psaValueText = lastPSAValue ? `${lastPSAValue} ng/ml` : 'n.a.';
  const freePsaValueText = report.psaValues.freePsaValue
    ? `${report.psaValues.freePsaValue.toFixed(2)} ng/ml`
    : 'n.a.';
  const psaQuotient =
    report.psaValues.freePsaValue && lastPSAValue
      ? `${((report.psaValues.freePsaValue / lastPSAValue) * 100).toFixed(2)}%`
      : 'n.a.';

  const calcualtePSADensity = () => {
    if (!lastPSAValue) {
      return 'n.a.';
    }

    const prostate = segmentations.find(_seg => _seg.segmentType === 'zone');

    if (!prostate) {
      return 'n.a.';
    }

    const prostateVolume = calculateProstateVolumeInML({
      segmentation: prostate,
    });

    return `${(lastPSAValue / prostateVolume.total).toFixed(2)} ng/ml`;
  };

  return (
    <div className="panel-area">
      <div className="card-heading">{t('PSA-Values')}</div>
      <div className="card-table">
        <div className="card-row">
          <InformationPanel
            title={infoPanelHeader(t('PSA-Value'))}
            body={infoPanelTextBody(psaValueText)}
          />
          <InformationPanel
            title={infoPanelHeader(t('free PSA'))}
            body={infoPanelTextBody(freePsaValueText)}
          />
        </div>
        <div className="card-row">
          <InformationPanel
            title={infoPanelHeader(t('PSA-Quotient'))}
            body={infoPanelTextBody(psaQuotient)}
          />
          <InformationPanel
            title={infoPanelHeader(t('PSA-Density'))}
            body={infoPanelTextBody(calcualtePSADensity())}
          />
        </div>
        <div className="card-row">
          <InformationPanel
            title={infoPanelHeader(t('PSA values in the course'))}
            body={<PSADiagram />}
          />
        </div>
      </div>
    </div>
  );
};
