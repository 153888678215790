import React from 'react';

import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';

import { useResources } from '../ResourceContextProvider';

import { PanelMainHeading } from './general';
import { ReportPage } from '../Report';

const styling = StyleSheet.create({
  Row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    backgroundColor: 'black',
    padding: '0 3px',
  },
  ImageContainer: {
    flexGrow: 1,
    backgroundColor: 'black',
    height: '200px',
    color: 'white',
    position: 'relative',
    maxWidth: 'calc(33% - 6px)',
    Image: {
      flexGrow: 1,
      zIndex: 10,
      margin: '6px 3px',
    },
    Text: {
      position: 'absolute',
      top: '10px',
      right: '50%',
      transform: 'translateX(95%)',
      fontWeight: 'bold',
      textAlign: 'center',
      width: '100%',
    },
  },

  HeatmapInfotext: {
    marginTop: '10px',
    heading: {
      fontWeight: 'bold',
      marginBottom: '8px',
    },
    P1: {
      marginBottom: '8px',
    },
    P2: {
      marginTop: '4px',
    },
    BulletPoint: {
      display: 'inline',
      paddingLeft: '5px',
      textIndent: '-18px',
      marginBottom: '4px',
      Emph: {
        fontWeight: '500',
      },
    },
  },
});

const MRIImage = ({ heading, image }) => {
  return (
    <View style={styling.ImageContainer}>
      <Image style={styling.ImageContainer.Image} src={image} />
      <Text style={styling.ImageContainer.Text}>{heading}</Text>
    </View>
  );
};

const MRIImageRow = ({ lesionIndex, images }) => {
  return (
    <View style={styling} wrap={false}>
      <PanelMainHeading heading={`Läsion ${lesionIndex}`} />
      <View style={styling.Row}>
        <MRIImage heading={'t2'} image={images.t2} />
        <MRIImage heading={'ADC'} image={images.adc} />
        <MRIImage heading={'DWI'} image={images.dwi} />
      </View>
    </View>
  );
};

const HeatmapInfotext = ({}) => {
  const style = styling.HeatmapInfotext;

  const BulletPoint = ({ emph, rest }) => {
    return (
      <Text style={style.BulletPoint}>
        <Text>- </Text>
        <Text style={style.BulletPoint.Emph}>{emph}</Text>
        <Text>{rest}</Text>
      </Text>
    );
  };

  return (
    <View style={style}>
      <Text style={style.heading}>Erläuterung der Heatmaps</Text>
      <Text style={style.P1}>
        Unsere KI-gestützte Analyse der MRT-Bilder zeigt die Ergebnisse in drei
        verschiedenen Klassen. Die Heatmaps sind farbcodierte Darstellungen, die
        anzeigen, wo und in welchem Ausmaß die KI Anomalien im Prostatagewebe
        erkannt hat.
      </Text>
      <View>
        <BulletPoint
          emph={'Chronische Prostatitis: '}
          rest={
            'Bereiche, die als chronische Prostatitis identifiziert wurden, sind durch spezifische Farbintensitäten gekennzeichnet, die auf Entzündungen hindeuteten kann.'
          }
        />
        <BulletPoint
          emph={'Gleason Score 6: '}
          rest={
            'Diese Heatmap zeigt Bereiche, die einem Gleason Score 6 entsprechen, was auf einen weniger aggressiven Prostatakrebs hindeuten kann. Größer als Gleason Score 6: Hierbei handelt es sich um Bereiche, die als aggressiverer Prostatakrebs identifiziert wurden, dargestellt durch intensivere Farbgebungen.'
          }
        />
        <BulletPoint
          emph={'Größer als Gleason Score 6: '}
          rest={
            'Hierbei handelt es sich um Bereiche, die als aggressiverer Prostatakrebs identifiziert wurden, dargestellt durch intensivere Farbgebungen.'
          }
        />
      </View>
      <Text style={style.P2}>
        Durch diese Heatmaps können Sie auf einen Blick die Lokalisation und den
        Schweregrad der Veränderungen im Prostatagewebe erkennen. Dies
        unterstützt eine präzise Diagnosestellung und erleichtert die Planung
        weiterer medizinischer Maßnahmen.
      </Text>
    </View>
  );
};

const HeatmapsRow = ({ heatmaps }) => {
  return (
    <View>
      <View style={{ ...styling.Row, marginTop: '10px' }}>
        {heatmaps.CI && (
          <MRIImage heading={'chronische Prostatitis'} image={heatmaps.CI} />
        )}
        {heatmaps.GS6 && (
          <MRIImage heading={'Gleason Score 6'} image={heatmaps.GS6} />
        )}
        {heatmaps.CSPC && (
          <MRIImage heading={'Gleason Score > 6'} image={heatmaps.CSPC} />
        )}
      </View>
      <HeatmapInfotext />
    </View>
  );
};

export const MRIImages = () => {
  const { mriImages } = useResources();

  return mriImages.map((images, index) => (
    <ReportPage>
      <View style={styling}>
        <MRIImageRow key={index} lesionIndex={index + 1} images={images} />
        {Object.keys(images.heatmaps).length && (
          <HeatmapsRow heatmaps={images.heatmaps} />
        )}
      </View>
    </ReportPage>
  ));
};
