import { StudyMetadata } from './StudyMetadata';
import { OHIFSeriesMetadata } from './OHIFSeriesMetadata';

export class OHIFStudyMetadata extends StudyMetadata {
  /**
   * @param {Object} Study object.
   */
  constructor(data, uid) {
    super(data, uid);
    this.init();
  }

  init() {
    const study = this.getData();

    // define "_studyInstanceUID" protected property
    Object.defineProperty(this, '_studyInstanceUID', {
      configurable: false,
      enumerable: false,
      writable: false,
      value: study.StudyInstanceUID,
    });

    Object.defineProperty(this, '_fullyLoaded', {
      configurable: false,
      enumerable: false,
      writable: true,
      value: study._fullyLoaded || false,
    });

    // populate internal list of series
    study.series.forEach(series => {
      this.addSeries(new OHIFSeriesMetadata(series, study));
    });
  }

  hasLoaded() {
    return this._fullyLoaded;
  }

  setLoaded() {
    this._fullyLoaded = true;
  }
}
