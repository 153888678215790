import { Icon } from '@ohif/ui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './Selector.styl';

export const DropDownSelector = ({
  title,
  options,
  selectedOption,
  onSelect,
  label,
  active,
}) => {
  const [expanded, setExpanded] = useState(active || false);

  useEffect(() => {
    setExpanded(active);
  }, [active]);

  const [t] = useTranslation('SegmentationPanel');

  return (
    <div
      key={label}
      className="segment-panel-box selector dropdown"
      onClick={() => setExpanded(!expanded)}
    >
      <div className="selector-overview">
        <div className="selector-left">
          <div className="title">{t(title)}</div>
          <div className="selected-option emph">
            {selectedOption ? t(selectedOption) : t('Please select')}
          </div>
        </div>
        <div className="selector-right">
          <div className="icon-group">
            <Icon
              className="icon"
              name={expanded ? 'arrow-up' : 'arrow-down'}
              onClick={e => {
                e.stopPropagation();
                setExpanded(!expanded);
              }}
            />
          </div>
        </div>
      </div>
      <div className="selector-expanded">
        {expanded &&
          options.map(opt => {
            return (
              <div
                key={`${label}-${opt}`}
                onClick={e => {
                  e.stopPropagation();
                  onSelect({ value: opt });
                  setExpanded(false);
                }}
                className="option"
              >
                {t(opt)}
              </div>
            );
          })}
      </div>
    </div>
  );
};
