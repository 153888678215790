import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@ohif/ui';

import { useMetadata } from '../../../../../context/SegmentationPanelContext';
import { Tooltip } from './ClassDistributionTable';

import './SegmentCardComponents.styl';

const ModelInfo = ({ modelInfo }) => {
  const ToolTipText = () => {
    return (
      <div>
        <div>{`Model: ${modelInfo.info}`}</div>
        <div>{`runID: ${modelInfo.runID}`}</div>
      </div>
    );
  };

  return (
    <div className="modelinfo">
      <Tooltip tooltipText={<ToolTipText />}>
        <Icon name="circle-info" height="15px" width="15px" />
      </Tooltip>
    </div>
  );
};

const AIOriginText = ({ t }) => {
  const { getInferenceModelInfo } = useMetadata();
  const modelInfo = getInferenceModelInfo();

  return (
    <>
      <span className="emph">AI</span>
      <span>{t('segment')}</span>
      {modelInfo && <ModelInfo modelInfo={modelInfo} />}
    </>
  );
};

const ManualOriginText = ({ t }) => {
  return (
    <>
      <span className="emph">{t('Manually')}</span>
      <span>{t('segment')}</span>
    </>
  );
};

const AIManualOriginText = ({ t }) => {
  const { getInferenceModelInfo } = useMetadata();
  const modelInfo = getInferenceModelInfo();
  return (
    <>
      <span className="emph">AI</span>
      <span>{t('segment')}</span>
      <span className="emph">{`(${t('edited')})`}</span>
      {modelInfo && <ModelInfo modelInfo={modelInfo} />}
    </>
  );
};

export const OriginInformation = ({ data }) => {
  const [t] = useTranslation('SegmentationPanel');

  const getOriginText = () => {
    switch (data) {
      case 'AI':
        return <AIOriginText t={t} />;
      case 'Manual':
        return <ManualOriginText t={t} />;
      case 'AI/Manual':
        return <AIManualOriginText t={t} />;
    }
  };

  return (
    <div className="segment-card-field">
      <div className="sc-body">
        <div className="origin">{data && getOriginText()}</div>
      </div>
    </div>
  );
};
