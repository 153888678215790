import { api } from 'dicomweb-client';
import DICOMWeb from '../DICOMWeb';
import { addInstancesToStudy } from './services/wado/studyInstanceHelpers';

import errorHandler from '../errorHandler';
import getXHRRetryRequestHook from '../utils/xhrRetryRequestHook';

const retrieveSeries = async (
  studyInstanceUID,
  seriesInstanceUID,
  server,
  study,
) => {
  const config = {
    ...server,
    url: server.qidoRoot,
    headers: DICOMWeb.getAuthorizationHeader(server),
    errorInterceptor: errorHandler.getHTTPErrorHandler(),
    requestHooks: [getXHRRetryRequestHook()],
  };
  const client = new api.DICOMwebClient(config);

  const options = {
    studyInstanceUID,
    seriesInstanceUID,
  };

  const metadata = await client.retrieveSeriesMetadata(options);
  await addInstancesToStudy(server, study, metadata);
  const newSeries = study.series.find(
    serie => serie.SeriesInstanceUID === seriesInstanceUID
  );
  return newSeries;
};

export { retrieveSeries };
