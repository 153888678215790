import React from 'react';

import { Document, Page, View } from '@react-pdf/renderer';
import {
  Assessment,
  Header,
  Indication,
  PSAHistory,
  PatientInfoHeader,
  ProstateLesions,
  ProstateVolume,
  Recommendation,
} from './components';
import { MRIImages } from './components/MRIImages.js';
import styling from './styling.js';

export const ReportPage = ({ children }) => {
  return (
    <Page size="A4" style={styling.page}>
      <Header />
      <PatientInfoHeader />
      {children}
    </Page>
  );
};

// Create Document Component
export const Report = ({ title }) => {
  return (
    <Document title={title}>
      <ReportPage>
        {/* First Row with Indication and PSA */}
        <View style={styling.row}>
          <View style={styling.rowElement}>
            <Indication />
          </View>
          <View style={styling.rowElement}>
            <PSAHistory />
          </View>
        </View>

        {/* Exam Information */}
        {/* <View style={{ ...styling.row, paddingTop: '10px' }}>
          <View style={styling.longRowElement}>
            <ExaminationInformation />
          </View>
        </View> */}

        {/* Prostate Volume */}

        <View style={{ ...styling.row, paddingTop: '10px' }}>
          <View style={styling.longRowElement}>
            <ProstateVolume />
          </View>
        </View>

        {/* Description of Prostate Lesion */}

        <View style={{ ...styling.row, paddingTop: '10px' }}>
          <View style={styling.longRowElement}>
            <ProstateLesions />
          </View>
        </View>

        {/* Assessment */}
        <View style={{ ...styling.row, paddingTop: '10px' }}>
          <View style={styling.longRowElement}>
            <Assessment />
          </View>
        </View>

        {/* Recommendation */}
        <View style={{ ...styling.row, paddingTop: '10px' }}>
          <View style={styling.longRowElement}>
            <Recommendation />
          </View>
        </View>
      </ReportPage>

      <MRIImages />
    </Document>
  );
};
