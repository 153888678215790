import React from 'react';

import classnames from 'classnames';

import {
  useSegmentationConfig,
  useSegmentationPanel,
} from '../../../context/SegmentationPanelContext';
import { useTranslation } from 'react-i18next';
import { useMergeSegment } from '../../../context/hooks/useMergeSement';

export const SegmentationTypeAreaFooterButton = () => {
  const [t] = useTranslation('SegmentationPanel');

  const { segmentationConfig, saveSegmentation } = useSegmentationPanel();
  const { isT2 } = useSegmentationConfig();

  const { segmentMergeModeActive, onMergeSegmentsConfirm } = useMergeSegment();

  const buttonText = segmentMergeModeActive ? 'Merge Segments' : 'Save';
  const buttonCallback = segmentMergeModeActive
    ? onMergeSegmentsConfirm
    : saveSegmentation;

  return (
    segmentationConfig &&
    segmentationConfig.enableSave && (
      <button
        onClick={() => {
          buttonCallback();
        }}
        className={classnames('seg-panel-card-footer-button', {
          active: isT2,
        })}
      >
        {t(buttonText)}
      </button>
    )
  );
};
