import cornerstoneTools from 'cornerstone-tools';
import { v4 } from 'uuid';
import SegmentationReader from './SegmentationReader';

const { setters, getters, state } = cornerstoneTools.getModule('segmentation');

const generateTrackingUID = (length = 2) => {
  const prefix = '1.2.826.0.1.3680043.10.511.3.';

  const utf8encoder = new TextEncoder();
  const value = utf8encoder.encode(v4());
  const valueString = value.toString().replaceAll(',', '');

  return prefix + valueString;
};

const determineNewSelectedSegmentIndex = (
  activeIndex,
  deletedIndex,
  totalNumberOfSegments
) => {
  // cornerstone indices are one-based -> pad our own indices
  deletedIndex++;
  return activeIndex > deletedIndex
    ? activeIndex - 1
    : Math.min(activeIndex, totalNumberOfSegments);
};

const createSegment = (element, label) => {
  const labelMeta = {
    _vrMap: {},
    SegmentedPropertyCategoryCodeSequence: {
      CodeValue: 'T-D0050',
      CodingSchemeDesignator: 'SRT',
      CodeMeaning: 'Tissue',
    },
    SegmentNumber: 1,
    SegmentLabel: label ? label : 'label-0-1',
    SegmentDescription: label,
    SegmentAlgorithmType: 'SEMIAUTOMATIC',
    SegmentAlgorithmName: 'MONAI',
    SegmentedPropertyTypeCodeSequence: {
      CodeValue: 'T-D0050',
      CodingSchemeDesignator: 'SRT',
      CodeMeaning: 'Tissue',
    },
    TrackingUID: generateTrackingUID(),
  };

  // this labelmap2D function will create a labelmap3D if a labelmap does
  // not yet exist. it will also generate a labelmap2D for the currentImageIndex
  // if it does not yet exist.
  // refer to: https://github.com/cornerstonejs/cornerstoneTools/blob/master/src/store/modules/segmentationModule/getLabelmap2D.js
  const { labelmap3D, activeLabelmapIndex } = getters.labelmap2D(element);

  // Add new colorLUT if required for new labelmapIndex
  // const { state } = cornerstoneTools.getModule('segmentation');
  // if (state.colorLutTables.length <= activeLabelmapIndex) {
  //   setters.colorLUT(activeLabelmapIndex);
  //   labelmap3D.colorLUTIndex = activeLabelmapIndex;
  // }

  // TODO:: which one is standard metadata.data[] or metadata[] ???
  if (!labelmap3D.metadata || !labelmap3D.metadata.data) {
    labelmap3D.metadata = { data: [undefined] };
  }

  const { metadata } = labelmap3D;
  const segmentNumbers = metadata.data
    .reduce((acc, curr) => {
      if (curr) {
        acc.push(curr.SegmentNumber);
      }
      return acc;
    }, [])
    .sort((a, b) => a - b);

  let nextSegmentId = 1;
  for (let i = 0; i < segmentNumbers[segmentNumbers.length - 1]; i++) {
    if (segmentNumbers.includes(nextSegmentId)) {
      nextSegmentId++;
    }
  }

  labelMeta.SegmentNumber = nextSegmentId;
  labelMeta.SegmentLabel = label
    ? label
    : 'label_' + activeLabelmapIndex + '-' + nextSegmentId;

  if (nextSegmentId === metadata.data.length) {
    metadata.data.push(labelMeta);
  } else {
    metadata.data.splice(nextSegmentId, 0, labelMeta);
  }
  setters.activeSegmentIndex(element, labelmap3D.activeSegmentIndex);

  return labelMeta;
};

const deleteSegment = (labelmap3D, index, element) => {
  const segmentNumberToDelete = labelmap3D.metadata.data[index].SegmentNumber;
  const trackingUID = labelmap3D.metadata.data[index].TrackingUID;

  labelmap3D.metadata.data = labelmap3D.metadata.data.filter(
    (_segment, _index) => _index !== index
  );

  const segmentNumbersToDecrement = labelmap3D.metadata.data
    .reduce((acc, curr) => {
      if (curr.SegmentNumber > segmentNumberToDelete) {
        acc.push(curr.SegmentNumber);
      }
      return acc;
    }, [])
    .sort((a, b) => a - b);

  const labelmaps2D = labelmap3D.labelmaps2D;

  // Remove segment to delete from 2d labelmaps
  for (let i = 0; i < labelmaps2D.length; i++) {
    const labelmap2D = labelmaps2D[i];
    if (
      labelmap2D &&
      labelmap2D.segmentsOnLabelmap.includes(segmentNumberToDelete)
    ) {
      const indexOfSegment = labelmap2D.segmentsOnLabelmap.indexOf(
        segmentNumberToDelete
      );
      labelmap2D.segmentsOnLabelmap.splice(indexOfSegment, 1);
    }
  }

  // Decrement segments after segment to delete on 2d labelmaps
  for (let i = 0; i < labelmaps2D.length; i++) {
    const labelmap2D = labelmaps2D[i];
    for (let j = 0; j < segmentNumbersToDecrement.length; j++) {
      const segmentNumToDec = segmentNumbersToDecrement[j];
      if (
        labelmap2D &&
        labelmap2D.segmentsOnLabelmap.includes(segmentNumToDec)
      ) {
        const indexOfSegment = labelmap2D.segmentsOnLabelmap.indexOf(
          segmentNumToDec
        );
        labelmap2D.segmentsOnLabelmap[indexOfSegment]--;
      }
    }
  }

  // delete and decrement pixel data
  let z = new Uint16Array(labelmap3D.buffer);
  for (let i = 0; i < z.length; i++) {
    if (z[i] === segmentNumberToDelete) {
      z[i] = 0;
    }
    for (let j = 0; j < segmentNumbersToDecrement.length; j++) {
      const segmentNumToDec = segmentNumbersToDecrement[j];
      if (z[i] === segmentNumToDec) {
        z[i]--;
      }
    }
  }

  // decrement metadata
  labelmap3D.metadata.data.forEach(item => {
    if (segmentNumbersToDecrement.includes(item.SegmentNumber)) {
      item.SegmentNumber--;
    }
  });

  // determine new selected segment
  const newSelectedIndex = determineNewSelectedSegmentIndex(
    labelmap3D.activeSegmentIndex,
    index,
    labelmap3D.metadata.data.length
  );
  document.dispatchEvent(
    new CustomEvent('deletesegment', {
      detail: { segmentID: newSelectedIndex },
    })
  );
  setters.activeSegmentIndex(element, newSelectedIndex);

  return trackingUID;
};

const mergeSegments = (labelmap3D, targetIndex, indicesToMap) => {
  let z = new Uint16Array(labelmap3D.buffer);
  for (let i = 0; i < z.length; i++) {
    if (indicesToMap.includes(z[i])) {
      z[i] = targetIndex;
    }
  }
};

const exportSegmentation = async ({
  labelmap3D,
  studyInstanceUID,
  sopInstanceUID,
  metadata,
}) => {
  const { apiInterface } = window.ohif.app;
  const seriesInstanceUID = labelmap3D.metadata.segmentationSeriesInstanceUID;

  let size;
  let response;

  response = await apiInterface.getSizeOfSeries({
    studyInstanceUID,
    seriesInstanceUID,
  });
  size = response.shape;

  const segmentation = SegmentationReader.serializeNrrd(labelmap3D.buffer, [
    size[2],
    size[1],
    size[0],
  ]);

  response = await apiInterface.updateSegmentationImage({
    studyInstanceUID,
    seriesInstanceUID,
    img: segmentation,
    data: {
      segmentation: metadata,
      sopInstanceUID,
    },
  });

  return response;
};

/**
 * Checks whether the study has a lesion and a zone segmentation
 * @param {*} param0
 * @returns
 */
const hasAllSegmentations = async ({ studyInstanceUID }) => {
  const { apiInterface } = window.ohif.app;

  const hasSegmentation = (type, segmentations) => {
    return segmentations.some(
      segmentation => segmentation.segmentType === type
    );
  };

  return new Promise((resolve, _) => {
    apiInterface
      .getSegmentationList({
        studyInstanceUID,
        complete: true,
      })
      .then(segmentations => {
        const hasLesion = hasSegmentation('lesion', segmentations);
        const hasZone = hasSegmentation('zone', segmentations);

        resolve(hasLesion && hasZone);
      })
      .catch(error => {
        console.warn(error);
        resolve(false);
      });
  });
};

export {
  createSegment,
  deleteSegment,
  mergeSegments,
  exportSegmentation,
  hasAllSegmentations,
};
