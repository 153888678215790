import React from 'react';

import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useResources } from '../ResourceContextProvider';
import { PanelMainHeading } from './general';

const styling = StyleSheet.create({
  Text: {
    fontSize: '8px',
    marginBottom: '4px',
  },
});

const RECOMMENDATION_MAP = {
  'MRI control in 6 months':
    'Wir empfehlen weiterhin regelmäßige PSA-Spiegel-Kontrollen sowie bei stabilem PSA-Verlauf eine mpMRT-Kontroll-Untersuchung Ihrer Prostata in ca. 6 Jahr.',
  'MRI control in 12 months':
    'Wir empfehlen weiterhin regelmäßige PSA-Spiegel-Kontrollen sowie bei stabilem PSA-Verlauf eine mpMRT-Kontroll-Untersuchung Ihrer Prostata in ca. 1 Jahr.',
  'PSA value determination in 3 months':
    'Wir empfehlen die nächste  PSA-Kontrollen in 3 Monaten sowie in Abhängigkeit vom weiteren PSA-Verlauf ggf. eine mpMRT-Kontroll-Untersuchung Ihrer Prostata in ca. 1 Jahr.',
  'PSA value determination in 6 months':
    'Wir empfehlen die nächste  PSA-Kontrollen in 6 Monaten sowie in Abhängigkeit vom weiteren PSA-Verlauf ggf. eine mpMRT-Kontroll-Untersuchung Ihrer Prostata in ca. 1 Jahr.',
  'Prostate Biopsy':
    'Eine MRT-gesteuerte und gezielte Biopsie der beschriebenen Befunde ist erforderlich. Im Zuge der gezielten Biopsie entnehmen wir gemäß der S3-Leitlinien noch systematisch Gewebeproben.',
  HoLEP: '',
};

const createRecommendations = ({ report }) => {
  const recommendationTexts = report.recommendation.therapyRecommendation
    .map(item => {
      try {
        return RECOMMENDATION_MAP[item];
      } catch (e) {
        console.warn("Couldn't find option for item: ", item);
        return null;
      }
    })
    .filter(Boolean);

  return recommendationTexts;
};

const Recommendation = () => {
  const {
    reportData: { report },
  } = useResources();

  const recommendations = createRecommendations({ report });

  return (
    <View style={styling}>
      <PanelMainHeading heading={'6. Empfehlung'} />
      <Text style={styling.Text}>{recommendations.join(' ')}</Text>
    </View>
  );
};

export { Recommendation };
