export const PROSTATE_ZONES = Object.freeze({
  MID: 'mid',
  BASE: 'base',
  APEX: 'apex',
  SV: 'sv',
});

const PROSTATE_AREAS_SV = [
  { id: 'SVR', inActiveColor: '#B3B3B3' },
  { id: 'SVL', inActiveColor: '#B3B3B3' },
];

const PROSTATE_AREAS_APEX = [
  { id: 'ARAS', inActiveColor: '#B3B3B3' },
  { id: 'ALAS', inActiveColor: '#B3B3B3' },
  { id: 'ARTZa', inActiveColor: '#CCCCCC' },
  { id: 'ALTZa', inActiveColor: '#CCCCCC' },
  { id: 'ARTZp', inActiveColor: '#CCCCCC' },
  { id: 'ALTZp', inActiveColor: '#CCCCCC' },
  { id: 'ARPZa', inActiveColor: '#CCCCCC' },
  { id: 'ALPZa', inActiveColor: '#CCCCCC' },
  { id: 'ARPZpl', inActiveColor: '#CCCCCC' },
  { id: 'ALPZpl', inActiveColor: '#CCCCCC' },
  { id: 'ARPZpm', inActiveColor: '#B3B3B3' },
  { id: 'ALPZpm', inActiveColor: '#B3B3B3' },
];

const PROSTATE_AREAS_BASE = [
  { id: 'BRPZpl', inActiveColor: '#CCCCCC' },
  { id: 'BLPZpl', inActiveColor: '#CCCCCC' },
  { id: 'BLPZa', inActiveColor: '#CCCCCC' },
  { id: 'BRPZa', inActiveColor: '#CCCCCC' },
  { id: 'BRAS', inActiveColor: '#B3B3B3' },
  { id: 'BLAS', inActiveColor: '#B3B3B3' },
  { id: 'BLTZa', inActiveColor: '#CCCCCC' },
  { id: 'BRTZa', inActiveColor: '#CCCCCC' },
  { id: 'BLTZp', inActiveColor: '#CCCCCC' },
  { id: 'BRTZp', inActiveColor: '#CCCCCC' },
  { id: 'BRCZ', inActiveColor: '#CCCCCC' },
  { id: 'BLCZ', inActiveColor: '#CCCCCC' },
];

const PROSTATE_AREAS_MID = [
  { id: 'MLPZa', inActiveColor: '#CCCCCC' },
  { id: 'MLTZa', inActiveColor: '#CCCCCC' },
  { id: 'MRTZa', inActiveColor: '#CCCCCC' },
  { id: 'MRPZa', inActiveColor: '#CCCCCC' },
  { id: 'MRTZp', inActiveColor: '#CCCCCC' },
  { id: 'MLTZp', inActiveColor: '#CCCCCC' },
  { id: 'MRPZpl', inActiveColor: '#CCCCCC' },
  { id: 'MLPZpl', inActiveColor: '#CCCCCC' },
  { id: 'MLAS', inActiveColor: '#B3B3B3' },
  { id: 'MRAS', inActiveColor: '#B3B3B3' },
  { id: 'MLPZpm', inActiveColor: '#CCCCCC' },
  { id: 'MRPZpm', inActiveColor: '#CCCCCC' },
];

const ZONE_TO_TEXT_MAPPING = {
  ARAS: { region: 'apex', side: 'right', zone: 'transitional' },
  ALAS: { region: 'apex', side: 'left', zone: 'transitional' },
  ARTZa: { region: 'apex', side: 'right', zone: 'transitional' },
  ALTZa: { region: 'apex', side: 'left', zone: 'transitional' },
  ARTZp: { region: 'apex', side: 'right', zone: 'transitional' },
  ALTZp: { region: 'apex', side: 'left', zone: 'transitional' },
  ARPZa: { region: 'apex', side: 'right', zone: 'peripher' },
  ALPZa: { region: 'apex', side: 'left', zone: 'peripher' },
  ARPZpl: { region: 'apex', side: 'right', zone: 'peripher' },
  ALPZpl: { region: 'apex', side: 'left', zone: 'peripher' },
  ARPZpm: { region: 'apex', side: 'right', zone: 'peripher' },
  ALPZpm: { region: 'apex', side: 'left', zone: 'peripher' },
  BRPZpl: { region: 'base', side: 'right', zone: 'peripher' },
  BLPZpl: { region: 'base', side: 'left', zone: 'peripher' },
  BLPZa: { region: 'base', side: 'left', zone: 'peripher' },
  BRPZa: { region: 'base', side: 'right', zone: 'peripher' },
  BRAS: { region: 'base', side: 'right', zone: 'transitional' },
  BLAS: { region: 'base', side: 'left', zone: 'transitional' },
  BLTZa: { region: 'base', side: 'left', zone: 'transitional' },
  BRTZa: { region: 'base', side: 'right', zone: 'transitional' },
  BLTZp: { region: 'base', side: 'left', zone: 'transitional' },
  BRTZp: { region: 'base', side: 'right', zone: 'transitional' },
  BRCZ: { region: 'base', side: 'right', zone: 'central' },
  BLCZ: { region: 'base', side: 'left', zone: 'central' },
  SVR: { region: null, side: 'right', zone: null },
  SVL: { region: null, side: 'left', zone: null },
  MLPZa: { region: 'mid', side: 'left', zone: 'peripher' },
  MLTZa: { region: 'mid', side: 'left', zone: 'transitional' },
  MRTZa: { region: 'mid', side: 'right', zone: 'transitional' },
  MRPZa: { region: 'mid', side: 'right', zone: 'peripher' },
  MRTZp: { region: 'mid', side: 'right', zone: 'transitional' },
  MLTZp: { region: 'mid', side: 'left', zone: 'transitional' },
  MRPZpl: { region: 'mid', side: 'right', zone: 'peripher' },
  MLPZpl: { region: 'mid', side: 'left', zone: 'peripher' },
  MLAS: { region: 'mid', side: 'left', zone: 'transitional' },
  MRAS: { region: 'mid', side: 'right', zone: 'transitional' },
  MLPZpm: { region: 'mid', side: 'left', zone: 'peripher' },
  MRPZpm: { region: 'mid', side: 'right', zone: 'peripher' },
};

const CORONAL_MAPPING = [
  { externalID: 'BLTZp', coronalID: 'B_PZplCZTZp' },
  { externalID: 'BRTZp', coronalID: 'B_PZplCZTZp' },
  { externalID: 'SVR', coronalID: 'SV' },
  { externalID: 'SVL', coronalID: 'SV' },
  { externalID: 'BLAS', coronalID: 'B_AS' },
  { externalID: 'BLTza', coronalID: 'B_TZa' },
  { externalID: 'BLTZp', coronalID: 'B_PZplCZTZp' },
  { externalID: 'BLPza', coronalID: 'B_TZa' },
  { externalID: 'BLPZpl', coronalID: 'B_PZa' },
  { externalID: 'BLCZ', coronalID: 'B_PZplCZ' },
  { externalID: 'BRAS', coronalID: 'B_AS' },
  { externalID: 'BRTza', coronalID: 'B_TZa' },
  { externalID: 'BRTZp', coronalID: 'B_PZplCZTZp' },
  { externalID: 'BRPZa', coronalID: 'B_TZa' },
  { externalID: 'BRPZpl', coronalID: 'B_PZa' },
  { externalID: 'BRCZ', coronalID: 'B_PZplCZ' },
  { externalID: 'MLAS', coronalID: 'M_AS' },
  { externalID: 'MLTZa', coronalID: 'M_PZaTZa' },
  { externalID: 'MLTZp', coronalID: 'M_PZplPZpmTZp' },
  { externalID: 'MLPZa', coronalID: 'M_PZa' },
  { externalID: 'MLPZpl', coronalID: 'M_PZpl' },
  { externalID: 'MLPZpm', coronalID: 'M_PZpl' },
  { externalID: 'MRAS', coronalID: 'M_AS' },
  { externalID: 'MRTZa', coronalID: 'M_PZaTZa' },
  { externalID: 'MRTZp', coronalID: 'M_PZplPZpmTZp' },
  { externalID: 'MRPZa', coronalID: 'M_PZa' },
  { externalID: 'MRPZpl', coronalID: 'M_PZpl' },
  { externalID: 'MRPZpm', coronalID: 'M_PZpl' },
  { externalID: 'ALAS', coronalID: 'A_AS' },
  { externalID: 'ALTZa', coronalID: 'A_PZplPZpmTZp' },
  { externalID: 'ALTZp', coronalID: 'A_PZplPZpmTZp' },
  { externalID: 'ALPZa', coronalID: 'A_PZa' },
  { externalID: 'ALPZpl', coronalID: 'A_PZpl' },
  { externalID: 'ALPZpm', coronalID: 'A_PZpl' },
  { externalID: 'ARAS', coronalID: 'A_AS' },
  { externalID: 'ARTZa', coronalID: 'A_PZplPZpmTZp' },
  { externalID: 'ARTZp', coronalID: 'A_PZplPZpmTZp' },
  { externalID: 'ARPZa', coronalID: 'A_PZa' },
  { externalID: 'ARPZpl', coronalID: 'A_PZpl' },
  { externalID: 'ARPZpm', coronalID: 'A_PZpl' },
  { externalID: 'BLTZp', coronalID: 'B_TZp' },
  { externalID: 'BLPza', coronalID: 'B_CZPZa' },
  { externalID: 'BLPZpl', coronalID: 'B_PZplCZ' },
  { externalID: 'BRTZp', coronalID: 'B_TZp' },
  { externalID: 'BRPZa', coronalID: 'B_CZPZa' },
  { externalID: 'BRPZpl', coronalID: 'B_PZplCZ' },
  { externalID: 'MLTZa', coronalID: 'M_TZa' },
  { externalID: 'MLPZa', coronalID: 'M_PZaTZa' },
  { externalID: 'MLPZpl', coronalID: 'M_PZplPZpmTZp' },
  { externalID: 'MLPZpm', coronalID: 'M_PZplPZpmTZp' },
  { externalID: 'MRTZa', coronalID: 'M_TZa' },
  { externalID: 'MRPZa', coronalID: 'M_PZaTZa' },
  { externalID: 'MRPZpl', coronalID: 'M_PZplPZpmTZp' },
  { externalID: 'MRPZpm', coronalID: 'M_PZplPZpmTZp' },
  { externalID: 'ALTZa', coronalID: 'A_PZaTZa' },
  { externalID: 'ALPZpl', coronalID: 'A_PZplPZpmTZp' },
  { externalID: 'ALPZpm', coronalID: 'A_PZplPZpmTZp' },
  { externalID: 'ARPZpl', coronalID: 'A_PZplPZpmTZp' },
  { externalID: 'ARPZpm', coronalID: 'A_PZplPZpmTZp' },
  { externalID: 'BLPZpl', coronalID: 'B_CZPZpl' },
  { externalID: 'BLPZpl', coronalID: 'B_CZPZpl2' },
  { externalID: 'BRPZpl', coronalID: 'B_CZPZpl' },
  { externalID: 'BRPZpl', coronalID: 'B_CZPZpl2' },
  { externalID: 'MLPZpl', coronalID: 'M_PZplPZpmTZp2' },
  { externalID: 'MRPZpl', coronalID: 'M_PZplPZpmTZp2' },
  { externalID: 'MLPZpl', coronalID: 'M_PZplPZpmTZp2' },
  { externalID: 'MRPZpl', coronalID: 'M_PZplPZpmTZp2' },
  { externalID: 'BLPZpl', coronalID: 'B_PZplCZTZp' },
  { externalID: 'BRPZpl', coronalID: 'B_PZplCZTZp' },
  { externalID: 'BLCZ', coronalID: 'B_PZplCZTZp' },
  { externalID: 'BRCZ', coronalID: 'B_PZplCZTZp' },
  { externalID: 'BLPZpl', coronalID: 'B_TZp' },
  { externalID: 'BRPZpl', coronalID: 'B_TZp' },
];

export const getZoneConfig = zone => {
  switch (zone) {
    case PROSTATE_ZONES.APEX:
      return PROSTATE_AREAS_APEX;
    case PROSTATE_ZONES.BASE:
      return PROSTATE_AREAS_BASE;
    case PROSTATE_ZONES.MID:
      return PROSTATE_AREAS_MID;
    case PROSTATE_ZONES.SV:
      return PROSTATE_AREAS_SV;
    default:
      throw Error('This zone is unknown: ', zone);
  }
};

export {
  CORONAL_MAPPING,
  PROSTATE_AREAS_APEX,
  PROSTATE_AREAS_BASE,
  PROSTATE_AREAS_MID,
  PROSTATE_AREAS_SV,
  ZONE_TO_TEXT_MAPPING,
};
