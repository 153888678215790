import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Icon } from '@ohif/ui';
import { useOptionalComponents } from '../../../../context/OptionalStudyComponentsContext';
import { SizeSelector } from '../SegmentDetailCard/components';

import './BladderComponent.styl';

const SELECTOR_TABLE = {
  total: {
    title: 'Insert Volume',
    setter: [
      { label: 'craniocaudal', key: 'c' },
      { label: 'mediolateral', key: 'm' },
      { label: 'anteriopost.', key: 'a' },
    ],
  },
};

const EMPTY_COMPONENT = {
  detailInfo: {
    measurements: {
      c: '',
      m: '',
      a: '',
    },
  },
};

export const BladderComponent = () => {
  const { UINotificationService } = window.ohif.app.servicesManager.services;
  const [t] = useTranslation('SegmentationPanel');
  const { getComponentByType, setComponentByType } = useOptionalComponents();

  const [isVisible, setIsVisible] = useState(false);
  const [data, setData] = useState(
    getComponentByType({ type: 'bladder' }) || EMPTY_COMPONENT
  );

  const hasMeasurements = () => {
    return !Object.values(data.detailInfo.measurements).some(
      value => value === '' || value === undefined || value === null
    );
  };

  const bladderVolume = () => {
    const { c, m, a } = data.detailInfo.measurements;
    return (((1 / 2) * c * m * a) / 1000).toFixed(1);
  };

  const handleOnSave = () => {
    data.componentType = 'bladder';

    if (!hasMeasurements()) {
      UINotificationService.show({
        type: 'warning',
        title: 'Werte unvollständig',
        message:
          'Einige Werte fehlen oder sind unzulässig, bitte überprüfen Sie Ihre Eingabe.',
      });
      return;
    }

    setComponentByType({ type: 'bladder', update: data })
      .then(() => {
        UINotificationService.show({
          type: 'success',
          title: 'Erfolgreich gespeichert',
          message: 'Die Daten wurden erfolgreich gespeichert.',
        });
      })
      .catch(e => {
        UINotificationService.show({
          type: 'error',
          title: 'Fehler',
          message: 'Die Daten konnten nicht gespeichert werden.',
        });
      });
  };

  return (
    <div className="seg-panel-card bladder">
      <div className="seg-panel-card-header">
        {t('Bladder')}
        <Icon
          className="icon"
          name={isVisible ? 'arrow-up' : 'arrow-down'}
          onClick={() => setIsVisible(!isVisible)}
        />
      </div>
      {isVisible && (
        <div className="seg-panel-card-body">
          {hasMeasurements && (
            <span className="combined-volume-card">
              <span>{t('Combined Volume')}</span>
              <span>
                <span className="bold">{bladderVolume()}</span> <span>ml</span>
              </span>
            </span>
          )}
          <div className="segment-panel-box">
            <span className="heading small">{t('Bladder information')}</span>
            <div className="volume-row">
              <div className="heading smaller">
                {t(SELECTOR_TABLE.total.title)}
              </div>
              <div className="sub-volume-fields">
                {SELECTOR_TABLE.total.setter.map(({ label, key }) => {
                  return (
                    <SizeSelector
                      key={`total-${label}`}
                      title={<span style={{ fontSize: '12px' }}>{label}</span>}
                      value={data.detailInfo.measurements[key]}
                      setValue={value => {
                        setData(old => {
                          let updated = { ...old };
                          updated.detailInfo.measurements[key] = value;
                          return updated;
                        });
                      }}
                    />
                  );
                })}
              </div>
            </div>
            <div className="segmentation-type-area-footer">
              <button onClick={handleOnSave}>{t('Save')}</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
