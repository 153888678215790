import {
  DropDownSelector,
  IncrementalSelector,
  ProstateZoneSelector,
} from '../../components/AltaSegmentationPanel/components/SegmentDetailCard/components';
import { constIsValidSeletion } from '../../components/AltaSegmentationPanel/components/SegmentDetailCard/components/ProstateZoneSelector/ProstateZoneSelector';

const isNotNull = value => value !== null && value !== undefined;

export const PeripheralZoneDetailCardTemplate = [
  {
    rowTitle: null,
    selectors: [
      {
        title: 'appearance',
        label: 'appearance',
        selector: DropDownSelector,
        isValueValid: isNotNull,
        options: ['symetric', 'asymetric'],
        optional: false,
      },
    ],
  },
  {
    rowTitle: null,
    selectors: [
      {
        title: 'compression',
        label: 'compression',
        selector: DropDownSelector,
        isValueValid: isNotNull,
        options: [
          'little',
          'little to medium',
          'medium',
          'medium to strong',
          'strong',
        ],
        optional: false,
      },
    ],
  },
];

export const TransitionZoneDetailCardTemplate = [
  {
    rowTitle: null,
    selectors: [
      {
        title: 'nodularAlteration',
        label: 'nodularAlteration',
        selector: DropDownSelector,
        isValueValid: isNotNull,
        options: [
          'small',
          'small to medium',
          'medium',
          'medium to large',
          'large',
        ],
        optional: false,
      },
    ],
  },
  {
    rowTitle: null,
    selectors: [
      {
        title: 'cysticPortion',
        label: 'cysticPortion',
        selector: DropDownSelector,
        isValueValid: isNotNull,
        options: [
          'small',
          'small to medium',
          'medium',
          'medium to large',
          'large',
        ],
        optional: false,
      },
    ],
  },
];

export const LesionDetailCardTemplate = [
  {
    rowTitle: null,
    selectors: [
      {
        title: 'assessment',
        label: 'assessment',
        selector: DropDownSelector,
        isValueValid: isNotNull,
        options: [
          'Findings in need of clarification',
          'Findings in need of monitoring',
          'Suspected prostate cancer',
          'Urgent suspicion of prostate cancer',
          'Suspected expired, chronic prostatitis',
          'Suspected chronic prostatitis',
          'Confirmed prostate cancer',
          'Cronic prostatitis',
        ],
        optional: false,
      },
    ],
  },
  {
    rowTitle: null,
    selectors: [
      {
        title: 'location',
        label: 'location',
        selector: ProstateZoneSelector,
        isValueValid: constIsValidSeletion,
        optional: false,
      },
    ],
  },
  {
    rowTitle: 'PIRADS',
    selectors: [
      {
        title: 'T2W',
        label: 'piradsT2W',
        selector: IncrementalSelector,
        isValueValid: isNotNull,
        minValue: 0,
        maxValue: 5,
        defaultValue: 3,
        optional: false,
      },

      {
        title: 'b-Val',
        label: 'piradsBVal',
        selector: IncrementalSelector,
        isValueValid: isNotNull,
        minValue: 0,
        maxValue: 5,
        defaultValue: 3,
        optional: false,
      },

      {
        title: 'ADC',
        label: 'piradsADC',
        selector: IncrementalSelector,
        isValueValid: isNotNull,
        minValue: 0,
        maxValue: 5,
        defaultValue: 3,
        optional: false,
      },

      {
        title: 'DCE',
        label: 'piradsDCE',
        selector: IncrementalSelector,
        isValueValid: isNotNull,
        options: ['-', '+', '++'],
        minValue: 0,
        maxValue: 2,
        defaultValue: 1,
        optional: false,
      },
    ],
  },
];
