import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AppointmentTableRow } from '@ohif/ui';
import { StudyListLoadingText } from './StudyListLoadingText.js';
import TableSearchFilter from './TableSearchFilter.js';
import { getContentFromUseMediaValue } from './utils';

import './StudyList.styl';

const getTemplates = t => {
  return {
    largeTableMeta: [
      {
        displayText: t('PatientName'),
        fieldName: 'PatientName',
        inputType: 'text',
        size: 225,
      },
      {
        displayText: t('MRN'),
        fieldName: 'PatientID',
        inputType: 'text',
        size: 150,
      },
      {
        displayText: t('Location'),
        fieldName: 'Location',
        inputType: 'text',
        size: 200,
      },
      {
        displayText: t('Examination'),
        fieldName: 'Examination',
        inputType: 'text',
        size: 225,
      },
      {
        displayText: t('BirthDate'),
        fieldName: 'PatientBirthDate',
        inputType: 'date-range',
        size: 300,
      },
      {
        displayText: t('AppointmentDate'),
        fieldName: 'AppointmentDate',
        inputType: 'date-range',
        size: 300,
      },
      {
        displayText: t('FormStatus'),
        fieldName: 'FormStatus',
        inputType: 'placeholder',
        size: 100,
      },
    ],

    mediumTableMeta: [
      {
        displayText: `${t('PatientName')} / ${t('MRN')}`,
        fieldName: 'patientNameOrId',
        inputType: 'text',
        size: 250,
      },
      {
        displayText: t('Location'),
        fieldName: 'Location',
        inputType: 'text',
        size: 350,
      },
      {
        displayText: t('Examination'),
        fieldName: 'Examination',
        inputType: 'text',
        size: 300,
      },
      {
        displayText: t('AppointmentDate'),
        fieldName: 'AppointmentDate',
        inputType: 'date-range',
        size: 300,
      },
    ],

    smallTableMeta: [
      {
        displayText: t('Search'),
        fieldName: 'allFields',
        inputType: 'text',
        size: 100,
      },
    ],
  };
};

export const AppointmentList = props => {
  const {
    isLoading,
    hasError,
    appointments,
    sort,
    onSort: handleSort,
    filterValues,
    onFilterChange: handleFilterChange,
    onSelectItem: handleSelectItem,
    studyListDateFilterNumDays,
    displaySize,
  } = props;

  const { t, ready: translationsAreReady } = useTranslation('StudyList');
  const { largeTableMeta, mediumTableMeta, smallTableMeta } = getTemplates(t);

  const tableMeta = getContentFromUseMediaValue(
    displaySize,
    { large: largeTableMeta, medium: mediumTableMeta, small: smallTableMeta },
    smallTableMeta
  );

  const totalSize = tableMeta
    .map(field => field.size)
    .reduce((prev, next) => prev + next);

  return translationsAreReady ? (
    <table className="table table--striped table--hoverable">
      <colgroup>
        {tableMeta.map((field, i) => {
          const size = field.size;
          const percentWidth = (size / totalSize) * 100.0;

          return <col key={i} style={{ width: `${percentWidth}px` }} />;
        })}
      </colgroup>
      <thead className="table-head">
        <tr className="filters">
          <TableSearchFilter
            meta={tableMeta}
            values={filterValues}
            onSort={handleSort}
            onValueChange={handleFilterChange}
            sortFieldName={sort.fieldName}
            sortDirection={sort.direction}
            studyListDateFilterNumDays={studyListDateFilterNumDays}
          />
        </tr>
      </thead>
      <tbody className="table-body" data-cy="study-list-results">
        {/* I'm not in love with this approach, but it's the quickest way for now
         *
         * - Display different content based on loading, empty, results state
         *
         * This is not ideal because it create a jump in focus. For loading especially,
         * We should keep our current results visible while we load the new ones.
         */}
        {/* LOADING */}
        {isLoading && (
          <tr className="no-hover">
            <td colSpan={tableMeta.length}>
              <StudyListLoadingText />
            </td>
          </tr>
        )}
        {!isLoading && hasError && (
          <tr className="no-hover">
            <td colSpan={tableMeta.length}>
              <div className="notFound">
                {t('There was an error fetching studies')}
              </div>
            </td>
          </tr>
        )}
        {/* EMPTY */}
        {!isLoading && !appointments.length && (
          <tr className="no-hover">
            <td colSpan={tableMeta.length}>
              <div className="notFound">{t('No matching results')}</div>
            </td>
          </tr>
        )}
        {!isLoading &&
          appointments.map((appointment, index) => {
            return (
              <AppointmentTableRow
                key={`${appointment.PatientID}-${appointment.AppointmentDate}-${index}`}
                onClick={item => handleSelectItem(item)}
                appointment={appointment}
                displaySize={displaySize}
              ></AppointmentTableRow>
            );
          })}
      </tbody>
    </table>
  ) : null;
};

AppointmentList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  appointments: PropTypes.array.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  // ~~ SORT
  sort: PropTypes.shape({
    fieldName: PropTypes.string,
    direction: PropTypes.oneOf(['desc', 'asc', null]),
  }).isRequired,
  onSort: PropTypes.func.isRequired,
  // ~~ FILTERS
  filterValues: PropTypes.shape({
    PatientName: PropTypes.string.isRequired,
    PatientID: PropTypes.string.isRequired,
    Location: PropTypes.string.isRequired,
    Examination: PropTypes.string.isRequired,
    patientNameOrId: PropTypes.string.isRequired,
    allFields: PropTypes.string.isRequired,
    appointmentDateTo: PropTypes.any,
    appointmentDateFrom: PropTypes.any,
    birthDateTo: PropTypes.any,
    birthDateFrom: PropTypes.any,
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  studyListDateFilterNumDays: PropTypes.number,
  displaySize: PropTypes.string,
};

AppointmentList.defaultProps = {};
