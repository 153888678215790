import { useState } from 'react';

// we keep track of send messages and silent them if they already have been send
export const useTrackMessagingFrequency = () => {
  const { UINotificationService } = window.ohif.app.servicesManager.services;

  const [messageDict, setMessageDict] = useState([]);

  const sendMessage = ({ id, type, title, message }) => {
    if (!id) {
      UINotificationService.show({ type, title, message });
      return;
    }

    if (messageDict.includes(id)) return;
    setMessageDict(mDict => [...mDict, id]);
    UINotificationService.show({ type, title, message });
  };

  return { sendMessage };
};
