import { BrushSizeScaler } from './toolbarComponents/BrushSizeScaler';

const TOOLBAR_BUTTON_TYPES = {
  COMMAND: 'command',
  SET_TOOL_ACTIVE: 'setSegmentationToolActive',
  BUILT_IN: 'builtIn',
};

const definitions = [
  {
    id: 'Brush',
    label: 'Brush',
    icon: 'brush',
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setSegmentationToolActive',
    commandOptions: { toolName: 'Brush' },
    group: 'BUTTON::EDIT',
  },
  {
    id: 'BrushEraser',
    label: 'Eraser',
    icon: 'eraser',
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setSegmentationToolActive',
    commandOptions: { toolName: 'BrushEraser' },
    group: 'BUTTON::EDIT',
  },
  {
    id: 'FreehandScissors',
    label: 'Freehand',
    icon: 'inline-edit',
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setSegmentationToolActive',
    commandOptions: { toolName: 'FreehandScissors' },
    group: 'BUTTON::EDIT',
  },
  {
    id: 'FreehandScissorsEraser',
    label: 'Freehand Delete',
    icon: 'liver',
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setSegmentationToolActive',
    commandOptions: { toolName: 'FreehandScissorsEraser' },
    group: 'BUTTON::EDIT',
  },
  {
    id: 'Undo',
    label: 'Undo',
    icon: 'undo',
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'undoLabelmapOperation',
    group: 'BUTTON::EDIT',
  },
  {
    id: 'Redo',
    label: 'Redo',
    icon: 'redo',
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'redoLabelmapOperation',
    group: 'BUTTON::EDIT',
  },
  {
    id: 'changeBrushSize',
    label: 'Brushsize',
    icon: 'soft-tissue',
    CustomComponent: BrushSizeScaler,
    group: 'BUTTON::EDIT',
  },
];

export default {
  definitions,
  defaultContext: 'ACTIVE_VIEWPORT::CORNERSTONE',
};
