import React from 'react';

const ResourceContext = React.createContext(null);
export const useResources = () => React.useContext(ResourceContext);

export const ResourceContextProvider = ({ children, resources }) => {
  return (
    <ResourceContext.Provider value={resources}>
      {children}
    </ResourceContext.Provider>
  );
};
