import { CustomScrollableArea, LoadingSpinner } from '@ohif/ui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IndicationArea, PSAArea, UrologicArea } from '../components';

import { hooks } from '@ohif/core';
import { ReportContextProvider } from '../context/reportContext';
import './ReportPanel.styl';

const ReportPanel = props => {
  const { t } = useTranslation('ReportPanel');

  const loading = hooks.useLoadingStudy(props.studies);
  const [reportExists, setReportExists] = useState(false);

  useEffect(() => {
    if (loading) return;

    const { apiInterface } = window.ohif.app;
    apiInterface
      .hasReportData({
        studyInstanceUID: props.activeViewport.StudyInstanceUID,
      })
      .then(resp => {
        setReportExists(resp.exists);
      });
  }, [loading]);

  return (
    <div className="report-panel">
      <div className="heading panel-heading">{t('Patientinformation')}</div>
      {loading ? (
        <LoadingSpinner />
      ) : reportExists ? (
        <ReportContextProvider
          studyInstanceUID={props.activeViewport.StudyInstanceUID}
        >
          <CustomScrollableArea>
            <IndicationArea />
            <PSAArea />
            <UrologicArea />
          </CustomScrollableArea>
        </ReportContextProvider>
      ) : (
        <span className="alt-text">{t('No patient data was found')}</span>
      )}
    </div>
  );
};

export default ReportPanel;
