import { useContext, useEffect } from 'react';

import { SegmentationPanelContext } from '../SegmentationPanelContext';

export const useMergeSegment = () => {
  const { UINotificationService } = window.ohif.app.servicesManager.services;
  const {
    setSegmentMergeState,
    segmentMergeState,
    onMergeSegments,
  } = useContext(SegmentationPanelContext);

  useEffect(() => {
    if (!segmentMergeState.segmentMergeModeActive) {
      setSegmentMergeState({
        ...segmentMergeState,
        checkedSegments: [],
      });
    }
  }, [segmentMergeState.segmentMergeModeActive]);

  const addSegmentByTrackingUID = trackingUID => {
    setSegmentMergeState({
      ...segmentMergeState,
      checkedSegments: [...segmentMergeState.checkedSegments, trackingUID],
    });
  };

  const removeSegmentByTrackingUID = trackingUID => {
    setSegmentMergeState({
      ...segmentMergeState,
      checkedSegments: segmentMergeState.checkedSegments.filter(
        tUID => tUID !== trackingUID
      ),
    });
  };

  const resetCheckedSegments = () => {
    setSegmentMergeState({
      ...segmentMergeState,
      checkedSegments: [],
    });
  };

  const onMergeSegmentModeToggle = () => {
    resetCheckedSegments();

    setSegmentMergeState({
      ...segmentMergeState,
      segmentMergeModeActive: !segmentMergeState.segmentMergeModeActive,
    });
  };

  const _checkValidSegmentSelection = () => {
    if (segmentMergeState.checkedSegments.length < 2) {
      UINotificationService.show({
        type: 'warning',
        message: 'Bitte wählen Sie mindestens zwei Segmente aus',
        title: 'Ungültige Auswahl',
      });
      return false;
    }

    return true;
  };

  return {
    segmentMergeModeActive: segmentMergeState.segmentMergeModeActive,
    addSegmentByTrackingUID,
    removeSegmentByTrackingUID,
    onMergeSegmentsConfirm: () => {
      if (!_checkValidSegmentSelection()) {
        return;
      }
      onMergeSegments({ trackingUIDs: segmentMergeState.checkedSegments });
      onMergeSegmentModeToggle();
    },
    onMergeSegmentModeToggle,
  };
};
