import React, { useState, useEffect } from 'react';
import Dropzone from './Dropzone';
import OHIFModal from '../ohifModal/OHIFModal';
import { sendFiles } from './sendFiles';
import ProgressBar from './ProgressBar/ProgressBar';
import './UploadModal.styl';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const UploadModal = props => {
  const { isOpen, closeModal } = props;
  const [files, setFiles] = useState();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [onUpload, setOnUpload] = useState(false);

  const { UINotificationService } = window.ohif.app.servicesManager.services;

  const handleOnSubmit = () => {
    if (!files.length) {
      UINotificationService.show({
        title: 'Invalid upload configureation',
        message: 'No files were found that can be uploaded',
        type: 'warning',
      });
      return;
    }

    window.addEventListener('beforeunload', () => {});

    sendFiles({ files, setUploadProgress, setOnUpload })
      .then(successfullUpload => {
        successfullUpload
          ? UINotificationService.show({
              title: 'Upload Successfull',
              message:
                'The files have been uploaded successfully. Please refresh the page to access the uploaded data',
              type: 'success',
            })
          : UINotificationService.show({
              title: 'Upload failed',
              message:
                'Not all files could be uploaded to the server. Please check the filetype of the files that failed!',
              type: 'error',
            });
      })
      .finally(() => {
        setTimeout(() => {
          setOnUpload(false);
          setFiles(null);
          window.removeEventListener('beforeunload', () => {});
        }, 2000);
      });
  };

  return (
    <>
      <OHIFModal
        title={'Upload Files'}
        isOpen={isOpen}
        style={customStyles}
        shouldCloseOnEsc={true}
        closeButton={true}
        onClose={closeModal}
      >
        <Dropzone
          files={files}
          setFiles={setFiles}
          onUpload={onUpload}
        ></Dropzone>
        {onUpload && <ProgressBar progress={uploadProgress} />}
        <button
          className={
            files && files.length > 0 && !onUpload ? 'active' : 'non-active'
          }
          type="submit"
          onClick={handleOnSubmit}
        >
          Upload
        </button>
      </OHIFModal>
    </>
  );
};

export default UploadModal;
