import './TextArea.css';

import React from 'react';
import PropTypes from 'prop-types';

class TextArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value };
    this.emptyValue = this.props.emptyValue || '';
  }

  handleChange = event => {
    this.setState({ value: event.target.value });
    if (this.props.onChange) this.props.onChange(event.target.value);
  };

  handleBlur = () => {
    if (this.props.onBlur) this.props.onBlur(this.state.value);
  };

  render() {
    return (
      <div className="textarea-wrapper">
        {this.emptyValue && this.state.value === '' && (
          <div className="emptyValue">{this.emptyValue}</div>
        )}
        <textarea
          className="textarea-ohif"
          value={this.state.value}
          rows={this.props.rows}
          cols={this.props.cols}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          id={this.props.id}
        />
      </div>
    );
  }
}

TextArea.propTypes = {
  value: PropTypes.string,
  Rows: PropTypes.number,
  cols: PropTypes.number,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  emptyValue: PropTypes.string,
};

export { TextArea };
