import React from 'react';
import { useTranslation } from 'react-i18next';

import './ConsenseModal.styl';

export const ConsenseModal = ({ onAccept, onDecline }) => {
  const [t] = useTranslation('Common');
  return (
    <div className="consense-modal">
      <button onClick={onAccept}>{t('Yes')}</button>
      <button onClick={onDecline}>{t('No')}</button>
    </div>
  );
};
