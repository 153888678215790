import React, { useContext, useEffect, useState } from 'react';

export const ReportContext = React.createContext(null);

const requestResources = async ({ studyInstanceUID }) => {
  const { apiInterface } = window.ohif.app;

  const reportPromise = new Promise((resolve, _) => {
    apiInterface
      .getReportData({ studyInstanceUID })
      .then(report => resolve(report))
      .catch(error => {
        console.warn(error);
        resolve({});
      });
  });

  const segmentationPromise = new Promise((resolve, _) => {
    apiInterface
      .getSegmentationList({
        studyInstanceUID,
        complete: true,
      })
      .then(segmentations => resolve(segmentations))
      .catch(error => {
        console.warn(error);
        resolve([]);
      });
  });

  const studyPromise = new Promise((resolve, _) => {
    apiInterface
      .getStudy({
        studyInstanceUID,
      })
      .then(study => resolve(study))
      .catch(error => {
        console.warn(error);
        resolve({});
      });
  });
  return Promise.all([reportPromise, segmentationPromise, studyPromise]);
};

export const ReportContextProvider = ({
  children,
  studyInstanceUID,
  userManager,
  user,
  reportSettings,
}) => {
  const [reportData, setReportData] = useState(null);

  useEffect(() => {
    requestResources({ studyInstanceUID })
      .then(response => {
        setReportData({
          report: response[0],
          segmentations: response[1],
          study: response[2],
        });
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <ReportContext.Provider
      value={{ reportData, setReportData, user, userManager, reportSettings }}
    >
      {reportData && children}
    </ReportContext.Provider>
  );
};

export const useReportData = () => {
  return useContext(ReportContext);
};
