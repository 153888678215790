import './CustomTimePicker.styl';

import React, { useEffect } from 'react';
import TimePicker from 'react-time-picker';

export const CustomTimePicker = ({ value, onChange }) => {
  const changeMinValue = () => {
    const numberInput = document.getElementsByClassName(
      'react-time-picker__inputGroup__input react-time-picker__inputGroup__minute'
    )[0];
    numberInput.min = -1;
    numberInput.addEventListener('focus', function(event) {
      event.target.blur(); // Prevent focus on the input field
    });
  };

  useEffect(() => {
    changeMinValue();
  }, []);

  return (
    <div>
      <TimePicker
        onChange={onChange}
        value={value}
        disableClock={true} // Disables the clock icon and analog clock
        format="H:m" // Specifies the format as hours and minutes
      />
    </div>
  );
};
