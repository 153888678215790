import { Icon } from '@ohif/ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './UploadInterface.styl';
import UploadModal from './UploadModal';

const UploadInterface = props => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [t] = useTranslation('StudyList');
  const handleOnClick = () => {
    setShowUploadModal(true);
  };

  return (
    <>
      <div
        className="upload-interface-button"
        onClick={handleOnClick}
        style={props.style}
      >
        <h1>{t('Upload Files')}</h1>
        <div className="icon-container">
          <Icon name="upload-icon" className="upload-icon" />
        </div>
      </div>

      <UploadModal
        isOpen={showUploadModal}
        closeModal={() => setShowUploadModal(false)}
      />
    </>
  );
};

export default UploadInterface;
