import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import { OpenSelector } from '@ohif/ui';

const Option = ({ t, text, isHighlighted = false }) => {
  return (
    <div
      key={text}
      className={classnames('choice-opt', {
        highlighted: isHighlighted,
      })}
    >
      <span>{t(text)}</span>
    </div>
  );
};
export const MultiOptionSelector = ({
  t,
  choices,
  singletons,
  selectedValues,
  onSelect,
}) => {
  const createOptions = () => {
    return choices.map(opt => {
      return <Option t={t} key={opt} text={opt} />;
    });
  };

  const [options, setOptions] = useState(createOptions());
  const [selectedOptions, setSelectedOptions] = useState(selectedValues || []);

  useEffect(() => {
    setOptions(() => {
      return createOptions().map(opt => {
        if (!selectedOptions) return opt;

        if (!selectedOptions.includes(opt.props.text)) return opt;

        return (
          <Option
            t={t}
            key={opt.props.text}
            text={opt.props.text}
            isHighlighted={true}
          />
        );
      });
    });

    if (onSelect) onSelect(selectedOptions);
  }, [selectedOptions]);

  return (
    <div className="panel-area">
      <OpenSelector
        options={options}
        onSelect={opt =>
          setSelectedOptions(() => {
            let updatedSelection = [...selectedOptions];

            if (singletons.includes(opt.props.text)) {
              return [opt.props.text];
            }

            const containsSingleton = singletons.some(item =>
              updatedSelection.includes(item)
            );

            // remove singletons
            if (containsSingleton) {
              updatedSelection = updatedSelection.filter(
                item => !singletons.includes(item)
              );
            }

            if (selectedOptions.includes(opt.props.text)) {
              updatedSelection = updatedSelection.filter(
                item => item != opt.props.text
              );
            } else {
              updatedSelection.push(opt.props.text);
            }
            return updatedSelection;
          })
        }
      />
    </div>
  );
};
