import React from 'react';

import { useReactiveManualVolume } from '../../../../../context/hooks/useReactiveManualVolume';

import './SegmentCardComponents.styl';

export const ZoneVolumeField = ({ volume, trackingUID }) => {
  const {
    hasManualVolume,
    getVolumeByTrackingUID,
    manualVolume,
    combinedVolume,
  } = useReactiveManualVolume();

  const displayedCombinedVolume = hasManualVolume()
    ? Number(manualVolume.total).toFixed(1)
    : Number(combinedVolume.toFixed(1));

  const displayedVolume = hasManualVolume()
    ? getVolumeByTrackingUID({ trackingUID })
    : volume;

  return (
    <div className="segment-card-field">
      <div className="sc-body">
        <div className="volume">
          <span>{`${displayedVolume} ml`}</span>
          {hasManualVolume() && <span className="astrix">*</span>}
        </div>
        {displayedCombinedVolume !== 0 && (
          <div style={{ marginLeft: '5px' }} className="volume-percentage">
            {`(${((displayedVolume / displayedCombinedVolume) * 100).toFixed(
              0
            )} %)`}
          </div>
        )}
      </div>
    </div>
  );
};
