import React from 'react';

import classnames from 'classnames';

import { useTranslation } from 'react-i18next';
import { CONFIDENCE_LEVEL } from '../../../../../context/constants/ConfidenceLevel';
import { useEditingInfo } from '../../../../../context/hooks/useEditingInformation';
import { MultiChoiceSelector } from '../../SegmentDetailCard/components';

const PoorSegmentationQualityExplanation = ({ options }) => {
  const [t] = useTranslation('SegmentationPanel');

  const { getEditingInfo, updateSegmentationErrors } = useEditingInfo();
  const editingInfo = getEditingInfo();

  return (
    <div className="poor-segmentation-quality-explanation">
      <span>{t('Reasons for poor segmentation quality')}:</span>
      <MultiChoiceSelector
        t={t}
        choices={editingInfo.getSegmentationErrors().possibleSegmentationErrors}
        selectedChoices={editingInfo.getSegmentationErrors().selectedErrors}
        onSelectionChange={segmentationErrors =>
          updateSegmentationErrors({ segmentationErrors })
        }
        allowCustomChoice={true}
      />
    </div>
  );
};

export const FurtherEditingInformationSection = () => {
  const [t] = useTranslation('SegmentationPanel');

  const { getEditingInfo } = useEditingInfo();
  const editingInfo = getEditingInfo();

  const infoSectionActive =
    editingInfo.getConfidenceLevel() !== CONFIDENCE_LEVEL.PERFECT;

  return (
    <div
      className={classnames('further-editing-information-section', {
        active: infoSectionActive,
      })}
    >
      <div className="further-editing-info-header">
        <span className="heading small">{t('Furher Information')}</span>
      </div>
      {infoSectionActive ? (
        <PoorSegmentationQualityExplanation />
      ) : (
        <>{t('No informations available')}</>
      )}
    </div>
  );
};
