import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Icon } from '@ohif/ui';

import { getContentFromUseMediaValue } from '../utils';

import './../StudyList.styl';

const FormStatus = ({ status }) => {
  switch (status) {
    case 'not_started':
      return (
        <Icon
          style={{ color: '##DB0000' }}
          name="cross-circle"
          height="24px"
          width="24px"
        />
      );
    case 'complete':
      return (
        <Icon
          style={{ color: '#35B442' }}
          className="icon"
          name="hook-circle"
          height="24px"
          width="24px"
        />
      );
    case 'incomplete':
      return <Icon style={{ color: '#C1853E' }} name="minus-circle" />;
    default:
      return <div className="span">{status}</div>;
  }
};

const AppointmentEntry = ({ appointmentDate, appointmentTime }) => {
  function formatTimeString(timeString) {
    const [hours, minutes] = timeString.split(':');
    return `${hours}:${minutes}`;
  }

  return (
    <>
      <span>{appointmentDate}</span>
      <span style={{ marginLeft: '10px' }}>{`(${formatTimeString(
        appointmentTime
      )})`}</span>
    </>
  );
};

export const AppointmentTableRow = props => {
  const {
    isHighlighted,
    appointment,
    onClick: handleClick,
    displaySize,
  } = props;

  const { t } = useTranslation('StudyList');

  const largeRowTemplate = (
    <tr
      onClick={() => handleClick(appointment)}
      className={classNames({ active: isHighlighted })}
    >
      <td className={classNames({ 'empty-value': !appointment.PatientName })}>
        {appointment.PatientName || `(${t('Empty')})`}
      </td>
      <td>{appointment.PatientID}</td>
      <td>{appointment.Location}</td>
      <td>{appointment.Examination}</td>
      <td>{appointment.PatientBirthDate}</td>
      <td>
        {
          <AppointmentEntry
            appointmentDate={appointment.AppointmentDate}
            appointmentTime={appointment.AppointmentTime}
          />
        }
      </td>
      <td>{<FormStatus status={appointment.FormStatus} />}</td>
    </tr>
  );

  const mediumRowTemplate = (
    <tr
      onClick={() => handleClick(appointment)}
      className={classNames({ active: isHighlighted })}
    >
      <td className={classNames({ 'empty-value': !appointment.PatientName })}>
        {appointment.PatientName || `(${t('Empty')})`}
        <div style={{ color: '#60656f' }}>{appointment.PatientID}</div>
      </td>
      <td>{appointment.Location}</td>
      <td>{appointment.Examination}</td>
      <td>{appointment.AppointmentDate}</td>
    </tr>
  );

  const smallRowTemplate = (
    <tr
      onClick={() => handleClick(appointment)}
      className={classNames({ active: isHighlighted })}
    >
      <td style={{ position: 'relative', overflow: 'hidden' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* NAME AND ID */}
          <div
            className={classNames({ 'empty-value': !appointment.PatientName })}
            style={{ width: '150px', minWidth: '150px', marginLeft: '20px' }}
          >
            <div style={{ fontWeight: 500, paddingTop: '3px' }}>
              {appointment.PatientName || `(${t('Empty')})`}
            </div>
            <div style={{ color: '#60656f' }}>{appointment.PatientID}</div>
          </div>

          {/* DATE */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '80px',
              width: '80px',
              marginRight: '20px',
            }}
          >
            <div>{appointment.AppointmentDate}</div>
          </div>
        </div>
      </td>
    </tr>
  );

  const rowTemplate = getContentFromUseMediaValue(
    displaySize,
    {
      large: largeRowTemplate,
      medium: mediumRowTemplate,
      small: smallRowTemplate,
    },
    smallRowTemplate
  );

  return rowTemplate;
};

AppointmentTableRow.propTypes = {
  isHighlighted: PropTypes.bool,
  appointment: PropTypes.shape({
    AppointmentDate: PropTypes.string.isRequired,
    AppointmentTime: PropTypes.string.isRequired,
    Examination: PropTypes.string.isRequired,
    FormStatus: PropTypes.string.isRequired,
    Location: PropTypes.string.isRequired,
    PatientBirthDate: PropTypes.string.isRequired,
    PatientID: PropTypes.string.isRequired,
    PatientName: PropTypes.string.isRequired,
  }).isRequired,
  displaySize: PropTypes.string,
};

AppointmentTableRow.defaultProps = {
  isHighlighted: false,
};
