import { redux, utils } from '@ohif/core';
import { ConsenseModal, LoadingSpinnerNotificationCard } from '@ohif/ui';
import cornerstoneTools from 'cornerstone-tools';
import React, { createContext, useContext, useEffect, useState } from 'react';
import DICOMSegTempCrosshairsTool from '../tools/DICOMSegTempCrosshairsTool';
import refreshViewports from '../utils/refreshViewports';
import {
  MetadataPromise,
  createMockSegmentMeta,
  getActiveLabelMapIndex,
  getActiveLabelMaps2D,
  getActiveLabelMaps3D,
  getActiveLabelMaps3DBySeriesInstanceUID,
  getActiveSegmentIndex,
  getBrushStackState,
  getCurrentDisplaySet,
  getLabelMapList,
  getSegmentList,
  isT2,
  tryOpenT2Viewport,
} from './utils';

import { setTimeout } from 'core-js';
import { toggleEditMode as toggleEditDispatch } from '../../../../platform/core/src/redux/thunks/editMode';
import { useViewerConfig } from '../../../../platform/viewer/config/ViewerConfigProvider';
import store from '../../../../platform/viewer/src/store';
import {
  createSegment,
  deleteSegment,
  mergeSegments,
  exportSegmentation,
} from '../utils/SegmentationUtils';
import { SegmentationMetadata } from './classes/SegmentationMetadata';

const { studyMetadataManager } = utils;
const { localStorage } = redux;
const { setActiveSegmentation } = redux.actions;

export const SegmentationPanelContext = createContext(null);

const useSegmentationPanelProvider = ({
  studies,
  activeIndex,
  activeViewport,
  viewports,
  activeContexts,
  contexts,
  onConfigurationChange,
  onSegmentVisibilityChange,
  onSelectedSegmentationChange,
  onDisplaySetLoadFailure,
  onSegmentItemClick,
}) => {
  const {
    UINotificationService,
    UIModalService,
  } = window.ohif.app.servicesManager.services;
  const { apiInterface } = window.ohif.app;

  const { viewerConfig } = useViewerConfig();
  const segmentationPanelProvider =
    viewerConfig.segmentations.segmentationPanelConfigProvider;

  const [segmentationState, setSegmentationState] = useState({
    segmentsHidden: [],
    segmentList: [],
    labelMapList: [],
    isT2: false,
    segmentationMetadata: null,
    selectedSegment: null,
    segmentationIsLoading: null,
    selectedSegmentation: null,
    selectedSegmentationType: null,
    segmentationTypeConfig: null,
    pendingSegmentationSelection: null,
  });

  const [segmentMergeState, setSegmentMergeState] = useState({
    segmentMergeModeActive: false,
    checkedSegments: [],
  });

  const [visibleSegments, setVisibleSegments] = useState([]);

  useEffect(() => {
    document.addEventListener(
      'selectedSegmentationFromThumbnail',
      selectSegmentationBySeriesInstanceUID
    );
    document.addEventListener('addedNewSeries', refreshSegmentations);
    document.addEventListener('onSegmentationAddedEnd', loadNewSegmentation);
    document.addEventListener('setSegmentActive', _setActiveSegmentByEvent);
    document.addEventListener('toggleVisibility', _toggleVisibility);

    return () => {
      document.removeEventListener(
        'selectedSegmentationFromThumbnail',
        selectSegmentationBySeriesInstanceUID
      );
      document.removeEventListener('addedNewSeries', refreshSegmentations);
      document.removeEventListener(
        'onSegmentationAddedEnd',
        loadNewSegmentation
      );
      document.removeEventListener(
        'setSegmentActive',
        _setActiveSegmentByEvent
      );
      document.removeEventListener('toggleVisibility', _toggleVisibility);
    };
  }, [
    activeIndex,
    activeViewport,
    segmentationState,
    segmentationState.labelMapList,
    segmentationState.selectedSegmentation,
  ]);

  // Resolve pending segmentation selection
  useEffect(() => {
    if (segmentationState.pendingSegmentationSelection === null) return;

    selectSegmentationBySeriesInstanceUID({
      detail: {
        seriesInstanceUID: segmentationState.pendingSegmentationSelection,
      },
    });
  }, [segmentationState]);

  useEffect(() => {
    if (
      segmentationState.segmentationIsLoading ||
      (!activeViewport || !activeViewport.StudyInstanceUID)
    )
      return;

    const isT2Open = isT2({ activeViewport });
    // init labelmaps only when t2 is selected
    if (isT2Open) {
      refreshSegmentations();
    }

    setSegmentationState(state => ({ ...state, isT2: isT2Open }));
  }, [studies, activeViewport, segmentationState.segmentationIsLoading]);

  const selectSegmentationByType = ({ segmentationType }) => {
    if (!isT2({ activeViewport }) && !tryOpenT2Viewport({ viewports })) {
      UINotificationService.show({
        type: 'warning',
        title: 'Keine Segmentierung gefunden',
        message:
          'Für die ausgewählte Segmentierung scheint keine Serie in den derzeitigen Viewports zu existieren.',
      });
      return;
    }

    const foundSegmentation = segmentationState.labelMapList.find(
      seg =>
        seg.segmentationType === segmentationType &&
        seg.segmentationOrigin === 'manual'
    );

    setSegmentationState(state => ({
      ...state,
      segmentationIsLoading: true,
    }));

    _selectSegmentation({ segmentation: foundSegmentation }).then(() => {
      refreshSegmentations();
      setSegmentationState(state => ({
        ...state,
        segmentationIsLoading: false,
      }));

      if (!foundSegmentation) {
        // We have to fake a segmentation so that out UI components render and we can notify the user that there was no segmentation
        setSegmentationState(state => ({
          ...state,
          selectedSegmentationType: segmentationType,
        }));
      } else {
        store.dispatch(
          setActiveSegmentation({
            studyInstanceUID: foundSegmentation.studyInstanceUID,
            seriesInstanceUID: foundSegmentation.seriesInstanceUID,
          })
        );
      }
    });
  };

  const selectSegmentationBySeriesInstanceUID = e => {
    refreshSegmentations();

    const seriesInstanceUID = e.detail.seriesInstanceUID;
    const foundSegmentation = segmentationState.labelMapList.find(
      seg => seg.seriesInstanceUID === seriesInstanceUID
    );

    if (!foundSegmentation) {
      setSegmentationState(state => ({
        ...state,
        pendingSegmentationSelection: seriesInstanceUID,
      }));
      return;
    }

    setSegmentationState(state => ({
      ...state,
      segmentationIsLoading: true,
    }));

    store.dispatch(
      setActiveSegmentation({
        studyInstanceUID: foundSegmentation.studyInstanceUID,
        seriesInstanceUID: foundSegmentation.seriesInstanceUID,
      })
    );

    // mark series as resolved
    if (seriesInstanceUID === segmentationState.pendingSegmentationSelection)
      setSegmentationState(state => ({
        ...state,
        pendingSegmentationSelection: null,
      }));

    setTimeout(() => {
      _selectSegmentation({ segmentation: foundSegmentation }).then(() => {
        refreshSegmentations();
        setSegmentationState(state => ({
          ...state,
          segmentationIsLoading: false,
        }));
      });
    }, 300);
  };

  const refreshSegmentations = () => {
    const brushStackState = getBrushStackState({ activeViewport });
    if (brushStackState) {
      const labelMapList = getLabelMapList({
        studies,
        activeViewport,
        onSelectedSegmentationChange,
        onDisplaySetLoadFailure,
        setSelectedSegmentation: ({
          selectedSegmentation,
          selectedSegmentationType,
        }) => {
          setSegmentationState(state => ({
            ...state,
            selectedSegmentation,
            selectedSegmentationType,
          }));
        },
      });

      let segmentList = [];
      let segmentsHidden = [];

      if (_isSegmentationSelected({ brushStackState })) {
        const segList = getSegmentList({ activeViewport });
        segmentList = segList.segmentList;
        segmentsHidden = segList.segmentsHidden;
      }

      setSegmentationState(state => ({
        ...state,
        labelMapList,
        segmentList,
        segmentsHidden,
      }));
    }
  };

  const loadNewSegmentation = e => {
    selectSegmentationBySeriesInstanceUID(e);
  };

  const saveSegmentation = async () => {
    const showWarning = title => {
      UINotificationService.show({
        type: 'warning',
        title: 'Speichern nicht möglich',
        message: title,
      });
    };

    if (!segmentationState.segmentList.length) {
      showWarning('Eine Segmentierung muss mindestens ein Segment enthalten');
      return;
    }

    const saveAllowed = segmentationState.segmentationTypeConfig.onSaveCallback(
      { segmentationMetadata: segmentationState.segmentationMetadata }
    );

    if (!saveAllowed) {
      showWarning(
        'Die Daten der Segmentierung sind nicht vollständig. Bitte überprüfen Sie die Eingaben und versuchen Sie es erneut.'
      );
      return;
    }

    const {
      studyInstanceUID,
      seriesInstanceUID,
    } = segmentationState.segmentationMetadata;

    if (
      segmentationContainsEmptySegments({
        seriesInstanceUID,
      })
    ) {
      showWarning('Segmentierungen dürfen keine leeren Segmente enthalten');
      return;
    }

    _loadingSpinnerDialog({
      text:
        'Upload der Segmentierung! Bitte schließen Sie nicht dieses Fenster',
    });

    window.addEventListener('beforeunload', () => {});

    apiInterface.assignUser({
      studyInstanceUID,
      userID: store.getState().oidc.user.profile.sub,
    });

    apiInterface.patchSegmentationErrors({
      segmentType: segmentationState.selectedSegmentationType,
      errors: segmentationState.segmentationMetadata
        .getEditingInfo()
        .getSegmentationErrors().possibleSegmentationErrors,
    });

    const trackingUIDs = segmentationState.segmentationMetadata.getTrackingUIDs();
    const labelmap3D = getActiveLabelMaps3DBySeriesInstanceUID({
      seriesInstanceUID,
    });
    const sopInstanceUID = _getFirstSopUIDOfSeries({ seriesInstanceUID });

    exportSegmentation({
      labelmap3D,
      studyInstanceUID,
      sopInstanceUID,
      metadata: {
        ...segmentationState.segmentationMetadata.toJson(),
        segmentType: segmentationState.selectedSegmentationType,
      },
    })
      .then(() => {
        UINotificationService.show({
          title: 'Erfolg',
          message: 'Die Segmentierung konnte erfolgreicht abgespeichert werden',
          type: 'success',
        });
      })
      .catch(e => {
        UINotificationService.show({
          title: 'Failed to save the edited segmentation',
          message: e.title || e.message,
          type: 'error',
        });
      })
      .finally(() => {
        window.removeEventListener('beforeunload', () => {});
        document.dispatchEvent(
          new CustomEvent('onSegmentationSaveEnd', {
            detail: { trackingUIDs },
          })
        );
        // HACK: we fake an event here -> really dirty :(
        selectSegmentationBySeriesInstanceUID({
          detail: { seriesInstanceUID },
        });
      });

    toggleEditMode({ editModeActive: false });
  };

  const onDeleteSegmentation = () => {
    if (_isVTK()) {
      showSegmentManipulationError();
      return;
    }

    const {
      seriesInstanceUID,
      studyInstanceUID,
    } = segmentationState.segmentationMetadata;
    const studyMetadata = studyMetadataManager.get(studyInstanceUID);

    const onDeleteAccept = () => {
      // delete segmentation from server
      apiInterface
        .deleteSegmentation({ studyInstanceUID, seriesInstanceUID })
        .then(() => {
          // delete metadata
          studyMetadata.deleteSeries(seriesInstanceUID);

          // delete labelmap
          const state = getBrushStackState({ activeViewport });
          state.labelmaps3D.splice(state.activeLabelmapIndex, 1);
          state.activeLabelmapIndex = null;

          _minimzeAllSegmentations();
          refreshSegmentations();
          refreshViewports();
          document.dispatchEvent(new CustomEvent('rerender'));
          document.dispatchEvent(new CustomEvent('deleteSegmentation'));

          UINotificationService.show({
            type: 'success',
            title: 'Segmentierung erfolgreich gelöscht',
          });
        })
        .catch(e => {
          UINotificationService.show({
            type: 'error',
            title: e.title,
            message: e.message,
          });
        })
        .finally(() => {
          UIModalService.hide();
        });
    };

    UIModalService.show({
      content: ConsenseModal,
      title: 'Wollen Sie diese Segmentierung wirkich löschen?',
      contentProps: {
        onAccept: onDeleteAccept,
        onDecline: UIModalService.hide,
      },
    });
  };

  const toggleSegmentationVisibility = () => {
    const isHidden = isSegmentationVisible();

    let segmentsHidden = [];
    segmentationState.segmentList.forEach(segment => {
      const { segmentNumber } = segment;

      if (_isVTK()) {
        onSegmentVisibilityChange(segmentNumber, !isHidden);
      }

      /** Get all labelmaps with this segmentNumber (overlapping segments) */
      const { labelmaps3D } = getBrushStackState({ activeViewport });
      const possibleLabelMaps3D = labelmaps3D.filter(({ labelmaps2D }) => {
        return labelmaps2D.some(({ segmentsOnLabelmap }) =>
          segmentsOnLabelmap.includes(segmentNumber)
        );
      });

      possibleLabelMaps3D.forEach(labelmap3D => {
        labelmap3D.segmentsHidden[segmentNumber] = isHidden;
        segmentsHidden = [
          ...new Set([...segmentsHidden, ...labelmap3D.segmentsHidden]),
        ];
      });
    });

    refreshSegmentations();
    refreshViewports();
  };

  const toggleEditMode = ({ editModeActive }) => {
    if (_isVTK()) {
      showSegmentManipulationError();
      return;
    }
    setActiveSegment({ segmentIndex: 1 });
    store.dispatch(toggleEditDispatch(editModeActive));
  };

  const seriesInstanceUIDProvider = () => {
    return segmentationState.segmentationMetadata
      ? segmentationState.segmentationMetadata.seriesInstanceUID
      : null;
  };

  const segmentationContainsEmptySegments = ({ seriesInstanceUID }) => {
    const labelmap3D = getActiveLabelMaps3DBySeriesInstanceUID({
      seriesInstanceUID,
    });
    const numberOfSegments = segmentationState.segmentList.length;

    const view = new DataView(labelmap3D.buffer);

    const valueCounts = new Map();
    for (let i = 0; i < labelmap3D.buffer.byteLength; i += 2) {
      const value = view.getInt16(i);
      if (valueCounts.has(value)) {
        valueCounts.set(value, valueCounts.get(value) + 1);
      } else {
        valueCounts.set(value, 1);
      }
    }

    return valueCounts.size < numberOfSegments + 1;
  };

  /* Segment Operations */

  const toggleSegmentVisibility = ({ targetSegmentNumber }) => {
    let segmentsHidden = [];
    segmentationState.segmentList.forEach(segment => {
      const { segmentNumber } = segment;

      /** Get all labelmaps with this segmentNumber (overlapping segments) */
      const { labelmaps3D } = getBrushStackState({ activeViewport });
      const possibleLabelMaps3D = labelmaps3D.filter(({ labelmaps2D }) => {
        return labelmaps2D.some(({ segmentsOnLabelmap }) =>
          segmentsOnLabelmap.includes(segmentNumber)
        );
      });

      possibleLabelMaps3D.forEach(labelmap3D => {
        const isHidden =
          segmentNumber === targetSegmentNumber
            ? !labelmap3D.segmentsHidden[segmentNumber]
            : labelmap3D.segmentsHidden[segmentNumber];

        if (_isVTK()) {
          onSegmentVisibilityChange(segmentNumber, !isHidden);
        }

        labelmap3D.segmentsHidden[segmentNumber] = isHidden;
        segmentsHidden = [
          ...new Set([...segmentsHidden, ...labelmap3D.segmentsHidden]),
        ];
      });
    });

    refreshSegmentations();
    refreshViewports();
  };

  const isSegmentationVisible = () => {
    if (
      !segmentationState.segmentsHidden.length ||
      !segmentationState.segmentList.length
    ) {
      return false;
    }

    for (var segmentIndex in segmentationState.segmentList) {
      const segment = segmentationState.segmentList[segmentIndex];
      if (!segmentationState.segmentsHidden[segment.segmentNumber]) return true;
    }

    return false;
  };

  const setActiveSegment = ({ segmentIndex }) => {
    if (segmentIndex > segmentationState.segmentList.length) {
      UINotificationService.show({
        type: 'warning',
        message:
          'Das ausgewählte Segment konnte nicht gefunden/ausgewählt werden',
        title: 'Auswahl nicht möglich',
      });
    }

    const activeSegmentIndex = getActiveSegmentIndex({ activeViewport });

    setSegmentationState(state => ({
      ...state,
      selectedSegment: segmentIndex,
    }));

    if (segmentIndex === activeSegmentIndex) {
      return;
    }

    const labelmap3D = getActiveLabelMaps3D({ activeViewport });
    labelmap3D.activeSegmentIndex = segmentIndex;

    /**
     * Activates the correct label map if clicked segment
     * does not belong to the active labelmap
     */
    const { StudyInstanceUID } = activeViewport;
    const studyMetadata = studyMetadataManager.get(StudyInstanceUID);
    const allDisplaySets = studyMetadata.getDisplaySets();
    let selectedSegmentation;
    let newLabelmapIndex = getActiveLabelMapIndex({ activeViewport });
    allDisplaySets.forEach(displaySet => {
      if (displaySet.labelmapSegments) {
        Object.keys(displaySet.labelmapSegments).forEach(labelmapIndex => {
          if (
            displaySet.labelmapSegments[labelmapIndex].includes(segmentIndex)
          ) {
            newLabelmapIndex = labelmapIndex;
            selectedSegmentation =
              displaySet.hasOverlapping === true
                ? displaySet.originLabelMapIndex
                : labelmapIndex;
          }
        });
      }
    });

    const brushStackState = getBrushStackState({ activeViewport });
    brushStackState.activeLabelmapIndex = newLabelmapIndex;
    if (selectedSegmentation) {
      setSegmentationState(state => ({ ...state, selectedSegmentation }));
    }

    refreshViewports();
    return segmentIndex;
  };

  const centerSegment = ({ segmentNumber }) => {
    setActiveSegment({ segmentIndex: segmentNumber });

    const validIndexList = [];
    getActiveLabelMaps2D({ activeViewport }).forEach((labelMap2D, index) => {
      if (labelMap2D.segmentsOnLabelmap.includes(segmentNumber)) {
        validIndexList.push(index);
      }
    });

    const avg = array => array.reduce((a, b) => a + b) / array.length;
    const average = avg(validIndexList);
    const closest = validIndexList.reduce((prev, curr) => {
      return Math.abs(curr - average) < Math.abs(prev - average) ? curr : prev;
    });

    if (_isCornerstone()) {
      const element = getEnabledElement();
      const toolState = cornerstoneTools.getToolState(element, 'stack');

      if (!toolState) return;

      const imageIds = toolState.data[0].imageIds;
      const imageId = imageIds[closest];
      const frameIndex = imageIds.indexOf(imageId);

      const SOPInstanceUID = cornerstone.metaData.get(
        'SOPInstanceUID',
        imageId
      );
      const StudyInstanceUID = cornerstone.metaData.get(
        'StudyInstanceUID',
        imageId
      );

      DICOMSegTempCrosshairsTool.addCrosshair(element, imageId, segmentNumber);

      onSegmentItemClick({
        StudyInstanceUID,
        SOPInstanceUID,
        frameIndex,
        activeViewportIndex: activeIndex,
      });
    }

    if (_isVTK()) {
      const labelMaps3D = getActiveLabelMaps3D({ activeViewport });
      const currentDisplaySet = getCurrentDisplaySet({ activeViewport });
      const frame = labelMaps3D.labelmaps2D[closest];

      onSegmentItemClick({
        studies,
        StudyInstanceUID: currentDisplaySet.StudyInstanceUID,
        displaySetInstanceUID: currentDisplaySet.displaySetInstanceUID,
        SOPClassUID: activeViewport.sopClassUIDs[0],
        SOPInstanceUID: currentDisplaySet.SOPInstanceUID,
        segmentNumber,
        frameIndex: closest,
        frame,
      });
    }
  };

  // Gets processed metadata that can be used to plug into UI components
  const getConnectedSegmentMetadata = ({ trackingUID }) => {
    const segmentData = _getSegmentMetadata({ trackingUID });
    return segmentationState.segmentationTypeConfig.segmentCardComponents({
      segmentData,
    });
  };

  const onDeleteSegment = ({ segmentIndex }) => {
    if (_isVTK()) {
      showSegmentManipulationError();
      return;
    }

    const labelmap3D = getActiveLabelMaps3D({
      activeViewport,
    });
    const element = getEnabledElement();

    const trackingUID = deleteSegment(labelmap3D, segmentIndex, element);
    segmentationState.segmentationMetadata.deleteSegment({
      trackingUID,
    });

    UINotificationService.show({
      title: 'Segment erfolgreich gelöscht',
      type: 'success',
    });

    refreshSegmentations();
    refreshViewports();
  };

  const onCreateSegment = () => {
    if (_isVTK()) {
      showSegmentManipulationError();
      return;
    }

    const { TrackingUID } = createSegment(getEnabledElement(), 'Lesion');
    segmentationState.segmentationMetadata.createSegment({
      trackingUID: TrackingUID,
    });
    refreshSegmentations();

    setTimeout(() => {
      document.dispatchEvent(
        new CustomEvent('newSegment', {
          detail: {
            trackingUID: TrackingUID,
          },
        })
      );
    }, 50);
  };

  const onMergeSegments = ({ trackingUIDs }) => {
    if (_isVTK()) {
      showSegmentManipulationError();
      return;
    }

    const labelmap3D = getActiveLabelMaps3D({
      activeViewport,
    });
    const element = getEnabledElement();

    const segmentNumbers = trackingUIDs.map(
      trackingUID =>
        segmentationState.segmentList.find(
          seg => seg.trackingUID === trackingUID
        ).segmentIndex + 1
    );

    const targetSegment = Math.min(...segmentNumbers);
    const segmentsToMap = segmentNumbers
      .filter(i => i != targetSegment)
      .sort((a, b) => b - a);

    mergeSegments(labelmap3D, targetSegment, segmentsToMap);

    segmentsToMap.forEach(segmentIndex => {
      const trackingUID = deleteSegment(labelmap3D, segmentIndex - 1, element);
      segmentationState.segmentationMetadata.deleteSegment({
        trackingUID,
      });
    });

    UINotificationService.show({
      title: 'Segment erfolgreich kombiniert',
      type: 'success',
    });

    refreshSegmentations();
    refreshViewports();

    // notify other parts of the application about the new segment
    const targetSegmentTrackingUID = segmentationState.segmentList.find(
      seg => seg.segmentIndex === targetSegment - 1
    ).trackingUID;

    document.dispatchEvent(
      new CustomEvent('newSegment', {
        detail: {
          trackingUID: targetSegmentTrackingUID,
        },
      })
    );
  };

  // This gets the raw segment metadata
  const _getSegmentMetadata = ({ trackingUID }) => {
    if (!segmentationState.segmentationMetadata) return;
    if (!segmentationState.segmentationMetadata.hasSegment({ trackingUID })) {
      UINotificationService.show({
        type: 'error',
        title: 'Data not available',
        message: `Segment metadata not found for segment ${trackingUID}`,
      });
    }

    return segmentationState.segmentationMetadata.getSegment({
      trackingUID,
    });
  };

  /* Optional component methods */
  const getCombinedVoxelVolume = () => {
    // check if we have manual volume measurements
    return segmentationState.segmentationMetadata.segments.reduce(
      (acc, curr) => {
        acc += curr.getVoxelVolume();
        return acc;
      },
      0
    );
  };

  const getBiopsyInfoPromise = () => {
    return apiInterface.getBiopsyInfo({
      studyInstanceUID: activeViewport.StudyInstanceUID,
    });
  };

  const updateConfiguration = newConfiguration => {
    const { configuration } = cornerstoneTools.getModule('segmentation');

    configuration.renderFill = newConfiguration.renderFill;
    configuration.renderOutline = newConfiguration.renderOutline;
    configuration.shouldRenderInactiveLabelmaps =
      newConfiguration.shouldRenderInactiveLabelmaps;
    configuration.fillAlpha = newConfiguration.fillAlpha;
    configuration.outlineAlpha = newConfiguration.outlineAlpha;
    configuration.outlineWidth = newConfiguration.outlineWidth;
    configuration.fillAlphaInactive = newConfiguration.fillAlphaInactive;
    configuration.outlineAlphaInactive = newConfiguration.outlineAlphaInactive;

    const localState = localStorage.loadState();
    localState['segmentationConfig'] = configuration;
    localStorage.saveState(localState);

    onConfigurationChange(newConfiguration);
    refreshViewports();
  };

  /* Helper Methods */

  // Close all segmentation cards
  const _minimzeAllSegmentations = () => {
    setSegmentationState(state => ({
      ...state,
      segmentationMetadata: null,
      selectedSegment: null,
      segmentationTypeConfig: null,
      selectedSegmentation: null,
      selectedSegmentationType: null,
    }));
  };

  const _selectSegmentation = ({ segmentation }) => {
    return new Promise(resolve => {
      if (segmentation) {
        const {
          studyInstanceUID,
          seriesInstanceUID,
          segmentationType,
        } = segmentation;

        const segmentationTypeConfig = segmentationPanelProvider({
          segmentationType,
          segmentationOrigin: segmentation.segmentationOrigin,
        });

        segmentationTypeConfig.initSegmentationExtension();

        const segmentTemplateProvider =
          segmentationTypeConfig.segmentDetailCardCompnents
            .detailTemplateProvider;

        const setSegmentationData = ({
          segmentationMeta,
          username,
          segmentList,
          inferenceData,
          segmentationErrors,
        }) => {
          const segmentationMetadata = new SegmentationMetadata({
            segmentationMetadata: segmentationMeta,
            segmentList,
            segmentTemplateProvider,
            inferenceData,
            segmentationErrors,
          });
          segmentationMetadata.editingInfo.setUser({
            user: username,
          });
          setSegmentationState(state => ({
            ...state,
            segmentList,
            segmentationMetadata,
            segmentationTypeConfig,
          }));
        };

        segmentation.onClick().then(segResponse => {
          MetadataPromise({
            studyInstanceUID,
            seriesInstanceUID,
            segmentType: segmentationType,
          })
            .then(respone => {
              const [
                segmentationMetadataResponse,
                userDataRespone,
                inferenceDataResponse,
                segmentationErrorsResponse,
              ] = respone;
              setSegmentationData({
                segmentationMeta: segmentationMetadataResponse,
                segmentList: segResponse.segmentList,
                username: userDataRespone.username,
                inferenceData: inferenceDataResponse,
                segmentationErrors: segmentationErrorsResponse,
              });
              resolve();
            })
            .catch(e => {
              UINotificationService.show({
                type: e.type,
                title: e.title,
                message: e.message,
              });

              // create an empty segmentationMetadata if e.type is warning
              if (e.type === 'warning') {
                const { segmentList } = getSegmentList({ activeViewport });
                const segmentMetadataMock = createMockSegmentMeta({
                  segmentList,
                  segmentationInfo: segmentation,
                });

                setSegmentationData({
                  segmentationMeta: segmentMetadataMock,
                  username: 'default-user',
                });
                resolve();
              }
            });
        });
      } else {
        _minimzeAllSegmentations();
        resolve();
      }
    });
  };

  const getEnabledElement = () => {
    const enabledElements = cornerstone.getEnabledElements();
    return enabledElements[activeIndex].element;
  };

  const showSegmentManipulationError = () => {
    UINotificationService.show({
      title: 'Segment Maniupulation Error',
      message:
        'The manipulation of segmentations is only on cornerstone viewports allowed. Please leave the VTK Viewport',
      type: 'error',
    });
  };

  const _setActiveSegmentByEvent = event => {
    setActiveSegment({ segmentIndex: event.detail.segmentID });
  };

  const _toggleVisibility = event => {
    if (segmentationState.segmentsHidden.some(seg => !seg)) {
      setVisibleSegments([...segmentationState.segmentsHidden]);
      toggleSegmentationVisibility();
      return;
    }

    if (visibleSegments.length) {
      visibleSegments.forEach((seg, index) => {
        if (seg !== undefined && seg === false) {
          toggleSegmentVisibility({ targetSegmentNumber: index });
        }
      });
    } else {
      toggleSegmentationVisibility();
    }
  };

  const _getFirstSopUIDOfSeries = ({ seriesInstanceUID }) => {
    const study = studies.find(
      study => study.StudyInstanceUID === activeViewport.StudyInstanceUID
    );

    const sopInstanceUID = study.series.find(
      s => s.SeriesInstanceUID === seriesInstanceUID
    ).instances[0].metadata.SOPInstanceUID;

    return sopInstanceUID;
  };

  const _isSegmentationSelected = ({ brushStackState }) => {
    return (
      (brushStackState.activeLabelmapIndex !== undefined) &
      (brushStackState.activeLabelmapIndex !== null)
    );
  };

  const _loadingSpinnerDialog = ({ text }) => {
    UINotificationService.show({
      type: 'info',
      customContent: <LoadingSpinnerNotificationCard text={text} />,
    });
  };

  const _isVTK = () => activeContexts.includes(contexts.VTK);
  const _isCornerstone = () => activeContexts.includes(contexts.CORNERSTONE);

  return {
    /* Top Level state */
    segmentationState,
    setSegmentationState,
    /* General Information */
    activeViewport,
    seriesInstanceUIDProvider,
    studyInstanceUID: activeViewport ? activeViewport.StudyInstanceUID : null,
    isT2: segmentationState.isT2,
    selectedSegmentationType: segmentationState.selectedSegmentationType,
    isSegmentationVisible: isSegmentationVisible(),
    numberOfSegments: segmentationState.segmentList.length,
    /* Segmentation Panel */
    segmentationConfig: segmentationState.segmentationTypeConfig,
    selectSegmentationByType,
    /* Segmentation */
    isSegmentationLoading: () => segmentationState.segmentationIsLoading,
    toggleEditMode,
    toggleSegmentationVisibility,
    getSegmentList: () => {
      return segmentationState.segmentList;
    },
    saveSegmentation,
    onDeleteSegmentation,
    getManualVolumeHandler: () =>
      segmentationState.segmentationMetadata.manualVolumeMeasurementHandler,
    /* Segment */
    setActiveSegment,
    isSegmentActive: ({ segmentIndex }) =>
      segmentIndex === segmentationState.selectedSegment,
    toggleSegmentVisibility,
    centerSegment,
    getConnectedSegmentMetadata,
    onDeleteSegment,
    onCreateSegment,
    getSegmentMetadata: ({ trackingUID }) =>
      _getSegmentMetadata({ trackingUID }),
    /* Segment Merge */
    segmentMergeState,
    setSegmentMergeState,
    onMergeSegments,
    /* Optional Components */
    getCombinedVoxelVolume,
    getEditingInfo: () => {
      return segmentationState.segmentationMetadata.getEditingInfo();
    },
    getInferenceModelInfo: () => {
      return segmentationState.segmentationMetadata.getInferenceModelInfo();
    },
    getBiopsyInfoPromise,
    updateConfiguration,
  };
};

export const SegmentationContextProvider = props => {
  return (
    <SegmentationPanelContext.Provider
      value={useSegmentationPanelProvider(props)}
    >
      {props.children}
    </SegmentationPanelContext.Provider>
  );
};

export const useSegmentationPanel = () => {
  const {
    selectSegmentationByType,
    selectedSegmentationType,
    segmentationConfig,
    isSegmentationLoading,
    getSegmentList,
    saveSegmentation,
    seriesInstanceUIDProvider,
  } = useContext(SegmentationPanelContext);

  return {
    seriesInstanceUIDProvider,
    saveSegmentation,
    isSegmentationLoading,
    segmentationConfig,
    selectSegmentationByType,
    selectedSegmentationType,
    getSegmentList,
  };
};

export const useSegmentationHeader = () => {
  const {
    toggleEditMode,
    toggleSegmentationVisibility,
    isSegmentationVisible,
    numberOfSegments,
    onCreateSegment,
    onDeleteSegmentation,
  } = useContext(SegmentationPanelContext);
  return {
    onDeleteSegmentation,
    onCreateSegment,
    toggleEditMode,
    toggleSegmentationVisibility,
    isSegmentationVisible,
    numberOfSegments,
  };
};

export const useCombinedVolumeCard = () => {
  const { getCombinedVoxelVolume } = useContext(SegmentationPanelContext);
  return { getCombinedVoxelVolume };
};

export const useSegmentationConfig = () => {
  const { segmentationConfig, studyInstanceUID, isT2 } = useContext(
    SegmentationPanelContext
  );
  return {
    segmentationConfig,
    studyInstanceUID,
    isT2,
  };
};

export const useBiopsyInfo = () => {
  const { getBiopsyInfoPromise } = useContext(SegmentationPanelContext);
  return {
    getBiopsyInfoPromise,
  };
};

export const useMetadata = () => {
  const {
    getConnectedSegmentMetadata,
    getSegmentMetadata,
    getInferenceModelInfo,
  } = useContext(SegmentationPanelContext);
  return {
    getConnectedSegmentMetadata,
    getSegmentMetadata,
    getInferenceModelInfo,
  };
};

export const useSegment = () => {
  const {
    toggleSegmentVisibility,
    centerSegment,
    isSegmentActive,
    setActiveSegment,
    onDeleteSegment,
  } = useContext(SegmentationPanelContext);

  return {
    onDeleteSegment,
    setActiveSegment,
    isSegmentActive,
    toggleSegmentVisibility,
    centerSegment,
  };
};

export const usePartialVolumeCalculation = () => {
  const { getManualVolumeHandler } = useContext(SegmentationPanelContext);

  return {
    getManualVolumeHandler,
  };
};

export const useSegmentationSettings = () => {
  const { updateConfiguration } = useContext(SegmentationPanelContext);
  return {
    updateConfiguration,
  };
};
