import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import store from '../../../platform/viewer/src/store';

const commandsModule = ({ servicesManager, commandsManager }) => {
  const { UINotificationService } = servicesManager.services;
  const { setters } = cornerstoneTools.getModule('segmentation');

  const getActiveViewportElement = () => {
    const { viewports } = store.getState();
    const activeVP = viewports.activeViewportIndex;
    return cornerstone.getEnabledElements()[activeVP].element;
  };

  const isEditModeEnabled = () => {
    const { viewports } = store.getState();
    return viewports.editMode;
  };

  const actions = {
    toggleSegmentationVisibility: () => {
      const toggleVisibilityEvent = new CustomEvent('toggleVisibility');
      document.dispatchEvent(toggleVisibilityEvent);
    },
    setActiveSegment: ({ segment }) => {
      const setSegmentActiveEvent = new CustomEvent('setSegmentActive', {
        detail: { segmentID: segment },
      });
      document.dispatchEvent(setSegmentActiveEvent);
    },
    redoLabelmapOperation: () => {
      const element = getActiveViewportElement();
      setters.redo(element);
    },
    undoLabelmapOperation: () => {
      const element = getActiveViewportElement();
      setters.undo(element);
    },
    setSegmentationToolActive: ({ toolName }) => {
      if (!isEditModeEnabled()) {
        UINotificationService.show({
          title: 'Edit mode is inactive',
          message:
            'Please make sure to enter the edit mode before you activate any segmentation tools!',
          type: 'error',
        });
        return;
      }
      if (!toolName) {
        console.warn('No toolname provided to setToolActive command');
      }
      cornerstoneTools.setToolActive(toolName, { mouseButtonMask: 1 });
      const activatedToolEvent = new CustomEvent('activatedTool', {
        detail: { toolName: toolName },
      });
      document.dispatchEvent(activatedToolEvent);
    },
  };
  const definitions = {
    redoLabelmapOperation: {
      commandFn: actions.redoLabelmapOperation,
      storeContexts: ['viewports'],
      options: {},
    },
    undoLabelmapOperation: {
      commandFn: actions.undoLabelmapOperation,
      storeContexts: ['viewports'],
      options: {},
    },
    toggleSegmentationVisibility: {
      commandFn: actions.toggleSegmentationVisibility,
      storeContexts: ['viewports'],
      options: {},
    },
    setSegmentationToolActive: {
      commandFn: actions.setSegmentationToolActive,
      storeContexts: ['viewports'],
      options: {},
    },
    setFirstSegmentActive: {
      commandFn: actions.setActiveSegment,
      storeContexts: ['viewports'],
      options: { segment: 1 },
    },
    setSecondSegmentActive: {
      commandFn: actions.setActiveSegment,
      storeContexts: ['viewports'],
      options: { segment: 2 },
    },
    setThirdSegmentActive: {
      commandFn: actions.setActiveSegment,
      storeContexts: ['viewports'],
      options: { segment: 3 },
    },
    setFourthSegmentActive: {
      commandFn: actions.setActiveSegment,
      storeContexts: ['viewports'],
      options: { segment: 4 },
    },
    setFifthSegmentActive: {
      commandFn: actions.setActiveSegment,
      storeContexts: ['viewports'],
      options: { segment: 5 },
    },
    setSixthSegmentActive: {
      commandFn: actions.setActiveSegment,
      storeContexts: ['viewports'],
      options: { segment: 6 },
    },
    setSeventhSegmentActive: {
      commandFn: actions.setActiveSegment,
      storeContexts: ['viewports'],
      options: { segment: 7 },
    },
    setEigthSegmentActive: {
      commandFn: actions.setActiveSegment,
      storeContexts: ['viewports'],
      options: { segment: 8 },
    },
    setNinthSegmentActive: {
      commandFn: actions.setActiveSegment,
      storeContexts: ['viewports'],
      options: { segment: 9 },
    },
    setBrush: {
      commandFn: actions.setSegmentationToolActive,
      storeContexts: ['viewports'],
      options: { toolName: 'Brush' },
    },
    setBrushEraser: {
      commandFn: actions.setSegmentationToolActive,
      storeContexts: ['viewports'],
      options: { toolName: 'BrushEraser' },
    },
    setFreehandScissors: {
      commandFn: actions.setSegmentationToolActive,
      storeContexts: ['viewports'],
      options: { toolName: 'FreehandScissors' },
    },
    setFreehandScissorsEraser: {
      commandFn: actions.setSegmentationToolActive,
      storeContexts: ['viewports'],
      options: { toolName: 'FreehandScissorsEraser' },
    },
  };

  return {
    actions,
    definitions,
    defaultContext: ['VIEWER'],
  };
};

export default commandsModule;
