import Protocol from '../classes/Protocol';
import ViewportStructure from '../classes/ViewportStructure';
import Viewport from '../classes/Viewport';
import Stage from '../classes/Stage';
import {
  ImageMatchingRule,
  ProtocolMatchingRule,
  SeriesMatchingRule,
} from '../classes';

function getDefaultProtocol() {
  var proto = new Protocol('MR_TwoByTwo');
  proto.id = 'MR_TwoByTwo';
  proto.locked = true;
  // Use http://localhost:3000/viewer/1.2.840.113619.2.5.1762583153.215519.978957063.78

  var StudyInstanceUID = new ProtocolMatchingRule(
    'StudyInstanceUID',
    {
      equals: {
        value: '1.3.12.2.1107.5.2.19.145132.2021040615053411235248242.0.0.0',
      },
    },
    true
  );

  proto.addProtocolMatchingRule(StudyInstanceUID);

  var oneByTwo = new ViewportStructure('grid', {
    Rows: 1,
    Columns: 2,
  });

  // Stage 1
  var left = new Viewport();
  var right = new Viewport();

  var firstSeries = new SeriesMatchingRule('SeriesNumber', {
    equals: {
      value: 1,
    },
  });

  var secondSeries = new SeriesMatchingRule('SeriesNumber', {
    equals: {
      value: 2,
    },
  });

  var thirdImage = new ImageMatchingRule('InstanceNumber', {
    equals: {
      value: 3,
    },
  });

  left.seriesMatchingRules.push(firstSeries);
  left.imageMatchingRules.push(thirdImage);

  right.seriesMatchingRules.push(secondSeries);
  right.imageMatchingRules.push(thirdImage);

  var first = new Stage(oneByTwo, 'oneByTwo');
  first.viewports.push(left);
  first.viewports.push(right);

  proto.stages.push(first);

  // Stage 2
  var twoByOne = new ViewportStructure('grid', {
    Rows: 2,
    Columns: 1,
  });
  var left2 = new Viewport();
  var right2 = new Viewport();

  var fourthSeries = new SeriesMatchingRule('SeriesNumber', {
    equals: {
      value: 4,
    },
  });

  var fifthSeries = new SeriesMatchingRule('SeriesNumber', {
    equals: {
      value: 5,
    },
  });

  left2.seriesMatchingRules.push(fourthSeries);
  left2.imageMatchingRules.push(thirdImage);
  right2.seriesMatchingRules.push(fifthSeries);
  right2.imageMatchingRules.push(thirdImage);

  var second = new Stage(twoByOne, 'twoByOne');
  second.viewports.push(left2);
  second.viewports.push(right2);

  proto.stages.push(second);

  return proto;
  // const protocol = new Protocol('Default');
  // protocol.id = 'defaultProtocol';
  // protocol.locked = true;

  // const oneByOne = new ViewportStructure('grid', {
  //   Rows: 2,
  //   Columns: 2,
  // });

  // // initialize 4 viewports
  // const topLeft = new Viewport();
  // const topRight = new Viewport();
  // const bottomLeft = new Viewport();
  // const bottomRight = new Viewport();

  // // specify initializer rules
  // var firstSeries = new SeriesMatchingRule('SeriesNumber', {
  //   equals: {
  //     value: 1,
  //   },
  // });

  // var secondSeries = new SeriesMatchingRule('SeriesNumber', {
  //   equals: {
  //     value: 2,
  //   },
  // });

  // var thirdSeries = new SeriesMatchingRule('SeriesNumber', {
  //   equals: {
  //     value: 3,
  //   },
  // });

  // var fourthSeries = new SeriesMatchingRule('SeriesNumber', {
  //   equals: {
  //     value: 4,
  //   },
  // });

  // topLeft.seriesMatchingRules.push(firstSeries);
  // topRight.seriesMatchingRules.push(secondSeries);
  // bottomLeft.seriesMatchingRules.push(thirdSeries);
  // bottomRight.seriesMatchingRules.push(fourthSeries);

  // const first = new Stage(oneByOne, 'oneByOne');

  // first.viewports.push(topLeft);
  // first.viewports.push(topRight);
  // first.viewports.push(bottomLeft);
  // first.viewports.push(bottomRight);

  // protocol.stages.push(first);

  // return protocol;
}

const defaultProtocol = getDefaultProtocol();

export default defaultProtocol;
