import React, { useState, useEffect } from 'react';

import { TextArea } from '@ohif/ui';

import './Selector.styl';

const Choice = ({ title, selected, onSelect }) => {
  return (
    <div className="choice">
      <div className="checkbox-wrapper">
        <input
          type="checkbox"
          id="myCheckbox"
          checked={selected}
          onChange={onSelect}
        />
      </div>
      <label htmlFor="myCheckbox">{title}</label>
    </div>
  );
};

const CustomChoice = ({ t, onAddCustomChoice }) => {
  const [customChoice, setCustomChoice] = useState('');

  const handleInputChange = text => {
    setCustomChoice(text);
  };

  const handleAddCustomChoice = () => {
    onAddCustomChoice(customChoice);
    setCustomChoice('');
    const textarea = document.getElementById('custom-choice-textarea');
    if (textarea) {
      textarea.value = '';
      textarea.innerText = '';
      textarea.innerHTML = '';
    }
  };

  const MemoizedTextarea = React.memo(({ value }) => {
    return (
      <TextArea
        key="custom-choise"
        value={value}
        emptyValue={t('Add an option')}
        onBlur={handleInputChange}
        id="custom-choice-textarea"
        rows={1}
      ></TextArea>
    );
  });

  return (
    <div className="custom-choice">
      <MemoizedTextarea value={customChoice} />
      <div className="button-wrapper">
        <button onClick={() => handleAddCustomChoice()} />
      </div>
    </div>
  );
};

export const MultiChoiceSelector = ({
  t,
  choices,
  selectedChoices,
  onSelectionChange,
  allowCustomChoice,
}) => {
  const [selectorState, setSelectorState] = useState({
    choices: choices,
    selectedChoices: selectedChoices,
  });

  useEffect(
    () =>
      onSelectionChange({
        possibleSegmentationErrors: selectorState.choices,
        selectedErrors: selectorState.selectedChoices,
      }),
    [selectorState]
  );

  return (
    <div className="multi-choice-selector">
      {selectorState.choices.map(ch => (
        <Choice
          key={ch}
          title={ch}
          selected={selectorState.selectedChoices.includes(ch)}
          onSelect={() => {
            if (selectorState.selectedChoices.includes(ch))
              setSelectorState({
                ...selectorState,
                selectedChoices: selectorState.selectedChoices.filter(
                  selectedChoice => selectedChoice !== ch
                ),
              });
            else
              setSelectorState({
                ...selectorState,
                selectedChoices: [...selectorState.selectedChoices, ch],
              });
          }}
        />
      ))}
      {allowCustomChoice && (
        <CustomChoice
          t={t}
          onAddCustomChoice={newChoice => {
            setSelectorState({
              choices: [...selectorState.choices, newChoice],
              selectedChoices: [...selectorState.selectedChoices, newChoice],
            });
          }}
        />
      )}
    </div>
  );
};
