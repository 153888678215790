import React from 'react';

import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styling = StyleSheet.create({
  backgroundColor: '#EDEDED',
  fontSize: '9px',
  padding: '2px',
  marginBottom: '2px',
});

const PanelSubHeading = ({ heading, style }) => {
  let textStyling = {};

  if (style && style.Text) {
    textStyling = style.Text;
  }

  return (
    <View style={{ ...styling, ...style }}>
      <Text style={textStyling}>{heading}</Text>
    </View>
  );
};

export { PanelSubHeading };
