import React from 'react';
import ReportPanel from './panel/ReportPanel';

import { redux } from '@ohif/core';
const { setExtensionAccessible } = redux.actions;

export default {
  /**
   * Only required property. Should be a unique value across all extensions.
   */
  id: 'report-tool',
  get version() {
    return window.version;
  },

  preRegistration() {},

  getPanelModule() {
    const ExtendedConnectedReportTool = props => {
      return <ReportPanel {...props} />;
    };

    return {
      menuOptions: [
        {
          icon: 'report',
          label: 'Patientendaten',
          target: 'report-tool',
        },
      ],
      components: [
        {
          id: 'report-tool',
          component: ExtendedConnectedReportTool,
        },
      ],
      defaultContext: ['VIEWER'],
    };
  },
};
