import { Icon } from '@ohif/ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePartialVolumeCalculation } from '../../../../context/SegmentationPanelContext';
import { SizeSelector } from '../SegmentDetailCard/components';

import './PartialVolumeFormCard.styl';

const EMPTY_VOLUME_MEASURES = {
  total: {
    c: '',
    m: '',
    a: '',
  },
  transitional: {
    c: '',
    m: '',
    a: '',
  },
};

const SELECTOR_TABLE = {
  total: {
    title: 'Combined Volume',
    setter: [
      { label: 'craniocaudal', key: 'c' },
      { label: 'mediolateral', key: 'm' },
      { label: 'anteriopost.', key: 'a' },
    ],
  },
  transitional: {
    title: 'Transitional zone volume',
    setter: [
      { label: 'craniocaudal', key: 'c' },
      { label: 'mediolateral', key: 'm' },
      { label: 'anteriopost.', key: 'a' },
    ],
  },
};

export const PartialVolumeFormCard = () => {
  const { UINotificationService } = window.ohif.app.servicesManager.services;

  const [t] = useTranslation('SegmentationPanel');

  const { getManualVolumeHandler } = usePartialVolumeCalculation();
  const manualVolumeHandler = getManualVolumeHandler();

  const [hasMeasures, setHasMeasures] = useState(
    manualVolumeHandler.hasManualVolumeMeasurements()
  );
  const [partialVolumes, setPartialVolumes] = useState(
    manualVolumeHandler.getManualVolumeMeasurements() || EMPTY_VOLUME_MEASURES
  );

  const resetMeasures = () => {
    setPartialVolumes(() => ({ ...EMPTY_VOLUME_MEASURES }));
  };

  const handleOnBlur = ({ index, key, value }) => {
    setPartialVolumes(state => {
      const newState = { ...state };
      newState[index][key] = value;
      return newState;
    });
  };

  return manualVolumeHandler.areManualVolumeMeasurementsAllowed() ? (
    <div className="segment-panel-box">
      <div className="seg-panel-card-header">
        <span className="heading small">{t('Enter volume manually')}</span>
        <Icon
          className="icon"
          name={hasMeasures ? 'minus' : 'plus'}
          onClick={() => {
            if (hasMeasures) {
              resetMeasures();
              manualVolumeHandler.setManualVolumeMeasurements({
                volumeMeasures: null,
              });
              UINotificationService.show({
                type: 'success',
                message: 'Manuelle Volumenmessung entfernt',
              });
            }

            setHasMeasures(!hasMeasures);
          }}
        />
      </div>
      {hasMeasures && (
        <div className="seg-panel-card-body">
          <div className="volume-row">
            <div className="heading smaller">
              {t(SELECTOR_TABLE.total.title)}
            </div>
            <div className="sub-volume-fields">
              {SELECTOR_TABLE.total.setter.map(({ label, key }) => {
                return (
                  <SizeSelector
                    key={`total-${label}`}
                    title={label}
                    value={partialVolumes.total[key]}
                    setValue={value => {
                      handleOnBlur({ index: 'total', key, value });
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div className="volume-row">
            <div className="heading smaller">
              {t('Transitional zone volume')}
            </div>
            <div className="sub-volume-fields">
              {SELECTOR_TABLE.transitional.setter.map(({ label, key }) => {
                return (
                  <SizeSelector
                    key={`transitional-${label}`}
                    title={label}
                    value={partialVolumes.transitional[key]}
                    setValue={value => {
                      handleOnBlur({ index: 'transitional', key, value });
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div className="button-area">
            <button onClick={resetMeasures}>{t('Reset')}</button>
            <button
              onClick={() => {
                const values = [
                  ...Object.values(partialVolumes.total),
                  ...Object.values(partialVolumes.transitional),
                ];
                const isNumericCheck = values.every(
                  value => /^\d+$/.test(value) && value !== ''
                );

                if (!isNumericCheck) {
                  UINotificationService.show({
                    type: 'warning',
                    title: 'Werte unvollständig',
                    message:
                      'Einige Volumen-Werte fehlen oder sind unzulässig, bitte überprüfen Sie Ihre Eingabe.',
                  });
                  return;
                }

                const relativeSizeCheck = Object.values(
                  partialVolumes.total
                ).every(
                  (value, index) =>
                    Number(value) >
                    Number(Object.values(partialVolumes.transitional)[index])
                );

                if (!relativeSizeCheck) {
                  UINotificationService.show({
                    type: 'warning',
                    title: 'Werte uneindeutig',
                    message:
                      'Einige Volumen-Werte ergeben keinen Sinn. Bitte überprüfen Sie, dass die Werte für das kombinierte Volumen größer sind als die Werte für das Transitional-Volumen.',
                  });
                  return;
                }

                manualVolumeHandler.setManualVolumeMeasurements({
                  volumeMeasures: partialVolumes,
                });

                UINotificationService.show({
                  type: 'success',
                  message: 'Manuelle Volumenmessung gespeichert',
                });
              }}
            >
              {t('Calculate volume')}
            </button>
          </div>
        </div>
      )}
    </div>
  ) : null;
};
