import { TextArea } from '@ohif/ui';
import classnames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';

import './Selector.styl';

const CLASS_NAMES = ['empty', 'invalid', 'valid'];

export const SizeSelector = ({
  title,
  value,
  defaultValue = '',
  setValue,
  unit = 'mm',
}) => {
  const key = useMemo(() => v4(), [value]);

  // 0 - input is empty, 1 - invalid input, 2 - valid input
  const [validInput, setValidInput] = useState(() => {
    return value === '' ? 0 : /^\d+$/.test(value);
  });

  useEffect(() => {
    if (value === '') {
      setValidInput(0);
      return;
    }

    const isNumeric = /^\d+$/.test(value);
    if (!isNumeric || value === '0') {
      setValidInput(1);
    } else {
      setValidInput(2);
    }
  }, [value]);

  useEffect(() => {
    const element = document.getElementById(key);
    CLASS_NAMES.forEach(item => element.classList.remove(item));
    element.classList.add(CLASS_NAMES[validInput]);
  }, [validInput, key]);

  return (
    <div className="segment-panel-box selector" style={{ padding: '5px 10px' }}>
      <div className="selector-left">
        <span className="title">{title}</span>
        <div className="input-area">
          <TextArea
            id={key}
            key={key}
            value={String(value) || defaultValue}
            onBlur={value => setValue(value)}
            rows={1}
            className={classnames('text-area', {
              empty: validInput == 0,
              invalid: validInput == 1,
              valid: validInput == 2,
            })}
          />
          <span className="unit">{unit}</span>
        </div>
      </div>
    </div>
  );
};
