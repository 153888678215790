import React from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { CONFIDENCE_LEVEL } from '../../../../../context/constants/ConfidenceLevel';
import { useEditingInfo } from '../../../../../context/hooks/useEditingInformation';

export const EvaluationButtonGroup = () => {
  const [t] = useTranslation('SegmentationPanel');

  const { getEditingInfo, setConfidenceLevel } = useEditingInfo();
  const editingInfo = getEditingInfo();

  return (
    <div className="button-group">
      <button
        className={classnames({
          active: editingInfo.getConfidenceLevel() == CONFIDENCE_LEVEL.PERFECT,
        })}
        type="button"
        onClick={() =>
          setConfidenceLevel({
            confidenceLevelString: CONFIDENCE_LEVEL.PERFECT,
          })
        }
      >
        {t('Perfect')}
      </button>
      <button
        className={classnames({
          active:
            editingInfo.getConfidenceLevel() == CONFIDENCE_LEVEL.PROCESSED,
        })}
        type="button"
        onClick={() =>
          setConfidenceLevel({
            confidenceLevelString: CONFIDENCE_LEVEL.PROCESSED,
          })
        }
      >
        {t('Minor artifacts')}
      </button>
      <button
        className={classnames({
          active: editingInfo.getConfidenceLevel() == CONFIDENCE_LEVEL.UNSURE,
        })}
        type="button"
        onClick={() =>
          setConfidenceLevel({
            confidenceLevelString: CONFIDENCE_LEVEL.UNSURE,
          })
        }
      >
        {t('Need review')}
      </button>
    </div>
  );
};
