import React from 'react';

import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useResources } from '../ResourceContextProvider';
import { OpenTable, PanelMainHeading, TextTable } from './general';

const styling = StyleSheet.create({
  flexGrow: 1,
  MainContainer: {
    flexGrow: 1,
    justifyContent: 'space-between',
    TextArea: {
      fontSize: '9px',
      padding: '0 7px',
    },
    Table: {
      marginTop: '5px',
    },
  },
  Heading: {
    fontWeight: 700,
    marginBottom: '2px',
  },
  Text: {
    fontSize: '8px',
  },
});

const optionalContent = (content, altText) => {
  if (content === undefined || content === null || content === '') {
    return altText;
  }
  if (Array.isArray(content) && content.length === 0) {
    return altText;
  }

  return content;
};

const getIPSSText = ipssScore => {
  if (!ipssScore) return '-';

  if (ipssScore >= 20) {
    return ipssScore + ' (schwere Symptomatik)';
  } else if (ipssScore >= 8) {
    return ipssScore + ' (mittlere Symptomatik)';
  } else {
    return ipssScore + ' (milde Symptomatik)';
  }
};

const getIIEFText = iiefScore => {
  if (!iiefScore) return '-';

  if (iiefScore >= 26) {
    return iiefScore + ' (keine Symptomatik)';
  } else if (iiefScore >= 22) {
    return iiefScore + ' (leichte Symptomatik)';
  } else if (iiefScore >= 17) {
    return iiefScore + ' (leicht-mittlere Symptomatik)';
  } else if (iiefScore >= 11) {
    return iiefScore + ' (mittlere Symptomatik)';
  } else {
    return iiefScore + ' (schwere Symptomatik)';
  }
};

/**
 * Build text table from the given data such that we can display it an fancy way
 */
const buildTextTable = ({ urologicInfos, indication }) => {
  const table = {
    urolgics: [
      {
        key: 'Tastuntersuchung:',
        value: optionalContent(
          urologicInfos.palpation,
          'Keine Behandlung bekannt'
        ),
      },
      {
        key: 'Ultraschalluntersuchung:',
        value: optionalContent(
          urologicInfos.ultrasound,
          'Keine Behandlung bekannt'
        ),
      },
    ],
    family: [
      {
        key: 'Erkrankte Familienmitglieder:',
        value: optionalContent(
          indication.anamnesis.familyRiskRelatives,
          'keine'
        ),
      },
      {
        key: 'Erkrankungen:',
        value: optionalContent(
          indication.anamnesis.familyRiskDiseases,
          'keine'
        ),
      },
    ],
    table: [
      { key: 'IPSS', value: getIPSSText(urologicInfos.ipssScore) },

      { key: 'IIEF', value: getIIEFText(urologicInfos.iiefScore) },
    ],
  };

  return table;
};

const Indication = () => {
  const {
    reportData: {
      report: { urologicInfos, indication },
    },
  } = useResources();
  const textTable = buildTextTable({ urologicInfos, indication });

  return (
    <View style={styling}>
      <PanelMainHeading heading={'1. Indikation'} />
      <View style={styling.MainContainer}>
        <View style={styling.MainContainer.TextArea}>
          <Text style={styling.Heading}>Urologische Untersuchungen</Text>
          <TextTable content={textTable.urolgics} style={{ fontSize: '8px' }} />

          <Text style={{ ...styling.Heading, marginTop: '5px' }}>
            Familiäre Vorbelastung
          </Text>
          <TextTable content={textTable.family} style={{ fontSize: '8px' }} />

          <Text style={{ ...styling.Heading, marginTop: '5px' }}>
            Symptome beim Wasserlassen
          </Text>
          <Text style={styling.Text}>
            {urologicInfos.urinationSymptoms.join(', ')}
          </Text>

          <Text style={{ ...styling.Heading, marginTop: '5px' }}>
            Lebensqualitätsindex
          </Text>
          <Text style={styling.Text}>
            {optionalContent(
              urologicInfos.urinationSymptomsSatisfactionLevel,
              '-'
            )}
          </Text>
        </View>
        <View style={styling.MainContainer.Table}>
          <OpenTable content={textTable.table} />
        </View>
      </View>
    </View>
  );
};

export { Indication };
