import React, { createContext, useContext } from 'react';
import { AltaConfigProvider } from './AltaConfig';
import { AnnotationConfigProvider } from './AnnotationConfig';
import { ZoneConfigProvider } from './ZoneConfig';

const VersionContext = createContext(null);

const ViewerConfigProvider = ({ children, configTarget }) => {
  const buildConfig = () => {
    if (!['student', 'alta', 'zones'].includes(configTarget)) {
      throw new Error('Invalid configuration Target: ', configTarget);
    }

    switch (configTarget) {
      case 'zones':
        return ZoneConfigProvider();

      case 'student':
        return AnnotationConfigProvider();

      case 'alta':
        return AltaConfigProvider();

      default:
        break;
    }
  };

  const viewerConfig = buildConfig();

  return (
    <VersionContext.Provider value={{ viewerConfig }}>
      {children}
    </VersionContext.Provider>
  );
};

export const useViewerConfig = () => {
  const { viewerConfig } = useContext(VersionContext);

  return { viewerConfig };
};

export default ViewerConfigProvider;
