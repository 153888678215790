import React, { useState } from 'react';
import { Icon } from '@ohif/ui';
import './DropDownPanel.styl';

export const DropDownPanel = ({ title, children, isOpen = false }) => {
  const [expanded, setExpanded] = useState(isOpen);

  return (
    <div className="drop-down-panel">
      <div className="ddp-header">
        <div className="ddp-title heading">{title}</div>
        <Icon
          className="icon"
          name={expanded ? 'arrow-up' : 'arrow-down'}
          onClick={() => setExpanded(!expanded)}
        />
      </div>
      {expanded && children}
    </div>
  );
};
