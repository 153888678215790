const getTransAndPeripheralZone = ({ segmentation }) => {
  const transZone = segmentation.segments.find(
    seg => seg.generalInfo.furtherInfo.zone === 'transitional'
  );
  const peripheralZone = segmentation.segments.find(
    seg => seg.generalInfo.furtherInfo.zone === 'peripheral'
  );

  return {
    transitional: transZone,
    peripheral: peripheralZone,
  };
};

export const calculateProstateVolumeInML = ({ segmentation }) => {
  // check whether we have manual measurements
  const hasManualMeasurements = segmentation.segments.some(
    seg => seg.generalInfo.furtherInfo.manualMeasurements
  );

  const { transitional, peripheral } = getTransAndPeripheralZone({
    segmentation,
  });

  let transVolume, periVolume;

  if (hasManualMeasurements) {
    const transMeasures = transitional.generalInfo.manualVolumeMeasures;
    // Note: The peripheral zone stores the measures of the whole prostate
    const combinedMeasures = peripheral.generalInfo.manualVolumeMeasures;

    transVolume = (1 / 2) * transMeasures.c * transMeasures.a * transMeasures.m;
    periVolume =
      (1 / 2) * combinedMeasures.c * combinedMeasures.a * combinedMeasures.m -
      transVolume;
  } else {
    transVolume = transitional.generalInfo.voxelVolume;
    periVolume = peripheral.generalInfo.voxelVolume;
  }

  return {
    total: transVolume + periVolume,
    peripheral: periVolume,
    transitional: transVolume,
  };
};

export const calculateCombinedPirads = (piradsT2W, piradsADC, piradsDCE) => {
  if (piradsADC === piradsT2W) return piradsT2W;
  if (Math.abs(piradsADC - piradsT2W) % 2 === 0)
    return (piradsADC + piradsT2W) / 2;

  return Math.floor((piradsADC + piradsT2W + piradsDCE) / 2);
};

export const calculateBladderVolume = ({ bladder }) => {
  const { c, m, a } = bladder.detailInfo.measurements;
  return (((1 / 2) * c * m * a) / 1000).toFixed(1);
};
