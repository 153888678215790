import React from 'react';
import { useTranslation } from 'react-i18next';

import { InformationPanel } from '@ohif/ui';

import { useReportData } from '../context/reportContext';
import * as tb from './utils'; // textblocks

const Anamnesis = (t, anamnesis) => {
  return (
    <>
      <div className="body">
        {tb.infoPanelTextBody(
          anamnesis.familyRiskRelatives.length
            ? anamnesis.familyRiskRelatives
            : t('No known sick family members'),
          'row'
        )}
      </div>
      <div className="body" style={{ marginTop: '10px' }}>
        {tb.infoPanelTextBody(
          anamnesis.familyRiskDiseases.length
            ? anamnesis.familyRiskDiseases
            : t('No known family diseases'),
          'row'
        )}
      </div>
    </>
  );
};

const Therapies = (t, therapies) => {
  const Therapy = (therapy, title) => {
    const therapies = therapy.therapies.length
      ? therapy.therapies
      : t('No therapies found');

    return (
      <div className="therapy" key={`therapy-${title}`}>
        <div className="therapy-title">{title}</div>
        <div className="row">
          <div className="title">{`${t('Therapies')}:`}</div>
          <div className="body">{tb.infoPanelTextBody(therapies, 'row')}</div>
        </div>
        <div className="row">
          <div className="title">{`${t('Time frame')}:`}</div>
          <div className="body">
            {tb.infoPanelTextBody(therapy.therapyTime)}
          </div>
        </div>
        {therapy.therapyDuration && (
          <div className="row">
            <div className="title">{`${t('Duration')}:`}</div>
            <div className="body">
              {tb.infoPanelTextBody(therapy.therapyDuration)}
            </div>
          </div>
        )}
        {therapy.medications && (
          <>
            <div className="row">
              <div className="title">{`${t('Medication')}:`}</div>
              <div className="body">
                {tb.infoPanelTextBody(therapy.medications, 'row')}
              </div>
            </div>
            <div className="row">
              <div className="title">{`${t('Time frame')}:`}</div>
              <div className="body">
                {tb.infoPanelTextBody(therapy.medicationTime)}
              </div>
            </div>
            {therapy.medicationDuration && (
              <div className="row">
                <div className="title">{`${t('Duration')}:`}</div>
                <div className="body">
                  {tb.infoPanelTextBody(therapy.medicationDuration)}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const hasTherapyInfo =
    therapies.prostateEnlargement ||
    therapies.prostateInflammation ||
    therapies.prostateCancer;

  return (
    <>
      {hasTherapyInfo ? (
        <>
          {therapies.prostateEnlargement &&
            Therapy(therapies.prostateEnlargement, t('Prostate Enlargement'))}
          {therapies.prostateInflammation &&
            Therapy(therapies.prostateInflammation, t('Prostate Inflammation'))}
          {therapies.prostateCancer &&
            Therapy(therapies.prostateCancer, t('Prostate Cancer'))}
        </>
      ) : (
        <div style={{ fontWeight: '700', fontSize: '18px' }}>
          {t('No therapy information found')}
        </div>
      )}
    </>
  );
};

export const IndicationArea = () => {
  const { t } = useTranslation('ReportPanel');
  const { report } = useReportData();

  return (
    <div className="panel-area" key={'indication-area'}>
      <div className="card-heading">{t('Indication')}</div>
      <div className="card-table">
        <div className="card-row">
          <InformationPanel
            title={tb.infoPanelHeader(t('Known diagnoses'))}
            body={tb.infoPanelTextBody(
              report.indication.knownDiagnoses.length
                ? report.indication.knownDiagnoses
                : t('No known diagnoses')
            )}
          />
        </div>
        <div className="card-row">
          <InformationPanel
            title={tb.infoPanelHeader(t('Prostate treatments'))}
            body={Therapies(t, report.therapies)}
          />
        </div>
        <div className="card-row">
          <InformationPanel
            title={tb.infoPanelHeader(t('Family medical history'))}
            body={Anamnesis(t, report.indication.anamnesis)}
          />
        </div>
      </div>
    </div>
  );
};
