import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  useMetadata,
  useSegmentationConfig,
} from '../../../../context/SegmentationPanelContext';

import './SegmentDetailCard.styl';

const SegmentDetailCardRow = ({
  segmentMeta,
  metadata,
  row,
  rowIndex,
  activeSelectorRow,
  setActiveSelectorRow,
}) => {
  const [activeSelectorIndex, setActiveSelectorIndex] = useState(0);

  return (
    <div className="segment-detail-card-row">
      {row.rowTitle && <div className="heading small">{row.rowTitle}</div>}

      <div className="segment-detail-card-row-body">
        {row.selectors.map((detailComp, index) => {
          const selectedOption = metadata.getDetailInfo({
            option: detailComp.label,
          });

          return detailComp.selector({
            ...detailComp,
            key: `${segmentMeta.trackingUID}-${detailComp.title}`,
            active:
              activeSelectorIndex === index && rowIndex === activeSelectorRow,
            label: `${segmentMeta.trackingUID}-${detailComp.title}`,
            selectedOption,
            segmentMeta,
            onSelect: ({ value }) => {
              metadata.setDetailInfo({ option: detailComp.title, value });
              activeSelectorIndex === row.selectors.length - 1
                ? setActiveSelectorRow(rowIndex + 1)
                : setActiveSelectorIndex(index + 1);
            },
          });
        })}
      </div>
    </div>
  );
};

export const SegmentDetailCard = ({ segmentMeta }) => {
  const [t] = useTranslation('SegmentationPanel');

  const [activeSelectorRow, setActiveSelectorRow] = useState(0);

  const { segmentationConfig } = useSegmentationConfig();
  const { segmentDetailCardCompnents } = segmentationConfig;
  const { getSegmentMetadata } = useMetadata();

  const metadata = getSegmentMetadata({ trackingUID: segmentMeta.trackingUID });

  return (
    <div
      className="segment-detail-card"
      key={`segment-detail-card-${segmentMeta.trackingUID}`}
    >
      <div className="heading">{t(segmentDetailCardCompnents.title)}</div>
      {metadata.segmentCardTemplate.map((row, index) => (
        <SegmentDetailCardRow
          key={`${segmentDetailCardCompnents.title}-row-${index}`}
          segmentMeta={segmentMeta}
          metadata={metadata}
          row={row}
          rowIndex={index}
          activeSelectorRow={activeSelectorRow}
          setActiveSelectorRow={setActiveSelectorRow}
        />
      ))}
    </div>
  );
};
