import React from 'react';

import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styling = StyleSheet.create({
  fontSize: '9px',
  justifyContent: 'space-between',
  Row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    LeftCol: {
      flex: 1,
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
});

const TableRow = ({ key, value }) => {
  return (
    <View key={key} style={styling.Row}>
      <Text>{key}</Text>
      <View style={styling.Row.LeftCol}>
        {Array.isArray(value) ? (
          value.map(item => <Text key={item}>{item}</Text>)
        ) : (
          <Text>{value}</Text>
        )}
      </View>
    </View>
  );
};

const TextTable = ({ content, style }) => {
  return (
    <View style={{ ...styling, ...style }}>
      {content.map(item => {
        return TableRow({ key: item.key, value: item.value });
      })}
    </View>
  );
};

export { TextTable };
