const getChunkSize = files => {
  let chunkSize = 0;
  files.forEach(file => {
    chunkSize += file.size;
  });
  return chunkSize / 1024 ** 3;
};

const _checkFileSize = files => {
  const chunkSize = getChunkSize(files);
  if (chunkSize > 1.0) {
    throw {
      name: 'FileSizeError',
      message: `Please keep the maximum file upload size to 1GB (${Math.round(
        chunkSize * 100
      ) / 100}GB)`,
      toString: function() {
        return this.name + ': ' + this.message;
      },
    };
  }
};

const _handleProgressUpdate = (progress, numFiles, setUploadProgress) => {
  progress += 1;
  if (progress % Math.round(numFiles / 100) == 0 || progress == numFiles) {
    setUploadProgress((progress / numFiles) * 100);
  }

  return progress;
};

const sendFiles = async ({ files, setUploadProgress, setOnUpload }) => {
  const { apiInterface } = window.ohif.app;

  _checkFileSize(files);
  let progress = 0;
  let successfullUpload = true;
  let serverError = false;

  setOnUpload(true);

  for (var fileIndex = 0; fileIndex < files.length; fileIndex++) {
    if (serverError) {
      return successfullUpload;
    }

    await apiInterface
      .bulkUpload({ data: files[fileIndex] })
      .then(() => {
        progress = _handleProgressUpdate(
          progress,
          files.length,
          setUploadProgress
        );
      })
      .catch(e => {
        successfullUpload = false;
      });
  }

  return successfullUpload;
};

export { sendFiles };
