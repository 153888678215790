import cloneDeep from 'lodash.clonedeep';

const defaultState = {
  studyData: {},
  activeSegmentation: {},
  sequenceSelection: {},
  extensionsReady: {},
};

const servers = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_STUDY_DATA': {
      const updatedStudyData = cloneDeep(state.studyData);
      updatedStudyData[action.StudyInstanceUID] = cloneDeep(action.data);

      return Object.assign({}, state, { studyData: updatedStudyData });
    }
    case 'SET_EXTENSION_ACCESSIBLE': {
      const updatedExtensions = cloneDeep(state.extensionsReady);
      updatedExtensions[action.StudyInstanceUID] = {
        ...updatedExtensions[action.StudyInstanceUID],
      };
      updatedExtensions[action.StudyInstanceUID][action.extension] =
        action.ready;

      return Object.assign({}, state, { extensionsReady: updatedExtensions });
    }

    case 'SET_SEQUENCE_SELECTION_STATE': {
      const sequenceSelection = cloneDeep(state.sequenceSelection);
      sequenceSelection[action.StudyInstanceUID] =
        action.sequenceSelectionState;

      return Object.assign({}, state, { sequenceSelection: sequenceSelection });
    }
    case 'SET_ACTIVE_SEGMENTATION': {
      return Object.assign({}, state, {
        activeSegmentation: action.activeSegmentation,
      });
    }

    default:
      return state;
  }
};

export default servers;
