import { useContext, useEffect, useState } from 'react';
import {
  SegmentationPanelContext,
  usePartialVolumeCalculation,
} from '../SegmentationPanelContext';

export const useReactiveManualVolume = () => {
  const { getCombinedVoxelVolume } = useContext(SegmentationPanelContext);
  const { getManualVolumeHandler } = usePartialVolumeCalculation();
  const manualVolumeHandler = getManualVolumeHandler();

  const [manualVolume, setManualVolume] = useState(
    manualVolumeHandler.getVolumes()
  );

  const updateManualVolume = () => {
    setManualVolume(manualVolumeHandler.getVolumes() || null);
  };

  useEffect(() => {
    document.addEventListener('volumeMeasurementsUpdated', updateManualVolume);
    return () => {
      document.removeEventListener(
        'volumeMeasurementsUpdated',
        updateManualVolume
      );
    };
  }, []);

  return {
    hasManualVolume: () => manualVolumeHandler.hasManualVolumeMeasurements(),
    getVolumeByTrackingUID: trackingUID =>
      manualVolumeHandler.getVolumeByTrackingUID(trackingUID),
    manualVolume,
    combinedVolume: getCombinedVoxelVolume(),
  };
};
