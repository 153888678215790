import { Icon } from '@ohif/ui';
import React, { useContext } from 'react';
import { SequenceSelectionContext } from '../utils/sequenceSelectionContext';

import './SequenceSelector.styl';

const SequenceSelector = ({
  modality,
  orientation,
  title,
  extended,
  setExtended,
}) => {
  const { setSequence, getSequence, getSeriesDescription } = useContext(
    SequenceSelectionContext
  );

  const selectedSequence = getSequence({ modality, orientation });
  return (
    <div className="sequence-selector">
      <div className="sequence-selector-preview">
        <div className="selector-title">
          {title}
          <div className="algorithm-label">{selectedSequence.algorithm()}</div>
        </div>
        <div className="selector-preview">
          {getSeriesDescription(selectedSequence.seriesInstanceUID())}
        </div>
        {extended ? (
          <Icon
            className="icon"
            name={'arrow-up'}
            width="18px"
            height="18px"
            onClick={setExtended}
          />
        ) : (
          <Icon
            className="icon"
            name={'arrow-down'}
            width="18px"
            height="18px"
            onClick={setExtended}
          />
        )}
      </div>
      {extended && (
        <ExtendedSequenceSelectorSection
          modality={modality}
          orientation={orientation}
          setSequenceCallback={sequence => {
            setSequence({ modality, orientation, sequence });
            setExtended();
          }}
        />
      )}
    </div>
  );
};

const ExtendedSequenceSelectorSection = ({
  modality,
  orientation,
  setSequenceCallback,
}) => {
  const { getSequence, getAvailableSequences } = useContext(
    SequenceSelectionContext
  );
  const selectedSequence = getSequence({ modality, orientation });

  return (
    <ul className="sequence-selector-list">
      {getAvailableSequences().map(seq => {
        return (
          <li
            key={`${modality}-${orientation}-${seq.SeriesInstanceUID}`}
            className={`sequence-tag ${
              selectedSequence.seriesInstanceUID() === seq ? 'selected' : ''
            }`}
            onClick={() => setSequenceCallback(seq.SeriesInstanceUID)}
          >
            {seq.SeriesDescription}
          </li>
        );
      })}
    </ul>
  );
};

export { SequenceSelector };
