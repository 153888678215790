import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { CLASS_MAPPING_LOOKUP_TABLE } from '../../../../constants';

import './SegmentCardComponents.styl';

const interpolateColor = (floatValue, color1, color2) => {
  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  const red = Math.round((1 - floatValue) * rgb1.r + floatValue * rgb2.r);
  const green = Math.round((1 - floatValue) * rgb1.g + floatValue * rgb2.g);
  const blue = Math.round((1 - floatValue) * rgb1.b + floatValue * rgb2.b);

  return `rgba(${red}, ${green}, ${blue}, 0.7)`;
};

const hexToRgb = hex => {
  hex = hex.replace(/^#/, '');

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return { r, g, b };
};

export const Tooltip = ({ children, tooltipText }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      className="tooltip-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showTooltip && <div className="tooltip-text">{tooltipText}</div>}
    </div>
  );
};

export const ClassDistributionTable = ({ data }) => {
  const [t] = useTranslation('SegmentationPanel');

  return (
    data && (
      <div className="segment-card-field">
        <div className="sc-body">
          <div className="cdt-table">
            {data.columns.map((col, index) => {
              return (
                <div
                  className={classNames('column', {
                    selected: index == data.classIndex,
                  })}
                  style={{
                    backgroundColor: interpolateColor(
                      col.value,
                      '#FFFFB2',
                      'BD0026'
                    ),
                    fontWeight: 700,
                  }}
                  key={`cdt-col-${data.trackingUID}-${index + 1}`}
                >
                  <div className="title">
                    <Tooltip
                      tooltipText={t(CLASS_MAPPING_LOOKUP_TABLE[col.title])}
                    >
                      <span>{col.title}</span>
                    </Tooltip>
                  </div>
                  <div className="value">{col.value.toFixed(2)}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    )
  );
};
