import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  updateViewportPreview,
  loadImage,
  DEFAULT_SIZE,
  REFRESH_VIEWPORT_TIMEOUT,
} from './utils';

export const MRIPreviewImage = ({
  label,
  data,
  enableViewport,
  disableViewport,
  setDataURL,
}) => {
  const [viewportPreview, setViewportPreview] = useState({
    src: null,
    width: DEFAULT_SIZE,
    height: DEFAULT_SIZE,
  });
  const [downloadCanvas, setDownloadCanvas] = useState({
    ref: createRef(),
    width: DEFAULT_SIZE,
    height: DEFAULT_SIZE,
  });
  const [viewportElement, setViewportElement] = useState();
  const [viewportElementDimensions, setViewportElementDimensions] = useState({
    width: DEFAULT_SIZE,
    height: DEFAULT_SIZE,
  });

  const refreshViewport = useRef(null);

  useEffect(() => {
    enableViewport(viewportElement);

    return () => {
      disableViewport(viewportElement);
    };
  }, [disableViewport, enableViewport, viewportElement]);

  const loadAndUpdateViewports = useCallback(async () => {
    const { width: scaledWidth, height: scaledHeight } = await loadImage({
      stack: data.stack,
      renderOptions: data.renderOptions,
      center: data.center,
      viewportElement,
    });

    const scaledDimensions = {
      height: scaledHeight,
      width: scaledWidth,
    };

    setViewportElementDimensions(scaledDimensions);
    setDownloadCanvas(state => ({
      ...state,
      ...scaledDimensions,
    }));

    const {
      dataUrl,
      width: viewportElementWidth,
      height: viewportElementHeight,
    } = await updateViewportPreview(
      viewportElement,
      downloadCanvas.ref.current
    );

    setDataURL(dataUrl);

    setViewportPreview(state => ({
      ...state,
      src: dataUrl,
      width: viewportElementWidth,
      height: viewportElementHeight,
    }));
  }, [viewportElement, downloadCanvas.ref]);

  useEffect(() => {
    if (refreshViewport.current !== null) {
      clearTimeout(refreshViewport.current);
    }

    refreshViewport.current = setTimeout(() => {
      refreshViewport.current = null;
      loadAndUpdateViewports();
    }, REFRESH_VIEWPORT_TIMEOUT);
  }, [viewportElement, downloadCanvas.ref]);

  return (
    <div
      className="mri-preview-image"
      key={`Preview-${label}-${data.stack[data.center]}`}
    >
      <div
        style={{
          height: viewportElementDimensions.height,
          width: viewportElementDimensions.width,
          position: 'absolute',
          left: '9999px',
        }}
        ref={ref => setViewportElement(ref)}
        key={`Render-${label}-${data.stack[data.center]}`}
      >
        <canvas
          className="cornerstone-canvas"
          style={{
            height: downloadCanvas.height,
            width: downloadCanvas.width,
            display: 'block',
          }}
          width={downloadCanvas.width}
          height={downloadCanvas.height}
          ref={downloadCanvas.ref}
        ></canvas>
      </div>

      {viewportPreview.src ? (
        <img
          className="mri-preview"
          src={viewportPreview.src}
          alt={'mriPreview'}
          data-cy="mri-preview"
        />
      ) : (
        'Loading'
      )}
    </div>
  );
};
