import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DropDownPanel, Icon, LoadingSpinner, Checkbox } from '@ohif/ui';

import { MailArea, RecommendationArea } from './components';
import { ReportContextProvider, useReportData } from './reportContext';
import { canGenerateReport } from './utils';

import { OptionalStudyComponentsContextProvider } from '../context/OptionalStudyComponentsContext';
import { PDFViewerModal } from '../pdf/PDFViewerModal';
import './ReportModal.styl';

export const ReportModal = ({ onDecline }) => {
  const [t] = useTranslation('ReportPanel');
  const {
    UINotificationService,
    UIModalService,
  } = window.ohif.app.servicesManager.services;
  const {
    reportData: { segmentations, report },
    user,
    userManager,
  } = useReportData();

  const [openPanel, _] = useState({
    recommendation: false,
    mail: false,
  });
  const [ready, setReady] = useState(false);
  const [reportSettings, setReportSettings] = useState({
    renderHeatmaps: true,
  });

  useEffect(() => {
    if (!canGenerateReport({ segmentations, report })) {
      onDecline();
      return;
    }
    setReady(true);
  }, []);

  const RecommendationPanelTitle = () => {
    const hasRecommendation =
      report.recommendation.therapyRecommendation &&
      report.recommendation.therapyRecommendation.length > 0;

    return (
      <>
        <span>{t('Recommendation')}</span>
        {hasRecommendation && (
          <>
            <Icon className="dropdown-status" name="check-circle-o" />
            <span className="recommendation-preview">
              {report.recommendation.therapyRecommendation.map((item, index) =>
                index === 0 ? t(item) : `, ${t(item)}`
              )}
            </span>
          </>
        )}
      </>
    );
  };

  const MailPanelTitle = () => {
    const hasMailType =
      report.recommendation.mailType &&
      report.recommendation.mailType.length > 0;

    return (
      <>
        <span>{t('Explanatory email')}</span>
        {hasMailType && (
          <>
            <Icon className="dropdown-status" name="check-circle-o" />
            <span className="recommendation-preview">
              {report.recommendation.mailType.map((item, index) =>
                index === 0 ? t(item) : `, ${t(item)}`
              )}
            </span>
          </>
        )}
      </>
    );
  };

  const ConnectedPDFModal = () => {
    return (
      <OptionalStudyComponentsContextProvider
        studyInstanceUID={segmentations[0].studyInstanceUID}
      >
        <ReportContextProvider
          studyInstanceUID={segmentations[0].studyInstanceUID}
          user={user}
          userManager={userManager}
          reportSettings={reportSettings}
        >
          <PDFViewerModal />
        </ReportContextProvider>
      </OptionalStudyComponentsContextProvider>
    );
  };

  const onDowload = () => {
    if (
      !report.recommendation.mailType ||
      !report.recommendation.mailType.length ||
      !report.recommendation.therapyRecommendation ||
      !report.recommendation.therapyRecommendation.length
    ) {
      UINotificationService.show({
        type: 'warning',
        title: 'Report kann nicht erstellt werden',
        message: 'Bitte vervollständigen Sie die notwendendigen Informationen',
      });
      return;
    }

    UIModalService.show({
      content: ConnectedPDFModal,
      title: `Download PDF`,
    });
  };

  return (
    <div className="report-modal">
      {!ready ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="report-modal-body">
            <DropDownPanel
              title={RecommendationPanelTitle()}
              isOpen={openPanel.recommendation}
            >
              <RecommendationArea
                selectedValues={report.recommendation.therapyRecommendation}
              />
            </DropDownPanel>
            <DropDownPanel title={MailPanelTitle()} isOpen={openPanel.mail}>
              <MailArea selectedValues={report.recommendation.mailType} />
            </DropDownPanel>
            <div className="further-settings">
              <span className="title">{t('Report Settings')}</span>
              <Checkbox
                label={t('Render heatmaps')}
                checked={reportSettings.renderHeatmaps}
                onChange={checked =>
                  setReportSettings({
                    ...reportSettings,
                    renderHeatmaps: checked,
                  })
                }
              />
            </div>
          </div>
          <div className="report-modal-footer">
            <button onClick={onDowload}>{t('Download report')}</button>
          </div>
        </>
      )}
    </div>
  );
};
