const flatten = arrays => {
  return arrays.reduce((acc, curr) => {
    if (!(curr instanceof Array)) {
      throw new Error('Invalid arguments. Only Arrays are allowed');
    }
    acc = acc.concat(curr);
    return acc;
  }, []);
};

const compare = (a, b) => {
  if (a.props.selected) return -1;
  if (b.props.selected) return 1;

  return 0;
};

export { flatten, compare };
