import './OHIFLogo.css';

import { Icon } from '@ohif/ui';
import React from 'react';

function OHIFLogo(props) {

  const foundWidth = props && props.width ? props.width : ''
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className="header-brand"
      href="https://www.alta-klinik.de"
      style={{ width: foundWidth }}
    >
      <Icon name="ohif-logo" className="header-logo-image" width='150px' heigth="90px" />
    </a>
  );
}

export default OHIFLogo;
