import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { useResources } from '../ResourceContextProvider';
import { OpenTable, PanelMainHeading } from './general';
import { calculateBladderVolume } from './utils';

const styling = StyleSheet.create({
  Text: {
    fontSize: '8px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
});

const sumVolume = ({ segmentation }) => {
  return segmentation.segments.reduce((acc, curr) => {
    return acc + curr.generalInfo.voxelVolume;
  }, 0);
};

const TableEntryWithSupplement = ({ mainText, subText }) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        margin: 'auto',
      }}
    >
      <Text style={{ fontSize: '8px', marginRight: '2px' }}>{mainText}</Text>
      <Text style={{ fontSize: '7px' }}>{subText}</Text>
    </View>
  );
};

const computeProstateVolumeTable = ({ segmentations, bladder }) => {
  const prostate = segmentations.find(_seg => _seg.segmentType === 'zone');
  const prostateVolume = sumVolume({ segmentation: prostate });
  const transVolume = prostate.segments.find(
    seg => seg.generalInfo.furtherInfo.zone === 'transitional'
  ).generalInfo.voxelVolume;
  const periVolume = prostate.segments.find(
    seg => seg.generalInfo.furtherInfo.zone === 'peripheral'
  ).generalInfo.voxelVolume;

  const volumeTable = [
    { key: 'Gesamt', value: `${prostateVolume.toFixed(1)} ml` },
    {
      key: 'Transitional',
      customComponent: (
        <TableEntryWithSupplement
          mainText={`${transVolume.toFixed(1)} ml`}
          subText={`(${((transVolume / prostateVolume) * 100).toFixed(1)}%)`}
        />
      ),
    },
    {
      key: 'Peripher',
      customComponent: (
        <TableEntryWithSupplement
          mainText={`${periVolume.toFixed(1)} ml`}
          subText={`(${((periVolume / prostateVolume) * 100).toFixed(1)}%)`}
        />
      ),
    },
  ];

  if (bladder) {
    volumeTable.push({
      key: 'Restharnvolumen',
      value: `${calculateBladderVolume({ bladder })} ml`,
    });

    const fillLevel =
      bladder <= 100 ? 'gering' : bladder >= 300 ? 'stark' : 'mäßig';

    volumeTable.push({
      key: 'Füllstand Harnblase',
      value: fillLevel,
    });
  }

  return volumeTable;
};

export const ProstateVolume = () => {
  const {
    reportData: { segmentations },
    getComponentByType,
  } = useResources();

  const table = computeProstateVolumeTable({
    segmentations,
    bladder: getComponentByType({ type: 'bladder' }),
  });

  return (
    <View style={styling}>
      <PanelMainHeading heading={'3. Prostatavolumen'} />
      <View style={styling.row}>
        <OpenTable content={table} style={{ marginBottom: '4px' }} />
      </View>
    </View>
  );
};
