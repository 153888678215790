import React from 'react';
// Svgs
import apex from './svgs/apex.svg';
import base from './svgs/base.svg';
import coronal from './svgs/coronal.svg';
import logo from './svgs/logo.svg';
import mid from './svgs/mid.svg';
import ohifLogoText from './svgs/ohif-logo-text.svg';
import ohifLogoWrappedText from './svgs/ohif-logo-wrapped-text.svg';
import seminalVesicles from './svgs/seminal-vesicles.svg';

const SVGS = {
  'ohif-logo-wrapped-text': ohifLogoWrappedText,
  'ohif-logo-text': ohifLogoText,
  'seminal-vesicles': seminalVesicles,
  apex: apex,
  coronal: coronal,
  base: base,
  mid: mid,
  logo,
};

/**
 * Return the matching SVG as a React Component.
 * Results in an inlined SVG Element. If there's no match,
 * return `null`
 */
export default function getSvg(key, props) {
  if (!key || !SVGS[key]) {
    return React.createElement('div', null, 'Missing SVG');
  }

  return React.createElement(SVGS[key], props);
}

export { SVGS };
