import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useViewerConfig } from '../../../../../../platform/viewer/config/ViewerConfigProvider';
import { useSegmentationConfig } from '../../../context/SegmentationPanelContext';
import { hasAllSegmentations } from '../../../utils/SegmentationUtils';
import { generateSegmentation } from './SegmentationMissingDialog/operations';

export const StudyContextArea = props => {
  const [t] = useTranslation('SegmentationPanel');
  const { UINotificationService } = window.ohif.app.servicesManager.services;
  const { apiInterface } = window.ohif.app;
  const { studyInstanceUID } = useSegmentationConfig();
  const {
    viewerConfig: { studyContextArea },
  } = useViewerConfig();

  const [hasShowableContent, setHasShowableContent] = useState(false);
  const [showableContent, setShowableContent] = useState({
    generateSegmentations: false,
    additionalComponents: false,
    markAsInvalid: false,
  });

  const _setShowableContent = () => {
    let contentToShow = { ...showableContent };

    contentToShow.additionalComponents = studyContextArea.segmentationStudyContext()
      .length
      ? true
      : false;
    contentToShow.markAsInvalid = studyContextArea.showMarkAsInvalid;

    hasAllSegmentations({ studyInstanceUID }).then(_show => {
      setShowableContent({ ...contentToShow, generateSegmentations: !_show });
    });

    setShowableContent(contentToShow);
  };

  useEffect(() => {
    _setShowableContent();
    document.addEventListener('deleteSegmentation', _setShowableContent);
    document.addEventListener('addedNewSeries', _setShowableContent);

    return () => {
      document.removeEventListener('deleteSegmentation', _setShowableContent);
      document.removeEventListener('addedNewSeries', _setShowableContent);
    };
  }, []);

  useEffect(() => {
    const hasShowableContent = Object.values(showableContent).some(val => val);
    setHasShowableContent(hasShowableContent);
  }, [showableContent]);

  const showStudyContextArea =
    studyContextArea.showStudyContext && hasShowableContent;

  return showStudyContextArea ? (
    <div className="seg-panel-card study-context-area">
      <div className="seg-panel-card-header">Study Context</div>
      <div className="seg-panel-card-body">
        {showableContent.generateSegmentations && (
          <>
            <div className="missing-segmentation-text">
              {t(
                'It seems that one or more segmentations are currently missing in your workspace. You can create the missing ones here.'
              )}
            </div>
            <button
              onClick={() => {
                generateSegmentation({
                  studyInstanceUID,
                  generateEmpty: false,
                  segmentationType: 'auto',
                });
              }}
            >
              {t('Generate AI segmentation')}
            </button>
          </>
        )}
        {showableContent.markAsInvalid && (
          <button
            onClick={() => {
              apiInterface
                .markStudyAsAIUnsuitable({ studyInstanceUID })
                .then(response =>
                  UINotificationService.show({
                    type: 'success',
                    message: 'Studie für AI Inferenz als unbrauchbar markiert',
                  })
                );
            }}
          >
            {t('Mark study as invalid')}
          </button>
        )}
        <div className="study-context-panels">
          {studyContextArea.segmentationStudyContext()}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
