import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import { OpenSelector } from '@ohif/ui';

const Option = ({ t, text, isHighlighted = false }) => {
  return (
    <div
      key={text}
      className={classnames('choice-opt', {
        highlighted: isHighlighted,
      })}
    >
      <span>{t(text)}</span>
    </div>
  );
};
export const SingleOptionSelector = ({
  t,
  choices,
  selectedValue,
  onSelect,
}) => {
  const createOptions = () => {
    return choices.map(opt => {
      return <Option t={t} key={opt} text={opt} />;
    });
  };

  const [options, setOptions] = useState(createOptions());
  const [selectedOption, setSelectedOption] = useState(selectedValue);

  useEffect(() => {
    setOptions(() => {
      return createOptions().map(opt => {
        if (!selectedOption) return opt;

        if (opt.props.text !== selectedOption) return opt;

        return (
          <Option
            t={t}
            key={opt.props.text}
            text={opt.props.text}
            isHighlighted={true}
          />
        );
      });
    });

    if (onSelect) onSelect(selectedOption);
  }, [selectedOption]);

  return (
    <div className="panel-area">
      <OpenSelector
        options={options}
        onSelect={opt => setSelectedOption(opt.props.text)}
      />
    </div>
  );
};
