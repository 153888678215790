import { StudentTableRow } from '@ohif/ui';
import {
  AdditionalSegmentationPanelComponents,
  StudyContextProvider,
  getSegmentationTypeConfig,
} from '../../../extensions/dicom-segmentation/src/config/AnnotationSegmentPanelConfig';
import { studentTemplateBuilder } from '../../ui/src/components/studyList/studyListTemplates';

export const AnnotationConfigProvider = () => {
  return {
    layoutOption: 'center',
    customModalStyle: {},
    studyList: {
      tableFilterBuilder: studentTemplateBuilder,
      TableRow: StudentTableRow,
      settings: {
        respectUserRoles: false,
        findEditableStudies: true,
        showStudiesWOReport: false,
        removeUnusableStudies: false,
      },
      showStudySkipper: true,
    },
    segmentations: {
      showLesions: true,
      showZones: true,
      segmentationPanelConfigProvider: getSegmentationTypeConfig,
    },
    studyContextArea: {
      showStudyContext: true,
      showMarkAsInvalid: true,
      segmentationStudyContext: StudyContextProvider,
    },
    hangingProtocol: [
      {
        type: 'sequenceMapping',
        value: { modality: 't2w', orientation: 'tra' },
      },
      {
        type: 'sequenceMapping',
        value: { modality: 'adc', orientation: 'tra' },
      },
      {
        type: 'seriesDescription',
        value: 'Heatmap Lesion',
      },
      {
        type: 'sequenceMapping',
        value: { modality: 't2w', orientation: 'sag' },
      },
    ],
    tabs: ['study'],
    canCreateReport: false,
    additionalSegmentationPanelComponents: AdditionalSegmentationPanelComponents,
  };
};
