import React from 'react';
import { useTranslation } from 'react-i18next';

import { useReactiveManualVolume } from '../../../../context/hooks/useReactiveManualVolume';
import './CombinedVolumeCard.styl';

export const CombinedVolumeCard = () => {
  const [t] = useTranslation('SegmentationPanel');
  const {
    hasManualVolume,
    manualVolume,
    combinedVolume,
  } = useReactiveManualVolume();

  const displayedVolume = hasManualVolume()
    ? manualVolume.total
    : combinedVolume.toFixed(1);

  return (
    <div className="combined-volume-card">
      <div className="flex-left">
        <span>{t('Combined Volume')}</span>
      </div>
      <div className="flex-right">
        <div className="volume">
          <span className="bold">{displayedVolume}</span>
          <span>{` ml`}</span>
          {hasManualVolume() && <span className="astrix">*</span>}
          <div className="subscripts">
            {hasManualVolume() && (
              <span>{`*${t('Manual volume specification')}`}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
