import { connect } from 'react-redux';
import { SegmentCard } from './SegmentCard';

const mapStateToProps = state => {
  return {
    editModeActive: state.viewports.editMode,
  };
};

export const ConnectedSegmentCard = connect(mapStateToProps)(SegmentCard);
