import { connect } from 'react-redux';
import { SegmentationCardHeader } from './SegmentationCardHeader';

const mapStateToProps = state => {
  return {
    editModeActive: state.viewports.editMode,
  };
};

export const ConnectedSegmentationCardHeader = connect(mapStateToProps)(
  SegmentationCardHeader
);
