import React from 'react';
import './InformationPanel.styl';

export const InformationPanel = ({ title, body }) => {
  return (
    <div className="info-panel">
      <div className="title">{title}</div>
      <div className="body">{body}</div>
    </div>
  );
};
