import extensions from './extensions';
import filterAttributes from './filterAttributes';
import preferences from './preferences';
import servers from './servers';
import tabs from './tabs';
import studies from './studies';
import timepointManager from './timepointManager';
import viewerStates from './viewerStates';
import viewports from './viewports';

const reducers = {
  extensions,
  preferences,
  servers,
  studies,
  timepointManager,
  viewports,
  viewerStates,
  filterAttributes,
  tabs,
};

export default reducers;
