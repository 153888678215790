import React, { useContext, useState } from 'react';
import { withTranslation } from 'react-i18next';

import { hooks } from '@ohif/core';
import { CustomScrollableArea, Icon, LoadingSpinner } from '@ohif/ui';

import { useViewerConfig } from '../../../../../platform/viewer/config/ViewerConfigProvider';
import UserManagerContext from '../../../../../platform/viewer/src/context/UserManagerContext';
import store from '../../../../../platform/viewer/src/store';
import { OptionalStudyComponentsContextProvider } from '../../context/OptionalStudyComponentsContext';
import { SegmentationContextProvider } from '../../context/SegmentationPanelContext';
import { ReportModal } from '../../report/ReportModal';
import { ReportContextProvider } from '../../report/reportContext';
import { SEGMENTATION_TYPE } from '../constants';
import './AltaSegmentationPanel.styl';
import { SegmentationTypeArea } from './SegmentationTypeArea';
import { ConncetedSegmentationSettings } from './components/ConncetedSegmentationsSettings';
import { StudyContextArea } from './components/StudyContextArea';

const ConnectedAltaSegmentationPanel = props => {
  const loading = hooks.useLoadingStudy(props.studies);

  return (
    <div className="segmentation-panel">
      <div className="heading panel-heading">
        {props.t('alta AI Predictions')}
      </div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <SegmentationContextProvider
          studies={props.studies}
          activeIndex={props.activeIndex}
          activeViewport={props.viewports[props.activeIndex]}
          viewports={props.viewports}
          activeContexts={props.activeContexts}
          contexts={props.contexts}
          onSelectedSegmentationChange={props.onSelectedSegmentationChange}
          onSegmentVisibilityChange={props.onSegmentVisibilityChange}
          onDisplaySetLoadFailure={props.onDisplaySetLoadFailure}
          onSegmentItemClick={props.onSegmentItemClick}
          onConfigurationChange={props.onConfigurationChange}
        >
          <AltaSegmentationPanel {...props} />
        </SegmentationContextProvider>
      )}
    </div>
  );
};

const AltaSegmentationPanel = ({
  t,
  viewports,
  activeIndex,
  activeContexts = [],
  contexts = {},
}) => {
  const { UIModalService } = window.ohif.app.servicesManager.services;
  const [showSettings, setShowSettings] = useState(false);
  const { viewerConfig } = useViewerConfig();
  const userManager = useContext(UserManagerContext);

  const ConnectedReportModal = () => {
    return (
      <ReportContextProvider
        studyInstanceUID={viewports[activeIndex].StudyInstanceUID}
        userManager={userManager}
        user={store.getState().oidc.user}
      >
        <ReportModal onDecline={UIModalService.hide} />
      </ReportContextProvider>
    );
  };

  const onCreateReport = () => {
    UIModalService.show({
      content: ConnectedReportModal,
      title: 'Report',
    });
  };

  return (
    <div className="segmentation-panel-inner">
      {showSettings ? (
        <ConncetedSegmentationSettings
          isVTK={activeContexts.includes(contexts.VTK)}
          onBack={() => {
            setShowSettings(false);
          }}
        />
      ) : (
        <div className="segmentation-panel-body">
          <Icon
            className="icon cog-icon"
            name="cog"
            width="25px"
            height="25px"
            onClick={() => setShowSettings(true)}
          />
          <CustomScrollableArea>
            {/* Study Context */}
            <StudyContextArea />

            {/* Segmentations */}
            {viewerConfig.segmentations.showLesions && (
              <SegmentationTypeArea
                segmentationType={SEGMENTATION_TYPE.LESION}
              ></SegmentationTypeArea>
            )}
            {viewerConfig.segmentations.showZones && (
              <SegmentationTypeArea
                segmentationType={SEGMENTATION_TYPE.ZONE}
              ></SegmentationTypeArea>
            )}

            {/* Optional Components */}
            <OptionalStudyComponentsContextProvider
              studyInstanceUID={viewports[activeIndex].StudyInstanceUID}
            >
              {viewerConfig.additionalSegmentationPanelComponents.map(comp =>
                comp()
              )}
            </OptionalStudyComponentsContextProvider>
          </CustomScrollableArea>
        </div>
      )}

      <div className="segmentation-panel-footer">
        {viewerConfig.canCreateReport && (
          <button onClick={onCreateReport}>{t('Create report')}</button>
        )}
      </div>
    </div>
  );
};

export default withTranslation('SegmentationPanel')(
  ConnectedAltaSegmentationPanel
);
