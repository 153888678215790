import React from 'react';
import { useTranslation } from 'react-i18next';

export const ZoneTitle = props => {
  const [t] = useTranslation('SegmentationPanel');
  return props.segmentMetadata.generalInfo.furtherInfo.zone === 'peripheral' ? (
    <span>{t('Peripheral Zone')}</span>
  ) : (
    <span>{t('Transitional Zone')}</span>
  );
};

export const LesionTitle = props => {
  const [t] = useTranslation('SegmentationPanel');
  return <span>{`${t('Lesion')}  ${props.index + 1}`}</span>;
};

export const LesionTitleExtension = ({ diagnosticClass }) => {
  const [t] = useTranslation('SegmentationPanel');
  return diagnosticClass ? (
    <div className="lesion-title-extension">
      <span className="delimiter">-</span>
      <span className="title-extension">{t(diagnosticClass)}</span>
    </div>
  ) : (
    <></>
  );
};
