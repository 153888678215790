import './DropdownMenu.styl';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '../Icon';

export const DropdownMenu = ({ value, onChange, options }) => {
  const [t] = useTranslation('Common');
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const EmptySelection = () => {
    return <span style={{ opacity: 0.5 }}>{t('No selection')}</span>;
  };

  return (
    <div className="dropdown-menu">
      <div className="dropdown-preview" onClick={handleToggle}>
        <span>{value || <EmptySelection />}</span>
        <Icon className="icon" name={expanded ? 'arrow-up' : 'arrow-down'} />
      </div>
      {expanded && (
        <ul className="dropdown-options">
          {options.map((option, index) => (
            <li
              key={index}
              onClick={() => {
                setExpanded(false);
                onChange(option);
              }}
            >
              {option || <EmptySelection />}
              {value === option && (
                <Icon
                  className="icon"
                  name="check"
                  width="10px"
                  height="10px"
                />
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
