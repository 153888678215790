import cornerstoneTools from 'cornerstone-tools';
import moment from 'moment';

import OHIF, { utils } from '@ohif/core';
import store from '../../../../platform/viewer/src/store';

import { deriveSubtextFromDerivedDS } from '../../../../platform/viewer/src/utils/checkIfSeriesEditable';
import {
  deriveSegmentationOrigin,
  dicomFieldToSegmentationType,
  generateSegmentTitle,
} from '../components/AltaSegmentationPanel/utils';
import setActiveLabelmap from '../utils/setActiveLabelMap';
import { SEGMENTATION_TYPE } from '../components/constants';

const { setViewportActive } = OHIF.redux.actions;
const { studyMetadataManager } = utils;

export const getLabelMapList = ({
  studies,
  activeViewport,
  onSelectedSegmentationChange,
  onDisplaySetLoadFailure,
  setSelectedSegmentation,
}) => {
  /* Get list of SEG labelmaps specific to active viewport (reference series) */
  const referencedSegDisplaysets = _getReferencedSegDisplaysets(
    activeViewport.StudyInstanceUID,
    activeViewport.SeriesInstanceUID
  );

  const filteredReferencedSegDisplaysets = referencedSegDisplaysets.filter(
    segDisplay => segDisplay.loadError !== true
  );

  return filteredReferencedSegDisplaysets.map(displaySet => {
    const {
      labelmapIndex,
      originLabelMapIndex,
      hasOverlapping,
      SeriesDate,
      SeriesTime,
    } = displaySet;

    /* Map to display representation */
    const dateStr = `${SeriesDate}:${SeriesTime}`.split('.')[0];
    const date = moment(dateStr, 'YYYYMMDD:HHmmss');
    const displayDate = date.format('ddd, MMM Do YYYY, h:mm:ss a');
    const displayDescription = displaySet.SeriesDescription;
    const segmentationSubtext = deriveSubtextFromDerivedDS(displaySet.metadata);
    const seriesInstanceUID = displaySet.SeriesInstanceUID;
    const studyInstanceUID = displaySet.StudyInstanceUID;
    const segmentationType = dicomFieldToSegmentationType(displayDescription);
    const segmentationOrigin = deriveSegmentationOrigin({
      metadata: displaySet.metadata,
    });

    return {
      value: hasOverlapping === true ? originLabelMapIndex : labelmapIndex,
      studyInstanceUID: studyInstanceUID,
      seriesInstanceUID: seriesInstanceUID,
      title: displayDescription,
      subtext: segmentationSubtext,
      description: displayDate,
      segmentationType,
      segmentationOrigin,
      onClick: async () => {
        const activatedLabelmapIndex = await setActiveLabelmap(
          activeViewport,
          studies,
          displaySet,
          onSelectedSegmentationChange,
          onDisplaySetLoadFailure
        );
        setSelectedSegmentation({
          selectedSegmentation: activatedLabelmapIndex,
          selectedSegmentationType: segmentationType,
        });

        return getSegmentList({ activeViewport });
      },
    };
  });
};

export const getSegmentList = ({ activeViewport }) => {
  const labelmap3D = getActiveLabelMaps3D({ activeViewport });

  const uniqueSegmentIndexes = [];

  // remove undefined labelmaps
  const indexOfUndefindedLabelmaps = labelmap3D.metadata.data
    .map((element, index) => {
      if (!element) {
        return index;
      }
    })
    .filter(id => id >= 0);
  indexOfUndefindedLabelmaps.forEach(invalidIndex => {
    labelmap3D.metadata.data.splice(invalidIndex, 1);
  });

  labelmap3D.metadata.data.forEach((segment, index) => {
    if (!segment) {
      return;
    }
    uniqueSegmentIndexes.push(index);
  });

  const colorLutTable = getColorLUTTable({ activeViewport });

  const segmentList = [];

  for (let i = 0; i < uniqueSegmentIndexes.length; i++) {
    const segmentIndex = uniqueSegmentIndexes[i];

    /* Meta */
    const segmentMeta = labelmap3D.metadata.data[segmentIndex];
    const segmentNumber = segmentMeta.SegmentNumber;
    const trackingUID = labelmap3D.metadata.data[segmentIndex].TrackingUID;

    // insert segment number into labelmaps.segmentsHidden
    if (labelmap3D.segmentsHidden[segmentNumber] === undefined) {
      labelmap3D.segmentsHidden[segmentNumber] = false;
    }

    const color = colorLutTable[segmentNumber];

    const segmentationType = dicomFieldToSegmentationType(
      segmentMeta.SegmentLabel
    );
    const { title, subtitle } = generateSegmentTitle({
      segmentationType,
      segmentIndex,
    });

    // processed information, which are ready to be used in UI components
    const displayInformation = { title, subtitle };

    segmentList.push({
      trackingUID,
      segmentMeta,
      segmentNumber,
      segmentIndex,
      color,
      displayInformation,
      isVisible: !labelmap3D.segmentsHidden[segmentNumber],
    });
  }
  return { segmentList, segmentsHidden: labelmap3D.segmentsHidden };
};

/**
 * Returns SEG DisplaySets that reference the target series, sorted by dateTime
 *
 * @param {string} StudyInstanceUID
 * @param {string} SeriesInstanceUID
 * @returns Array
 */
const _getReferencedSegDisplaysets = (StudyInstanceUID, SeriesInstanceUID) => {
  /* Referenced DisplaySets */
  const studyMetadata = studyMetadataManager.get(StudyInstanceUID);
  const referencedDisplaysets = studyMetadata.getDerivedDatasets({
    referencedSeriesInstanceUID: SeriesInstanceUID,
    Modality: 'SEG',
  });

  /* Sort */
  referencedDisplaysets.sort((a, b) => {
    const aNumber = Number(`${a.SeriesDate}${a.SeriesTime}`);
    const bNumber = Number(`${b.SeriesDate}${b.SeriesTime}`);
    return bNumber - aNumber;
  });

  return referencedDisplaysets;
};

export const getBrushStackStateBySeriesInstanceUID = ({
  seriesInstanceUID,
}) => {
  const module = cornerstoneTools.getModule('segmentation');
  const brushStackState = Object.values(module.state.series).find(bss => {
    const seriesInstanceUIDs = bss.labelmaps3D.map(
      lm => lm.metadata.segmentationSeriesInstanceUID
    );
    return seriesInstanceUIDs.includes(seriesInstanceUID);
  });

  return brushStackState;
};

/* Cornerstone Tools */
export const getBrushStackState = ({ activeViewport }) => {
  const module = cornerstoneTools.getModule('segmentation');

  const { StudyInstanceUID, displaySetInstanceUID } = activeViewport;
  const studyMetadata = studyMetadataManager.get(StudyInstanceUID);

  const firstImageId = studyMetadata.getFirstImageId(displaySetInstanceUID);
  const brushStackState = module.state.series[firstImageId];
  return brushStackState;
};

export const getActiveLabelMaps3D = ({ activeViewport }) => {
  const { labelmaps3D, activeLabelmapIndex } = getBrushStackState({
    activeViewport,
  });
  return labelmaps3D[activeLabelmapIndex];
};

export const getActiveLabelMaps3DBySeriesInstanceUID = ({
  seriesInstanceUID,
}) => {
  const {
    labelmaps3D,
    activeLabelmapIndex,
  } = getBrushStackStateBySeriesInstanceUID({
    seriesInstanceUID,
  });
  return labelmaps3D[activeLabelmapIndex];
};

export const getActiveLabelMaps2D = ({ activeViewport }) => {
  const { labelmaps2D } = getActiveLabelMaps3D({ activeViewport });
  return labelmaps2D;
};

export const getColorLUTTable = ({ activeViewport }) => {
  const { state } = cornerstoneTools.getModule('segmentation');
  const { colorLUTIndex } = getActiveLabelMaps3D({ activeViewport });
  return state.colorLutTables[colorLUTIndex];
};

export const getActiveSegmentIndex = ({ activeViewport }) => {
  const { activeSegmentIndex } = getActiveLabelMaps3D({ activeViewport });
  return activeSegmentIndex;
};

export const getActiveLabelMapIndex = ({ activeViewport }) => {
  const { activeLabelmapIndex } = getBrushStackState({ activeViewport });
  return activeLabelmapIndex;
};

export const getCurrentDisplaySet = ({ activeViewport }) => {
  const { StudyInstanceUID, displaySetInstanceUID } = activeViewport;
  const studyMetadata = studyMetadataManager.get(StudyInstanceUID);
  const allDisplaySets = studyMetadata.getDisplaySets();
  return allDisplaySets.find(
    ds => ds.displaySetInstanceUID === displaySetInstanceUID
  );
};

/* External Metadata */
export const MetadataPromise = ({
  studyInstanceUID,
  seriesInstanceUID,
  segmentType,
}) => {
  const { apiInterface } = window.ohif.app;
  return Promise.all([
    apiInterface.getSegmentation({
      studyInstanceUID,
      seriesInstanceUID,
    }),
    apiInterface.getUserToStudy({ studyInstanceUID }),
    apiInterface.getSegmentationInferenceData({
      studyInstanceUID,
      seriesInstanceUID,
    }),
    apiInterface.getSegmentationErrors({ segmentType }),
  ]);
};

export const isT2 = ({ activeViewport }) => {
  const { sequenceSelectionManager } = window.ohif.app;

  const sequenceSelection = sequenceSelectionManager.getSequenceMapping(
    activeViewport.StudyInstanceUID
  );

  const t2w_tra_map = sequenceSelection.selectAtomicMapping({
    modality: 't2w',
    orientation: 'tra',
  });

  return t2w_tra_map.seriesInstanceUID() === activeViewport.SeriesInstanceUID;
};

export const createMockSegmentMeta = ({ segmentList, segmentationInfo }) => {
  return {
    editingInfo: {
      comment: '',
      confidenceLevel: 'unprocessed',
    },
    segmentType: segmentationInfo.segmentationType,
    segments: segmentList.map(seg => ({
      detailInfo: {},
      generalInfo: {
        volume: 0,
      },
      processingState: 'unprocessed',
      trackingUID: seg.trackingUID,
    })),
    seriesInstanceUID: segmentationInfo.seriesInstanceUID,
    studyInstanceUID: segmentationInfo.studyInstanceUID,
  };
};

export const tryOpenT2Viewport = ({ viewports }) => {
  let foundT2 = false;

  Object.values(viewports).forEach((_vp, index) => {
    if (isT2({ activeViewport: _vp })) {
      foundT2 = true;
      store.dispatch(setViewportActive(index));
    }
  });

  return foundT2;
};

export const setColorLUTForLabelmap = ({ labelmap3D, segmentationType }) => {
  switch (segmentationType) {
    case SEGMENTATION_TYPE.ZONE:
      labelmap3D.colorLUTIndex = 1;
      break;
    default:
      labelmap3D.colorLUTIndex = 0;
  }
};
