import OHIF from '@ohif/core';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { useResources } from '../ResourceContextProvider';

const styling = StyleSheet.create({
  backgroundColor: '#C4C4C4',
  display: 'flex',
  flexDirection: 'row',
  fontSize: '11px',
  padding: '5px 5px',
  justifyContent: 'space-between',
  marginBottom: '2px',
});

/**
 * Computes the age in years from the given date
 * @param {*} param0
 */
const computeAgeInYears = ({ date }) => {
  const today = new Date();
  let age = today.getFullYear() - date.getFullYear();
  if (
    today.getMonth() < date.getMonth() ||
    (today.getMonth() === date.getMonth() && today.getDate() < date.getDate())
  ) {
    age--;
  }
  return age;
};

const getPatientName = ({ studyInstanceUID }) => {
  const { studyMetadataManager } = OHIF.utils;
  const study = studyMetadataManager.get(studyInstanceUID);
  const patientName = study._data.series[0].instances[0].metadata.PatientName.toString();

  if (patientName.includes('^')) {
    const [lastName, firstName] = patientName.split('^');
    return `${lastName}, ${firstName}`;
  }

  return patientName;
};

const PatientInfoHeader = () => {
  const {
    reportData: { study },
  } = useResources();
  const birthDate = new Date(study.birthDate).toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return (
    <View style={styling}>
      <Text>{`Patient/-in: ${getPatientName({
        studyInstanceUID: study.studyInstanceUID,
      })}`}</Text>
      <Text>{`Geburtstag: ${birthDate} (${computeAgeInYears({
        date: new Date(study.birthDate),
      })} Jahre)`}</Text>
      <Text>{`Pat-ID: ${study.patientID}`}</Text>
    </View>
  );
};

export { PatientInfoHeader };
