import OHIF from '@ohif/core';
import cornerstoneTools from 'cornerstone-tools';
import { setEditMode, setViewportLayoutAndData } from '../actions';

const { studyMetadataManager } = OHIF.utils;

const getADCDisplaySet = studyInstanceUID => {
  const { sequenceSelectionManager } = window.ohif.app;
  const studyData = studyMetadataManager.get(studyInstanceUID);

  const sequenceSelection = sequenceSelectionManager.getSequenceMapping(
    studyInstanceUID
  );

  const adc_tra_map = sequenceSelection.selectAtomicMapping({
    modality: 'adc',
    orientation: 'tra',
  });

  if (adc_tra_map.isEmpty()) {
    return;
  }

  return studyData.findDisplaySet(
    ds => ds.SeriesInstanceUID === adc_tra_map.seriesInstanceUID()
  );
};

const buildSingleView = viewports => {
  const { viewportSpecificData, activeViewportIndex } = viewports;

  return setViewportLayoutAndData(
    {
      numRows: 1,
      numColumns: 1,
      viewports: [{ plugin: 'cornerstone' }],
    },
    { 0: viewportSpecificData[activeViewportIndex] }
  );
};

const buildDualView = viewports => {
  const { viewportSpecificData, activeViewportIndex } = viewports;

  const nullableADCDisplaySet = getADCDisplaySet(
    viewportSpecificData[activeViewportIndex].StudyInstanceUID
  );

  return setViewportLayoutAndData(
    {
      numRows: 1,
      numColumns: 2,
      viewports: [{ plugin: 'cornerstone' }, { plugin: 'cornerstone' }],
    },
    {
      0: viewportSpecificData[activeViewportIndex],
      1: nullableADCDisplaySet || viewportSpecificData[activeViewportIndex],
    }
  );
};

// fetchTodoById is the "thunk action creator"
export function toggleEditMode(activated) {
  // fetchTodoByIdThunk is the "thunk function"
  return async function fetchTodoByIdThunk(dispatch, getState) {
    const { layout, segmentations } = window.config;

    const toolName = activated ? segmentations.editMode.defaultTool : 'Wwwc';

    cornerstoneTools.setToolActive(toolName, { mouseButtonMask: 1 });

    // we have to notify other parts in this app
    const activatedToolEvent = new CustomEvent('activatedTool', {
      detail: { toolName: toolName, editMode: activated },
    });

    const { viewports } = getState();

    document.dispatchEvent(activatedToolEvent);
    dispatch(setEditMode(activated));

    if (activated) {
      const numberOfViewports = layout.numberOfViewportsEditMode;

      if (numberOfViewports === 1) {
        dispatch(buildSingleView(viewports));
      } else if (numberOfViewports === 2) {
        dispatch(buildDualView(viewports));
      } else {
        throw new Error('Unsupported number of viewports');
      }
    } else {
      // reset view
      const {
        numRows,
        numColumns,
        viewportSpecificData,
      } = viewports.oldViewports;

      if (viewportSpecificData) {
        const viewportsLayout = Object.values(viewportSpecificData).map(vp => ({
          plugin: vp.plugin,
        }));

        dispatch(
          setViewportLayoutAndData(
            {
              numRows: numRows,
              numColumns: numColumns,
              viewports: viewportsLayout,
            },
            viewportSpecificData
          )
        );
      }
    }
  };
}
