import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styling = StyleSheet.create({
  backgroundColor: '#DEDEDE',
  display: 'flex',
  flexDirection: 'row',
  fontSize: '9px',
  padding: '2px 2px',
  justifyContent: 'space-between',
  marginBottom: '2px',
});

const PanelMainHeading = ({ heading }) => {
  return (
    <View style={styling}>
      <Text>{heading}</Text>
    </View>
  );
};

export { PanelMainHeading };
