import { useState, useEffect } from 'react';

export const useHighlight = () => {
  const [highlightedVPs, setHighlightedVPs] = useState([]);

  useEffect(() => {
    document.addEventListener('highlightOn', onHighlightOn);
    document.addEventListener('highlightKill', onHighlightKill);

    return () => {
      document.removeEventListener('highlightOn', onHighlightOn);
      document.removeEventListener('highlightKill', onHighlightKill);
    };
  }, []);

  const onHighlightOn = e => {
    const providedID = e.detail.id;

    setHighlightedVPs(vps => {
      if (!vps.find(vp => vp.id === providedID)) {
        return [...vps, { id: providedID, highlighted: true }];
      } else {
        return vps.map(vp => {
          if (vp.id !== providedID) {
            return vp;
          }
          return { ...vp, highlighted: true };
        });
      }
    });

    return;
  };

  const onHighlightKill = () => {
    setHighlightedVPs(vps => {
      return vps.map(vp => {
        return { ...vp, highlighted: false };
      });
    });
  };

  const isHighlighted = id => {
    if (highlightedVPs.find(vp => vp.id === id))
      return highlightedVPs.find(vp => vp.id === id).highlighted;
    else return false;
  };

  return { isHighlighted: isHighlighted };
};
