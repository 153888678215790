import cornerstoneTools from 'cornerstone-tools';
import React, { useEffect, useState } from 'react';

import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { useResources } from '../ResourceContextProvider';
import { ClosedTable, PanelMainHeading, PanelSubHeading } from './general';

import { Canvg } from 'canvg';
import { calculateCombinedPirads } from './utils';

const styling = StyleSheet.create({
  Images: {
    margin: '0px 0px 10px 0px',
    padding: '0px 10px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    Text: {
      alignSelf: 'start center',
      textAlgin: 'center',
      fontSize: '10px',
    },
    Basal: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      width: '26%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    SV: {
      width: '70%',
    },
    Medial: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
      marginLeft: '20px',
      width: '27%',
    },
    Apikal: {
      height: '100%',
      flexDirection: 'column',
      flexGrow: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
      marginLeft: '20px',
      paddingBottom: '20px',
      width: '27%',
    },
    Coronal: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      justifyContent: 'space-between',
      width: '19%',
    },
  },
  Table: {
    // height: "200px",
  },
});

const extractLesionData = ({ segmentations }) => {
  const { state } = cornerstoneTools.getModule('segmentation');
  const colors = state.colorLutTables[0];

  const lesionSegmentation = segmentations.find(
    seg => seg.segmentType === 'lesion'
  );

  return lesionSegmentation.segments.map((seg, index) => {
    const { piradsT2W, piradsBVal, piradsADC, piradsDCE } = seg.detailInfo;

    // round colors to int and set opacity to 0.5
    const roundedColor = colors[index + 1].map(c => Math.round(c));
    roundedColor[3] = 0.5;
    const color = `rgba(${roundedColor.join(',')})`;

    return [
      {
        key: 'Läsion',
        customComponent: (
          <Text
            style={{
              margin: 'auto',
              backgroundColor: color,
              borderRadius: '50%',
              width: '9px',
            }}
          >
            {index + 1}
          </Text>
        ),
      },
      { key: 'Volumen', value: `${seg.generalInfo.voxelVolume}ml` },
      { key: 'T2W', value: piradsT2W },
      { key: 'b-Wert', value: piradsBVal },
      { key: 'ADC', value: piradsADC },
      { key: 'DCE', value: piradsDCE === 1 ? '+' : '-' },
      {
        key: 'PIRADS*',
        value: calculateCombinedPirads(piradsT2W, piradsADC, piradsDCE),
      },
    ];
  });
};

const FOOT_NOTES = [
  'PIRADS 1 = Klinisch signifikantes Prostata-Ca. sehr unwahrscheinlich',
  'PIRADS 2 = unwahrscheinlich',
  'PIRADS 3 = fraglich',
  'PIRADS 4 = wahrscheinlich',
  'PIRADS 5 = sehr wahrscheinlich',
];

const ProstateLesions = () => {
  const { lesionUI, reportData } = useResources();
  const debug = false;
  const [pngDataURL, setPngDataURL] = useState({
    apex: undefined,
    mid: undefined,
    base: undefined,
    sv: undefined,
    coronal: undefined,
  });

  useEffect(async () => {
    convertSvgToPng(lesionUI.base, 800, 400, 'base');
    convertSvgToPng(lesionUI.mid, 700, 600, 'mid');
    convertSvgToPng(lesionUI.apex, 800, 400, 'apex');
    convertSvgToPng(lesionUI.SV, 800, 400, 'sv');
    convertSvgToPng(lesionUI.coronal, 800, 600, 'coronal');
  }, []);

  const convertSvgToPng = async (svg, width, height, name) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = width;
    canvas.height = height;

    const v = await Canvg.from(ctx, svg);
    v.render().then(() => {
      const png = canvas.toDataURL('image/png');
      setPngDataURL(os => {
        const newState = { ...os };
        newState[name] = png;
        return newState;
      });
    });
  };
  return (
    <View style={styling}>
      <PanelMainHeading heading={'4. Beschreibung der Prostataläsionen'} />
      <PanelSubHeading heading={'Prostataläsionstabelle'} />
      <View style={styling.Images}>
        <View style={styling.Images.Basal} debug={debug}>
          <Text style={styling.Images.Text}>Basal</Text>
          <Image style={styling.Images.SV} src={pngDataURL.sv}></Image>
          <Image src={pngDataURL.base}></Image>
        </View>

        <View style={styling.Images.Medial} debug={debug}>
          <Text style={{ ...styling.Images.Text, marginBottom: '20px' }}>
            Medial
          </Text>
          <Image src={pngDataURL.mid}></Image>
        </View>

        <View style={styling.Images.Apikal}>
          <Text
            style={{ ...styling.Images.Text, marginBottom: '20px' }}
            debug={debug}
          >
            Apikal
          </Text>
          <Image src={pngDataURL.apex} debug={debug}></Image>
        </View>

        <View style={styling.Images.Coronal} debug={debug}>
          <Text style={{ ...styling.Images.Text, alignSelf: 'center' }}>
            Coronal
          </Text>
          <Image src={pngDataURL.coronal}></Image>
        </View>
      </View>
      <View style={styling.Table}>
        <ClosedTable
          content={extractLesionData({
            segmentations: reportData.segmentations,
          })}
          footnotes={FOOT_NOTES}
        />
      </View>
    </View>
  );
};

export { ProstateLesions };
