import { DropDownSelector } from '../../components/AltaSegmentationPanel/components/SegmentDetailCard/components/DropDownSelector';

const isNotNull = value => value !== null && value !== undefined;

export const PeripheralZoneDetailCardTemplate = [
  {
    rowTitle: null,
    selectors: [
      {
        title: 'appearance',
        label: 'appearance',
        selector: DropDownSelector,
        isValueValid: isNotNull,
        options: ['symetric', 'asymetric'],
        optional: true,
      },
    ],
  },
  {
    rowTitle: null,
    selectors: [
      {
        title: 'compression',
        label: 'compression',
        selector: DropDownSelector,
        isValueValid: isNotNull,
        options: [
          'little',
          'little to medium',
          'medium',
          'medium to strong',
          'strong',
        ],
        optional: true,
      },
    ],
  },
];

export const TransitionZoneDetailCardTemplate = [
  {
    rowTitle: null,
    selectors: [
      {
        title: 'nodularAlteration',
        label: 'nodularAlteration',
        selector: DropDownSelector,
        isValueValid: isNotNull,
        options: [
          'small',
          'small to medium',
          'medium',
          'medium to large',
          'large',
        ],
        optional: true,
      },
    ],
  },
  {
    rowTitle: null,
    selectors: [
      {
        title: 'cysticPortion',
        label: 'cysticPortion',
        selector: DropDownSelector,
        isValueValid: isNotNull,
        options: [
          'small',
          'small to medium',
          'medium',
          'medium to large',
          'large',
        ],
        optional: true,
      },
    ],
  },
];

export const LesionDetailCardTemplate = [
  {
    rowTitle: null,
    selectors: [
      {
        title: 'diagnosticClass',
        label: 'diagnosticClass',
        selector: DropDownSelector,
        isValueValid: isNotNull,
        options: [
          'No tumor',
          'Chronic inflammation',
          'High-grade PIN',
          'Gleason 6',
          'Gleason 7a',
          'Gleason 7b',
          'Gleason 8',
          'Gleason 9',
          'Gleason 10',
        ],
        optional: false,
      },
    ],
  },
  {
    rowTitle: null,
    selectors: [
      {
        title: 'zone',
        label: 'zone',
        selector: DropDownSelector,
        isValueValid: isNotNull,
        options: ['Peripher', 'Transitional', 'Whole Gland'],
        optional: true,
      },
    ],
  },
  {
    rowTitle: null,
    selectors: [
      {
        title: 'region',
        label: 'region',
        selector: DropDownSelector,
        isValueValid: isNotNull,
        options: [
          'Completly',
          'Basal',
          'Basal/Midglade',
          'Midglade',
          'Midglade/Apikal',
          'Apikal',
          'Basal/Apikal',
        ],
        optional: true,
      },
    ],
  },
  {
    rowTitle: null,
    selectors: [
      {
        title: 'laterality',
        label: 'laterality',
        selector: DropDownSelector,
        isValueValid: isNotNull,
        options: ['Left', 'Right', 'Both sides'],
        optional: true,
      },
    ],
  },
];
