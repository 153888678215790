import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@ohif/ui';
import { useBiopsyInfo } from '../../../../context/SegmentationPanelContext';

import './BiopsyInformation.styl';

export const BiopsyInformation = () => {
  const { UINotificationService } = window.ohif.app.servicesManager.services;
  const { getBiopsyInfoPromise } = useBiopsyInfo();

  const [t] = useTranslation('SegmentationPanel');

  const [expanded, setExpanded] = useState(true);
  const [biopsyInfo, setBiopsyInfo] = useState(null);

  useEffect(() => {
    getBiopsyInfoPromise()
      .then(response => {
        if (response.biopsyInfo === '') {
          response.biopsyInfo = 'No biopsy information available';
        }
        setBiopsyInfo(t(response.biopsyInfo));
      })
      .catch(e => {
        UINotificationService.show({
          type: 'error',
          title: e.title,
          message: e.message,
        });
      });
  }, []);

  return (
    <div className="segment-panel-box biopsy-info">
      <div className="biopsy-info-header">
        <span className="heading small">{t('Biopsy Results')}</span>
        <div className="icon-group">
          <Icon
            className="icon"
            name={`arrow-${expanded ? 'up' : 'down'}`}
            width="18px"
            height="18px"
            onClick={() => {
              setExpanded(!expanded);
            }}
          />
        </div>
      </div>
      {expanded &&
        (biopsyInfo && (
          <div className="biopsy-info">
            {biopsyInfo
              .split('-')
              .map((bullet, index) =>
                index === 0 ? null : (
                  <span key={`biopsy-info-${index}`}>{`- ${bullet}`}</span>
                )
              )}
          </div>
        ))}
    </div>
  );
};
