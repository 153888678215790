const checkIfSeriesIsEditable = firstInstanceMetadata => {
  switch (firstInstanceMetadata.SegmentSequence[0].SegmentAlgorithmName) {
    case 'Manual Prostate Lesion Segmentation':
      return true;
    case 'Automatic Prostate Lesion Segmentation':
      return false;
    case 'Automatic Prostate Zone Segmentation':
      return false;
    case 'Manual Prostate Zone Segmentation':
      return true;
    default:
      return false;
  }
};

const deriveSubtextFromDerivedDS = firstInstanceMetadata => {
  switch (firstInstanceMetadata.SegmentSequence[0].SegmentAlgorithmName) {
    case 'Manual Prostate Lesion Segmentation':
      return 'Lesion';
    case 'Automatic Prostate Lesion Segmentation':
      return 'Alta KI - Lesion';
    case 'Automatic Prostate Zone Segmentation':
      return 'Alta KI - Zone';
    case 'Manual Prostate Zone Segmentation':
      return 'Zone';
    default:
      return undefined;
  }
};

export { deriveSubtextFromDerivedDS, checkIfSeriesIsEditable };
