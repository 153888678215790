import { CLASS_MAPPING_LOOKUP_TABLE_SHORT } from '../../components/constants';

const flattenSegmentCardTemplate = segmentCardTemplate => {
  return segmentCardTemplate.reduce((acc, curr) => {
    return acc.concat(curr.selectors);
  }, []);
};

export class SegmentMetadata {
  constructor({ segment, segmentCardTemplate = null, inferenceData, Title }) {
    this.segmentCardTemplate = segmentCardTemplate;
    this.flattenedSegmentCardTemplate = flattenSegmentCardTemplate(
      segmentCardTemplate
    );

    this.detailInfo = this._initDetailInfo({
      segment: segment.detailInfo || {},
    });

    this.inferenceData = inferenceData;

    this.trackingUID = segment.trackingUID;
    this.processingState = segment.processingState;
    this.origin = segment.origin;

    this.AICertainty = segment.generalInfo
      ? segment.generalInfo.AICertainty
      : null;
    this.voxelVolume = segment.generalInfo
      ? segment.generalInfo.voxelVolume
      : 0;

    // components
    this.Title = Title;
  }

  _initDetailInfo({ segment }) {
    const detailInfo = [];

    // get content of segmentCardTemplate
    this.flattenedSegmentCardTemplate.forEach(setting => {
      detailInfo.push({
        label: setting.title,
        labelInternal: setting.label,
        value: segment[setting.label],
        optional: setting.optional,
        isValueValid: setting.isValueValid,
      });
    });
    // get content of segment - we have to track provided segment info and segmentCardTemplate individual
    // if we omit segments we can run into problems with different viewer versions that require different information from the segments
    // TODO
    const existingInfos = detailInfo.map(detail => detail.label);
    for (var segmentDetail in segment) {
      if (!existingInfos.includes(segmentDetail)) {
        detailInfo.push({
          label: segmentDetail,
          value: segment[segmentDetail] || null,
          optional: true,
        });
      }
    }

    return detailInfo;
  }

  neccessaryInformationProvided() {
    return this.flattenedSegmentCardTemplate.reduce((acc, curr) => {
      const { value } = this.detailInfo.find(di => di.label === curr.title);

      if (!curr.optional && !curr.isValueValid(value)) {
        acc = false;
      }

      return acc;
    }, true);
  }

  getVoxelVolume() {
    return this.voxelVolume;
  }

  hasInferenceData() {
    return (
      this.inferenceData &&
      this.inferenceData.segment &&
      this.inferenceData.classMapping
    );
  }

  getClassDistributionTableData() {
    if (!this.hasInferenceData()) return null;

    const classes = Object.values(this.inferenceData.classMapping);
    classes.unshift('BG');

    const columns = classes.map((col, index) => {
      return {
        title: col,
        value: this.inferenceData.segment.classDistribution.mean[index],
      };
    });

    return {
      columns,
      trackingUID: this.trackingUID,
      classIndex: this.inferenceData.segment.classIndex,
    };
  }

  getDetailInfo() {
    return this.detailInfo;
  }

  getTrackingUID() {
    return this.trackingUID;
  }

  getOrigin() {
    if (!this.origin) return null;

    return this.origin;
  }

  getDiagnosticClass() {
    const detailInfo = this.detailInfo.find(
      detail => detail.labelInternal === 'diagnosticClass'
    );

    if (detailInfo && detailInfo.value) return detailInfo.value;

    if (!this.hasInferenceData()) return null;

    const classIndex = this.inferenceData.segment.classIndex;
    const selectedClass = Object.values(this.inferenceData.classMapping)[
      classIndex - 1
    ];

    return CLASS_MAPPING_LOOKUP_TABLE_SHORT[selectedClass];
  }

  getDetailInfo({ option }) {
    const foundDetail = this.detailInfo.find(
      detail => detail.labelInternal === option
    );

    if (!foundDetail) {
      console.log("couldn't find detail info for option: ", option);
    }

    return foundDetail === null ? null : foundDetail.value;
  }

  setDetailInfo({ option, value }) {
    this.detailInfo.map(detail => {
      if (detail.label !== option) return detail;
      detail.value = value;
      return detail;
    });
  }

  detailInfoToDict() {
    return this.detailInfo.reduce((acc, curr) => {
      if (curr.value !== null && curr.value !== undefined)
        acc[curr.labelInternal] = curr.value;
      return acc;
    }, {});
  }

  toJson() {
    return {
      detailInfo: this.detailInfoToDict(),
      generalInfo: {
        voxelVolume: this.voxelVolume,
      },
      trackingUID: this.trackingUID,
    };
  }
}
