import { utils } from '@ohif/core';

const { studyMetadataManager } = utils;

class SequenceSelectionManager {
  constructor() {
    this._api;
    this._studyMetadataManager;
    this._sequence_mappings = [];
  }

  setSequenceMapping(studyInstanceUID, mapping) {
    if (this.hasSequenceMapping(studyInstanceUID)) {
      this._sequence_mappings = this._sequence_mappings.filter(
        _map => _map.studyInstanceUID !== studyInstanceUID
      );
    }
    this._sequence_mappings.push({
      studyInstanceUID,
      mapping,
    });
  }

  init() {
    const { apiInterface } = window.ohif.app;
    this._api = apiInterface;
    this._studyMetadataManager = studyMetadataManager;
  }

  hasSequenceMapping(studyInstanceUID) {
    const foundStudy = this._sequence_mappings.find(
      mapping => mapping.studyInstanceUID === studyInstanceUID
    );

    return foundStudy !== undefined;
  }

  getSequenceMapping(studyInstanceUID) {
    if (!this.hasSequenceMapping(studyInstanceUID))
      throw new Error('No Mapping was found');

    const foundStudy = this._sequence_mappings.find(
      mapping => mapping.studyInstanceUID === studyInstanceUID
    );

    return foundStudy.mapping;
  }

  saveSequenceMapping(studyInstanceUID) {
    const sequenceMap = this.getSequenceMapping(studyInstanceUID);
    return this._api.setSequenceMap({ studyInstanceUID, sequenceMap });
  }
}

export default SequenceSelectionManager;
