import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { useResources } from '../ResourceContextProvider';
import { Logo } from '../resources/Logo';

const styling = StyleSheet.create({
  display: 'flex',
  marginBottom: '5px',
  justifyContent: 'space-between',
  flexDirection: 'row',
  LeftColumn: {
    width: '70%',
    MainHeading: {
      fontWeight: 700,
      marginBottom: '5px',
    },
    SubHeading: {
      fontWeight: 300,
    },
  },
  RightColumn: {
    width: '27.5%',
    Logo: {
      width: '100%',
      objectFit: 'scale-down',
      objectPosition: 'right center',
    },
  },
});

// Create Document Component
export const Header = () => {
  const {
    reportData: { study },
    user,
  } = useResources();

  const date = new Date(study.studyDate).toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const { LeftColumn, RightColumn } = styling;

  return (
    <View style={styling}>
      <View style={LeftColumn}>
        <Text style={LeftColumn.MainHeading}>
          {`Multiparametrische Kernspintomographie der Prostata unter
          Berücksichtigung des kleinen Beckens nativ und i. v. KM-Gabe vom
          ${date}`}
        </Text>
        <Text style={LeftColumn.SubHeading}>
          Behandelnder Arzt: {user.profile.displayName}
        </Text>
      </View>
      <View style={RightColumn}>
        <Logo />
      </View>
    </View>
  );
};
