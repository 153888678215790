import OHIF, { classes } from '@ohif/core';
import { studyFiltersToUriArgs } from './utils';
const { ServerError, ResourceError } = classes;

const pako = require('pako');

class APIInterface {
  constructor() {
    this.servers = this._server();
  }

  _server() {
    const servers = window.ohif.app.commandsManager._getAppState().servers;
    const viewerServer = servers.extensionServers.find(server => {
      return server.name === 'FlaskServer';
    });

    const formServer = servers.extensionServers.find(server => {
      return server.name === 'FormServer';
    });

    return {
      viewer: viewerServer,
      form: formServer,
    };
  }

  /**
   * We have to change the logic of how we catch errors here
   */
  async sendHttpRequest({
    suburl,
    requestOptions = {},
    defaultReturn = null,
    origin = 'viewer',
  }) {
    const url = `${this.servers[origin].url}/api${suburl}`;

    const headers = {
      ...requestOptions.headers,
      Authorization: OHIF.DICOMWeb.getAuthorizationHeader().Authorization,
    };

    let response;

    try {
      response = await fetch(url, {
        ...requestOptions,
        headers,
      });
    } catch (e) {
      throw new ServerError('Server Error', 'The server was not reachable');
    }

    if (!response.ok) {
      if (defaultReturn !== null) {
        return defaultReturn;
      }

      const errorMessage = await response.json();

      throw new ResourceError(
        errorMessage['type'] || 'Unknown Error',
        errorMessage['message'] || 'We encountered an unknown error'
      );
    }

    if (response.statusText === 'NO CONTENT' || response.status === 204) {
      return;
    }

    const responseMessage = await response.json();

    return responseMessage;
  }

  /*******************        Study        ********************/

  getStudy({ studyInstanceUID }) {
    const suburl = `/studies/${studyInstanceUID}`;
    return this.sendHttpRequest({ suburl });
  }

  markStudyAsAIUnsuitable({ studyInstanceUID }) {
    const suburl = `/studies/${studyInstanceUID}/markAsAIUnsuitable`;
    const requestOptions = {
      method: 'PATCH',
    };
    return this.sendHttpRequest({ suburl, requestOptions });
  }

  getStudyList({ filter }) {
    const suburl = `/studies`;
    const uriArgs = studyFiltersToUriArgs(filter);

    return this.sendHttpRequest({
      suburl: `${suburl}?${uriArgs}`,
    });
  }

  getBiopsyInfo({ studyInstanceUID }) {
    const suburl = `/studies/${studyInstanceUID}/biopsy`;
    return this.sendHttpRequest({
      suburl,
      defaultReturn: { biopsyInfo: 'No biopsy information available' },
    });
  }

  markAsHealthy({ studyInstanceUID, disease }) {
    const suburl = `/studies/${studyInstanceUID}/disease`;
    const requestOptions = {
      method: 'PATCH',
      body: JSON.stringify({ disease: disease }),
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return this.sendHttpRequest({ suburl, requestOptions });
  }

  setSequenceMap({ studyInstanceUID, sequenceMap }) {
    const suburl = `/studies/${studyInstanceUID}/sequenceMap`;
    const requestOptions = {
      method: 'PATCH',
      body: JSON.stringify({ sequenceMap: sequenceMap.toJSON() }),
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return this.sendHttpRequest({ suburl, requestOptions });
  }

  getSequenceMap({ studyInstanceUID }) {
    const suburl = `/studies/${studyInstanceUID}/sequenceMap`;
    return this.sendHttpRequest({ suburl });
  }

  assignUser({ studyInstanceUID, userID }) {
    const suburl = `/studies/${studyInstanceUID}/user`;
    const requestOptions = {
      method: 'PATCH',
      body: JSON.stringify({ userID: userID }),
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return this.sendHttpRequest({ suburl, requestOptions });
  }

  getOptionalComponents({ studyInstanceUID, args = {} }) {
    let suburl = `/studies/${studyInstanceUID}/optionalComponents`;

    for (var arg in args) {
      suburl += `&${arg}=${args[arg]}`;
    }

    return this.sendHttpRequest({ suburl });
  }

  patchOptionalComponents({ studyInstanceUID, args = {}, body }) {
    let suburl = `/studies/${studyInstanceUID}/optionalComponents`;
    let argAdded = false;

    for (var arg in args) {
      if (!argAdded) {
        suburl += `?${arg}=${args[arg]}`;
        argAdded = true;
      } else {
        suburl += `&${arg}=${args[arg]}`;
      }
    }

    const requestOptions = {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return this.sendHttpRequest({ suburl, requestOptions });
  }

  /*******************    Segmentation     ********************/

  getSegmentationList({ studyInstanceUID, complete = true }) {
    const suburl = `/studies/${studyInstanceUID}/segmentations?complete=${complete}`;
    return this.sendHttpRequest({ suburl });
  }

  getSegmentation({ studyInstanceUID, seriesInstanceUID }) {
    const suburl = `/studies/${studyInstanceUID}/segmentation/${seriesInstanceUID}`;
    return this.sendHttpRequest({ suburl });
  }

  getSegmentationInferenceData({ studyInstanceUID, seriesInstanceUID }) {
    const suburl = `/studies/${studyInstanceUID}/segmentation/${seriesInstanceUID}/inferenceData`;
    return this.sendHttpRequest({ suburl });
  }

  createSegmentation({
    studyInstanceUID,
    generateEmpty,
    segmentationType,
    editable = true,
  }) {
    const suburl = `/studies/${studyInstanceUID}/segmentation?generateEmpty=${generateEmpty}&editable=${editable}&segmentationType=${segmentationType}`;
    const requestOptions = {
      method: 'POST',
    };
    return this.sendHttpRequest({ suburl, requestOptions });
  }

  deleteSegmentation({ studyInstanceUID, seriesInstanceUID }) {
    const suburl = `/studies/${studyInstanceUID}/segmentation/${seriesInstanceUID}`;
    const requestOptions = {
      method: 'DELETE',
    };
    return this.sendHttpRequest({ suburl, requestOptions });
  }

  updateSegmentationMetadata({ studyInstanceUID, seriesInstanceUID, data }) {
    const suburl = `/studies/${studyInstanceUID}/segmentation/${seriesInstanceUID}/metadata`;
    const requestOptions = {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return this.sendHttpRequest({ suburl, requestOptions });
  }

  updateSegmentationImage({ studyInstanceUID, seriesInstanceUID, img, data }) {
    const suburl = `/studies/${studyInstanceUID}/segmentation/${seriesInstanceUID}`;

    // zip image
    const uintarray = new Uint8Array(img);
    const zippedBuffer = pako.deflate(uintarray);

    const form = new FormData();
    form.append('file', new Blob([zippedBuffer]));
    form.append('segmentation', JSON.stringify(data.segmentation));
    form.append('sopInstanceUID', data.sopInstanceUID);

    const requestOptions = {
      method: 'PATCH',
      body: form,
    };

    return this.sendHttpRequest({ suburl, requestOptions });
  }

  /*******************    Forms     ********************/
  getAppointmentList({ filter }) {
    const suburl = `/appointments`;
    const uriArgs = studyFiltersToUriArgs(filter);

    return this.sendHttpRequest({
      suburl: `${suburl}?${uriArgs}`,
      origin: 'form',
    });
  }

  getAppointment({ entryID }) {
    const suburl = `/appointments/${entryID}`;
    return this.sendHttpRequest({ suburl, origin: 'form' });
  }

  saveAppointment({ entryID, data }) {
    const suburl = `/appointments/${entryID}`;
    const requestOptions = {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return this.sendHttpRequest({ suburl, origin: 'form', requestOptions });
  }

  deleteAppointment({ entryID }) {
    const suburl = `/appointments/${entryID}`;
    const requestOptions = {
      method: 'DELETE',
    };

    return this.sendHttpRequest({ suburl, origin: 'form', requestOptions });
  }

  getFormsWithStatus({ entryID }) {
    const suburl = `/appointments/${entryID}/forms/status`;
    return this.sendHttpRequest({ suburl, origin: 'form' });
  }

  /*******************       Other        ********************/

  getSegmentationErrors({ segmentType }) {
    const suburl = `/segmentationErrors?segmentType=${segmentType}`;
    return this.sendHttpRequest({ suburl, defaultReturn: { errors: [] } });
  }

  patchSegmentationErrors({ segmentType, errors }) {
    const suburl = `/segmentationErrors?segmentType=${segmentType}`;

    const requestOptions = {
      method: 'PATCH',
      body: JSON.stringify({ errors: errors }),
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return this.sendHttpRequest({ suburl, requestOptions });
  }

  getFurtherUserInfo({ userID }) {
    const suburl = `/users/${userID}`;
    return this.sendHttpRequest({ suburl });
  }

  getUserToStudy({ studyInstanceUID }) {
    const suburl = `/studies/${studyInstanceUID}/user`;
    return this.sendHttpRequest({ suburl });
  }

  bulkUpload({ data }) {
    const suburl = `/bulkUpload?compressed=false`;

    const form = new FormData();
    form.append('file', new Blob([data]));

    const requestOptions = {
      method: 'POST',
      body: form,
    };
    return this.sendHttpRequest({ suburl, requestOptions });
  }

  getAltaPatientInfo({ patientID }) {
    const suburl = `/patient/${patientID}`;
    return this.sendHttpRequest({ suburl, defaultReturn: {} });
  }

  getSizeOfSeries({ studyInstanceUID, seriesInstanceUID }) {
    const suburl = `/series/${studyInstanceUID}/${seriesInstanceUID}/size`;
    return this.sendHttpRequest({ suburl });
  }

  hasReportData({ studyInstanceUID }) {
    const suburl = `/report/exists?studyInstanceUID=${studyInstanceUID}`;
    return this.sendHttpRequest({ suburl });
  }

  getReportData({ studyInstanceUID }) {
    const suburl = `/report?studyInstanceUID=${studyInstanceUID}`;
    return this.sendHttpRequest({ suburl });
  }

  patchReportRecommendation({
    studyInstanceUID,
    mailType,
    therapyRecommendation,
  }) {
    let suburl = `/report/recommendations?studyInstanceUID=${studyInstanceUID}`;

    let body = {};

    if (mailType) {
      body.mailType = mailType;
    }

    if (therapyRecommendation) {
      body.therapyRecommendation = therapyRecommendation;
    }

    const requestOptions = {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return this.sendHttpRequest({ suburl, requestOptions });
  }

  postReportPdfData({ studyInstanceUID, filename, file }) {
    let suburl = `/report?studyInstanceUID=${studyInstanceUID}`;

    const form = new FormData();
    form.append('file', new Blob([file]));
    form.append('filename', filename);

    const requestOptions = {
      method: 'POST',
      body: form,
    };

    return this.sendHttpRequest({ suburl, requestOptions });
  }
}

export default APIInterface;
