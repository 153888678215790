import React from 'react';

import { LoadingSpinnerNotificationCard } from '@ohif/ui';

const _loadingSpinnerDialog = ({ text }) => {
  const { UINotificationService } = window.ohif.app.servicesManager.services;

  UINotificationService.show({
    autoclose: false,
    type: 'info',
    customContent: <LoadingSpinnerNotificationCard text={text} />,
  });
};

const beforeUnloadListener = event => {
  event.preventDefault();
  return (event.returnValue = '');
};

const markCaseAsHealty = ({ studyInstanceUID, segmentationType }) => {
  const { UINotificationService } = window.ohif.app.servicesManager.services;
  const { apiInterface } = window.ohif.app;

  if (segmentationType !== 'lesion') {
    UINotificationService.show({
      type: 'error',
      title: 'Currently not implemented for other types than prostate lesions',
    });
  }

  apiInterface
    .markAsHealthy({ studyInstanceUID, disease: 'prostateCancer' })
    .then(() =>
      UINotificationService.show({
        type: 'success',
        title: 'Studie als gesund markiert',
      })
    );
};

const _throwNewSeriesEvent = ({ newSeries, isSegmentation }) => {
  document.dispatchEvent(
    new CustomEvent('addedNewSeries', {
      detail: {
        newSeries,
        callback: isSegmentation
          ? () => {
              document.dispatchEvent(
                new CustomEvent('onSegmentationAddedEnd', {
                  detail: {
                    seriesInstanceUID: newSeries[0].seriesInstanceUID,
                  },
                })
              );
            }
          : () => {},
      },
    })
  );
};

const _createSegmentation = ({
  studyInstanceUID,
  segmentationType,
  generateEmpty,
}) => {
  const { UINotificationService } = window.ohif.app.servicesManager.services;
  const { apiInterface } = window.ohif.app;

  // addedSegmentationEvent
  return new Promise((resolve, reject) => {
    apiInterface
      .createSegmentation({
        studyInstanceUID,
        generateEmpty,
        segmentationType,
      })
      .then(response => {
        resolve(response);
      })
      .catch(e => {
        reject();
      })
      .finally(() => {
        window.removeEventListener('beforeunload', beforeUnloadListener, {
          capture: true,
        });
        // hide loading spinner
        UINotificationService.hide();
        UINotificationService.show({
          type: 'success',
          title: 'Segmentierung erfolgreich erstellt!',
        });
      });
  });
};

const generateSegmentation = ({
  studyInstanceUID,
  segmentationType,
  generateEmpty = false,
}) => {
  const notificationText =
    (generateEmpty
      ? 'Generiere eine leere Segmentierung'
      : 'Generiere eine AI Segmentierung') +
    ' . Bitte schließen Sie nicht dieses Fenster';

  window.addEventListener('beforeunload', beforeUnloadListener, {
    capture: true,
  });

  _loadingSpinnerDialog({ text: notificationText });

  _createSegmentation({
    studyInstanceUID,
    segmentationType,
    generateEmpty,
  }).then(response => {
    const { segmentations, heatmaps } = response;

    const newSegmentations = segmentations.map(_seg => ({
      seriesInstanceUID: _seg.seriesInstanceUID,
      studyInstanceUID: _seg.studyInstanceUID,
    }));

    _throwNewSeriesEvent({ newSeries: newSegmentations, isSegmentation: true });

    const newHeatmaps = heatmaps.map(_seg => ({
      seriesInstanceUID: _seg.seriesInstanceUID,
      studyInstanceUID: _seg.studyInstanceUID,
    }));

    _throwNewSeriesEvent({ newSeries: newHeatmaps, isSegmentation: false });
  });
};

export { generateSegmentation, markCaseAsHealty };
