import React from 'react';
import { Icon } from '@ohif/ui';

import './TextField.styl';

const Textfield = ({ fieldName, value, onValueChange }) => {
  return (
    <div className="textField">
      <input
        type="text"
        id={`filter-${fieldName}`}
        className="form-control studylist-search"
        value={value}
        onChange={e => onValueChange(e.target.value)}
      />
      <Icon
        className="icon"
        name="times"
        width="10px"
        height="10px"
        onClick={() => {
          onValueChange('');
        }}
      />
    </div>
  );
};

export { Textfield };
