import { Icon, LoadingSpinner } from '@ohif/ui';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSegmentationPanel } from '../../context/SegmentationPanelContext';
import { ConnectedSegmentCard } from './components/SegmentCard/ConnectedSegmentCard';
import { ConnectedSegmentationCardHeader } from './components/SegmentationCardHeader/ConnectedSegmentationCardHeader';
import { SegmentationMissingDialog } from './components/SegmentationMissingDialog/SegmentationMissingDialog';
import { segmentationTypeToSegmentationString } from './utils';
import { SegmentationTypeAreaFooterButton } from './components/SegmentationTypeAreaFooterButton';

const SegmentationTypeArea = ({ segmentationType }) => {
  const [t] = useTranslation('SegmentationPanel');
  const {
    selectSegmentationByType,
    selectedSegmentationType,
    segmentationConfig,
    isSegmentationLoading,
    getSegmentList,
    seriesInstanceUIDProvider,
  } = useSegmentationPanel();

  const isVisible = segmentationType === selectedSegmentationType;

  const preHeaderComponents = useMemo(() => {
    if (seriesInstanceUIDProvider() === null) return;

    return segmentationConfig.optionalCardProviders.preHeader.map(
      cardProvider => {
        return cardProvider();
      }
    );
  }, [seriesInstanceUIDProvider()]);

  const postHeaderComponents = useMemo(() => {
    if (seriesInstanceUIDProvider() === null) return;
    return segmentationConfig.optionalCardProviders.postHeader.map(
      cardProvider => {
        return cardProvider();
      }
    );
  }, [seriesInstanceUIDProvider()]);

  return (
    <div className="seg-panel-card">
      <div className="seg-panel-card-header">
        {t(segmentationTypeToSegmentationString(segmentationType))}
        <Icon
          className="icon"
          name={isVisible ? 'arrow-up' : 'arrow-down'}
          onClick={() => {
            selectSegmentationByType({
              segmentationType: isVisible ? null : segmentationType,
            });
          }}
        />
      </div>
      {isVisible && (
        <div className="seg-panel-card-body">
          {isSegmentationLoading() ? (
            <LoadingSpinner />
          ) : segmentationConfig ? (
            <>
              {preHeaderComponents}
              <ConnectedSegmentationCardHeader />
              {postHeaderComponents}
              {getSegmentList().map(segment => {
                return (
                  <ConnectedSegmentCard
                    key={segment.trackingUID}
                    segmentMeta={segment}
                  />
                );
              })}
              <SegmentationTypeAreaFooterButton />
            </>
          ) : (
            <SegmentationMissingDialog segmentationType={segmentationType} />
          )}
        </div>
      )}
    </div>
  );
};

export { SegmentationTypeArea };
