/**
 * This class handles the operation that are bound to everything that is related to the volumes of segmentation
 */

const calculateVolumeFromMeasurementsInML = ({ measuements }) => {
  return (measuements.c * measuements.m * measuements.a) / (1000 * 2);
};

const getKeyByValue = (object, value) => {
  for (const key in object) {
    if (object[key] === value) {
      return key;
    }
  }
  return null;
};

export class ManualVolumeMeasurementHandler {
  constructor({ segmentationMetadata }) {
    this.segmentationMetadata = segmentationMetadata;

    const peripheral = this.segmentationMetadata.segments.find(
      _segment => _segment.generalInfo.furtherInfo.zone === 'peripheral'
    );
    const transitional = this.segmentationMetadata.segments.find(
      _segment => _segment.generalInfo.furtherInfo.zone === 'transitional'
    );

    // We use this to index the volumes
    this._trackingUIDVolumeMapping = {
      peripher: peripheral.trackingUID,
      transitional: transitional.trackingUID,
    };

    // We use this to index the measurements
    this._trackingUIDMeasurementMapping = {
      total: peripheral.trackingUID,
      transitional: transitional.trackingUID,
    };

    // set manual measurements
    const hasMeasurements = segmentationMetadata.segments.every(
      _segment => _segment.generalInfo.manualVolumeMeasures !== null
    );

    if (hasMeasurements) {
      this.manualVolumeMeasures = {
        total: peripheral.generalInfo.manualVolumeMeasures,
        transitional: transitional.generalInfo.manualVolumeMeasures,
      };
    } else {
      this.manualVolumeMeasures = null;
    }

    // set preliminary peripheral volume
    const hasPreliminaryVolumes = segmentationMetadata.segments.every(
      _segment => _segment.generalInfo.preliminaryVolume !== null
    );

    if (hasPreliminaryVolumes) {
      this.preliminaryVolumes = {
        peripher: peripheral.generalInfo.preliminaryVolume,
        transitional: transitional.generalInfo.preliminaryVolume,
      };
    } else {
      this.preliminaryVolumes = null;
    }
  }

  _getInfoByTrackingUID(trackingUID, data, mapping) {
    if (!Object.values(this._trackingUIDVolumeMapping).includes(trackingUID)) {
      throw new Error('TrackingUID not found in segmentation');
    }

    const segmentKey = getKeyByValue(mapping, trackingUID);

    return data[segmentKey];
  }

  /* Manual Measurements */

  areManualVolumeMeasurementsAllowed() {
    return true;
  }

  hasManualVolumeMeasurements() {
    return this.manualVolumeMeasures !== null;
  }

  getManualVolumeMeasurements() {
    if (!this.hasManualVolumeMeasurements()) return null;

    return this.manualVolumeMeasures;
  }

  getMeasurementsByTrackingUID({ trackingUID }) {
    return this._getInfoByTrackingUID(
      trackingUID,
      this.manualVolumeMeasures,
      this._trackingUIDMeasurementMapping
    );
  }

  setManualVolumeMeasurements({ volumeMeasures }) {
    this.manualVolumeMeasures = volumeMeasures;
    document.dispatchEvent(new CustomEvent('volumeMeasurementsUpdated'));
  }

  /* Volumes */

  getVolumeByTrackingUID({ trackingUID }) {
    const volumes = this.getVolumes();
    return this._getInfoByTrackingUID(
      trackingUID,
      volumes,
      this._trackingUIDVolumeMapping
    );
  }

  getVolumes() {
    if (!this.hasManualVolumeMeasurements())
      return {
        total: null,
        transitional: null,
        peripher: null,
      };

    const totalVolume = calculateVolumeFromMeasurementsInML({
      measuements: this.manualVolumeMeasures.total,
    });
    const transitionalVolume = calculateVolumeFromMeasurementsInML({
      measuements: this.manualVolumeMeasures.transitional,
    });

    return {
      total: Number(totalVolume).toFixed(1),
      transitional: Number(transitionalVolume).toFixed(1),
      peripher: Number(totalVolume - transitionalVolume).toFixed(1),
    };
  }

  /* Preliminary Volumes */
  arePreliminaryVolumesAllowed() {
    return true;
  }

  hasPreliminaryVolumes() {
    return this.preliminaryVolumes !== null;
  }

  getPreliminaryVolumes() {
    if (!this.hasPreliminaryVolumes()) return null;

    return this.preliminaryVolumes;
  }

  getPreliminaryVolumeByTrackingUID({ trackingUID }) {
    return this._getInfoByTrackingUID(
      trackingUID,
      this.preliminaryVolumes,
      this._trackingUIDVolumeMapping
    );
  }

  setPremliminaryVolumes({ preliminaryVolumes }) {
    this.preliminaryVolumes = preliminaryVolumes;
  }
}

export class ManualLesionVolumeHandler {
  constructor({ segmentationMetadata }) {
    this.segmentationMetadata = segmentationMetadata;
  }

  /* Manual Measurements */

  areManualVolumeMeasurementsAllowed() {
    return false;
  }

  hasManualVolumeMeasurements() {
    return false;
  }

  getManualVolumeMeasurements() {
    return null;
  }

  getMeasurementsByTrackingUID({ trackingUID }) {
    return null;
  }

  getVolumeByTrackingUID({ trackingUID }) {
    return null;
  }

  setManualVolumeMeasurements({ volumeMeasures }) {
    return;
  }

  /* Volumes */

  getVolumeByTrackingUID({ trackingUID }) {
    return null;
  }

  getVolumes() {
    return null;
  }

  /* Preliminary Volumes */

  arePreliminaryVolumesAllowed() {
    return false;
  }

  hasPreliminaryVolumes() {
    return false;
  }

  getPreliminaryVolumes() {
    return null;
  }

  getPreliminaryVolumeByTrackingUID({ trackingUID }) {
    return null;
  }

  setPremliminaryVolumes({ preliminaryVolumes }) {
    return;
  }
}

export const createManualVolumeMeasurementHandler = ({
  segmentationMetadata,
}) => {
  if (segmentationMetadata.segmentType !== 'zone')
    return new ManualLesionVolumeHandler({ segmentationMetadata });

  const hasZones = segmentationMetadata.segments.every(_segment =>
    _segment.generalInfo.furtherInfo.hasOwnProperty('zone')
  );

  return hasZones
    ? new ManualVolumeMeasurementHandler({ segmentationMetadata })
    : new ManualLesionVolumeHandler({ segmentationMetadata });
};
