import React from 'react';
import { useTranslation } from 'react-i18next';

import './SegmentCardComponents.styl';

export const CertaintyScoreField = ({ certainty }) => {
  const [t] = useTranslation('SegmentationPanel');
  return (
    <div className="certainty-score">
      <div className="cs-header">
        {t('Mean')} / {t('Max')}
      </div>
      <div className="cs-body">
        {certainty.mean} / {certainty.max}
      </div>
    </div>
  );
};
