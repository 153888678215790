import { Checkbox, Icon, CustomDateRangePicker } from '@ohif/ui';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { isInclusivelyBeforeDay } from 'react-dates';
import { useTranslation } from 'react-i18next';
import { selectOptionLayout } from './CustomOptionPicker/CustomOptionLayouts.js';
import { CustomOptionPicker } from './CustomOptionPicker/CustomOptionPicker.js';
import { Textfield } from './TextFieldFilter/Textfield.js';

const getDateEntry = (datePicked, rangeDatePicked) => {
  const date = rangeDatePicked || datePicked;

  if (!date) return null;

  return moment(date, 'MMM DD, YYYY');
};

const getDateEntryFromRange = (today, numOfDays, edge = 'start') => {
  if (typeof numOfDays !== 'number') {
    return;
  }

  if (edge === 'end') {
    return today;
  } else {
    today.subtract(numOfDays, 'days');
  }
};

function TableSearchFilter(props) {
  const {
    meta,
    values,
    onSort,
    onValueChange,
    sortFieldName,
    sortDirection,
    // TODO: Rename
    studyListDateFilterNumDays,
  } = props;

  const { studyDateTo, studyDateFrom } = values || {};
  const { birthDateTo, birthDateFrom } = values || {};
  const { appointmentDateTo, appointmentDateFrom } = values || {};

  const [focusedInput, setFocusedInput] = useState({
    StudyDate: null,
    PatientBirthDate: null,
  });

  const { t, ready: translationsAreReady } = useTranslation('Common');

  const sortIcons = ['sort', 'sort-up', 'sort-down'];
  const sortIconForSortField =
    sortDirection === 'asc' ? sortIcons[1] : sortIcons[2];

  const today = moment();
  const lastWeek = moment().subtract(7, 'day');
  const lastMonth = moment().subtract(1, 'month');

  const defaultStartDate = getDateEntryFromRange(
    today,
    studyListDateFilterNumDays,
    'start'
  );
  const defaultEndDate = getDateEntryFromRange(
    today,
    studyListDateFilterNumDays,
    'end'
  );

  const getDateConfig = ({ fieldName }) => {
    // we need to render different data and content depending on birthdate or study date
    const dateFrom = (() => {
      switch (fieldName) {
        case 'StudyDate':
          return { date: studyDateFrom, label: 'studyDateFrom' };
        case 'AppointmentDate':
          return { date: appointmentDateFrom, label: 'appointmentDateFrom' };
        case 'PatientBirthDate':
          return { date: birthDateFrom, label: 'birthDateFrom' };
        default:
          return { date: null, label: '' };
      }
    })();

    const dateTo = (() => {
      switch (fieldName) {
        case 'StudyDate':
          return { date: studyDateTo, label: 'studyDateTo' };
        case 'AppointmentDate':
          return { date: appointmentDateTo, label: 'appointmentDateTo' };
        case 'PatientBirthDate':
          return { date: birthDateTo, label: 'birthDateTo' };
        default:
          return { date: null, label: '' };
      }
    })();

    return {
      key: fieldName,
      startDate: getDateEntry(dateFrom.date, defaultStartDate),
      startDateId: dateFrom.label,
      endDate: getDateEntry(dateTo.date, defaultEndDate),
      endDateId: dateTo.label,
      // TODO: We need a dynamic way to determine which fields values to update
      onDatesChange: ({ startDate, endDate, preset = false }) => {
        onValueChange(
          dateFrom.label,
          startDate ? startDate.format('MMM DD, YYYY') : null
        );
        onValueChange(
          dateTo.label,
          endDate ? endDate.format('MMM DD, YYYY') : null
        );
      },
      focusedInput: focusedInput[fieldName],
      onFocusChange: updatedVal => {
        setFocusedInput({ ...focusedInput, [fieldName]: updatedVal });
      },
      // Optional
      numberOfMonths: 1, // For med and small screens? 2 for large?
      showClearDates: true,
      anchorDirection: 'left',
      presets: fieldName === 'StudyDate' ? studyDatePresets : [],
      hideKeyboardShortcutsPanel: true,
      isOutsideRange: day => !isInclusivelyBeforeDay(day, moment()),
    };
  };

  const studyDatePresets = [
    {
      text: t('Today'),
      start: today,
      end: today,
    },
    {
      text: t('Last 7 days'),
      start: lastWeek,
      end: today,
    },
    {
      text: t('Last 30 days'),
      start: lastMonth,
      end: today,
    },
  ];

  return translationsAreReady
    ? meta.map((field, i) => {
        const { displayText, fieldName, inputType, optionLayout } = field;
        const isSortField = sortFieldName === fieldName;
        const sortIcon = isSortField ? sortIconForSortField : sortIcons[0];

        return (
          <th key={`${fieldName}-${i}`}>
            <label
              htmlFor={`filter-${fieldName}`}
              onClick={() => onSort(fieldName)}
            >
              <span>{`${displayText}`}</span>
              <Icon name={sortIcon} style={{ fontSize: '12px' }} />
            </label>
            {inputType === 'text' && (
              <Textfield
                value={values[fieldName]}
                fieldName={fieldName}
                onValueChange={val => {
                  onValueChange(fieldName, val);
                }}
              />
            )}
            {inputType === 'option-picker' && (
              <CustomOptionPicker
                selectedValues={values[fieldName]}
                optionLayout={selectOptionLayout(optionLayout)}
                onOptionsSelect={updatedStates => {
                  onValueChange(fieldName, updatedStates);
                }}
              />
            )}
            {inputType === 'radioBox' && (
              <Checkbox
                label={''}
                checked={values[fieldName]}
                onChange={() => onValueChange(fieldName, !values[fieldName])}
                labelFirst={false}
              />
            )}
            {inputType === 'placeholder' && <div className="placeholder"></div>}
            {inputType === 'date-range' && (
              // https://github.com/airbnb/react-dates

              <CustomDateRangePicker {...getDateConfig({ fieldName })} />
            )}
          </th>
        );
      })
    : null;
}

TableSearchFilter.propTypes = {
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      displayText: PropTypes.string.isRequired,
      fieldName: PropTypes.string.isRequired,
      inputType: PropTypes.oneOf([
        'text',
        'date-range',
        'option-picker',
        'placeholder',
      ]).isRequired,
      size: PropTypes.number.isRequired,
    })
  ).isRequired,
  values: PropTypes.object.isRequired,
  onSort: PropTypes.func.isRequired,
  sortFieldName: PropTypes.string,
  sortDirection: PropTypes.oneOf([null, 'asc', 'desc']),
};

TableSearchFilter.defaultProps = {};

export { TableSearchFilter };
export default TableSearchFilter;
