import {
  LesionTitle,
  ZoneTitle,
} from '../../components/AltaSegmentationPanel/components/SegmentCard/components';
import { EditingInfo } from './EditingInfo';
import { createManualVolumeMeasurementHandler } from './ManualVolumeMeasurementHandler';
import { SegmentMetadata } from './SegmentMetadata';

export class SegmentationMetadata {
  constructor({
    segmentationMetadata,
    segmentList,
    inferenceData,
    segmentationErrors,
    segmentTemplateProvider = null,
  }) {
    this._init({
      segmentationMetadata,
      segmentList,
      segmentTemplateProvider,
      inferenceData,
      segmentationErrors,
    });
  }

  /**
   * We have to build the templates individually for each segment (e.g. in the zone they differ)
   */
  _buildSegmentTemplate({ segmentMetadata }) {
    if (this.segmentationType !== 'zone') return this.segmentTemplateProvider();

    return this.segmentTemplateProvider({
      isPeripheral:
        segmentMetadata.generalInfo.furtherInfo.zone === 'peripheral',
    });
  }

  _init({
    segmentationMetadata,
    segmentList,
    segmentTemplateProvider,
    inferenceData,
    segmentationErrors,
  }) {
    this.segmentationMetadata = segmentationMetadata;
    this.studyInstanceUID = segmentationMetadata.studyInstanceUID;
    this.seriesInstanceUID = segmentationMetadata.seriesInstanceUID;
    this.segmentationType = segmentationMetadata.segmentType;
    this.inferenceData = inferenceData;
    this.segmentTemplateProvider = segmentTemplateProvider;

    const dicomSegmentUIDs = segmentList.map(seg => seg.trackingUID);

    this.segments = segmentationMetadata.segments
      .filter(_segment => dicomSegmentUIDs.includes(_segment.trackingUID))
      .map((_segment, index) => {
        return new SegmentMetadata({
          segment: _segment,
          segmentCardTemplate: this._buildSegmentTemplate({
            segmentMetadata: _segment,
          }),
          inferenceData: {
            segment: this.getSegmentInferenceData({
              segmentMetadata: _segment,
            }),
            classMapping: inferenceData.classMapping,
          },
          Title:
            this.segmentationType === 'zone'
              ? () => ZoneTitle({ segmentMetadata: _segment })
              : ({ index }) => LesionTitle({ index }),
        });
      });

    const possibleErrors = segmentationErrors ? segmentationErrors.errors : [];

    this.editingInfo = new EditingInfo({
      editingInfo: segmentationMetadata.editingInfo,
      possibleSegmentationErrors: possibleErrors,
    });

    this.manualVolumeMeasurementHandler = createManualVolumeMeasurementHandler({
      segmentationMetadata: this.segmentationMetadata,
    });
  }

  getInferenceModelInfo() {
    if (!this.inferenceData || !this.inferenceData.modelInfo) return;

    return this.inferenceData.modelInfo;
  }

  getSegmentInferenceData({ segmentMetadata }) {
    if (!this.inferenceData || !this.inferenceData.segments) return;

    return this.inferenceData.segments.find(
      seg => seg.trackingUID === segmentMetadata.trackingUID
    );
  }

  hasSegment({ trackingUID }) {
    for (var _segmentID in this.segments) {
      const segment = this.segments[_segmentID];
      if (segment.getTrackingUID() === trackingUID) return true;
    }

    return false;
  }

  getSegment({ trackingUID }) {
    const segment = this.segments.find(
      _segment => _segment.getTrackingUID() === trackingUID
    );

    if (!segment) {
      throw new Error('No segment with given trackingUID was found');
    }

    return segment;
  }

  createSegment({ trackingUID }) {
    const newSegment = new SegmentMetadata({
      segment: { trackingUID },
      segmentCardTemplate: this.segmentTemplateProvider(),
      Title: ({ index }) => LesionTitle({ index }),
    });

    newSegment.origin = 'Manual';
    newSegment.voxelvolume = 0;

    this.segments.push(newSegment);
  }

  deleteSegment({ trackingUID }) {
    this.segments = this.segments.filter(
      segment => segment.getTrackingUID() !== trackingUID
    );
  }

  getTrackingUIDs() {
    return this.segments.map(seg => seg.getTrackingUID());
  }

  getEditingInfo() {
    return this.editingInfo;
  }

  getSegmentDetail({ trackingUID, option }) {
    const segment = this.getSegment({ trackingUID });
    return segment.getDetailInfo({ option });
  }

  updateSegmentDetail({ trackingUID, option, value }) {
    const segment = this.getSegment({ trackingUID });

    segment.setDetailInfo({ option, value });
  }

  segmentsComplete() {
    return this.segments.reduce((acc, curr) => {
      if (!curr.neccessaryInformationProvided()) {
        acc = false;
      }
      return acc;
    }, true);
  }

  confidenceLevelWasSet() {
    return this.editingInfo.confidenceLevelWasSet();
  }

  toJson() {
    let segments = this.segments.map(seg => ({ ...seg.toJson() }));

    if (this.manualVolumeMeasurementHandler.hasManualVolumeMeasurements()) {
      segments = segments.map(_segment => {
        const measurement = this.manualVolumeMeasurementHandler.getMeasurementsByTrackingUID(
          { trackingUID: _segment.trackingUID }
        );

        return {
          ..._segment,
          generalInfo: {
            ..._segment.generalInfo,
            manualVolumeMeasures: measurement,
          },
        };
      });
    }

    if (this.manualVolumeMeasurementHandler.hasPreliminaryVolumes()) {
      segments = segments.map(_segment => {
        const measurement = this.manualVolumeMeasurementHandler.getPreliminaryVolumeByTrackingUID(
          { trackingUID: _segment.trackingUID }
        );

        return {
          ..._segment,
          generalInfo: {
            ..._segment.generalInfo,
            preliminaryVolume: measurement,
          },
        };
      });
    }

    return {
      studyInstanceUID: this.studyInstanceUID,
      seriesInstanceUID: this.seriesInstanceUID,
      editingInfo: this.editingInfo.toJson(),
      segments: segments,
    };
  }
}
