import React from 'react';

import { BladderComponent } from '../../components/AltaSegmentationPanel/components/optionalCards/BladderComponent';
import { CombinedVolumeCard } from '../../components/AltaSegmentationPanel/components/optionalCards/CombinedVolumeCard';
import { PartialVolumeFormCard } from '../../components/AltaSegmentationPanel/components/optionalCards/PartialVolumeFormCard';
import {
  ClassDistributionTable,
  LesionTitleExtension,
  LesionVolumeField,
  OriginInformation,
  ZoneVolumeField,
} from '../../components/AltaSegmentationPanel/components/SegmentCard/components';
import {
  SEGMENTATION_ORIGIN,
  SEGMENTATION_TYPE,
} from '../../components/constants';
import { CONFIDENCE_LEVEL } from '../../context/constants/ConfidenceLevel';
import {
  LesionDetailCardTemplate,
  PeripheralZoneDetailCardTemplate,
  TransitionZoneDetailCardTemplate,
} from './SegmentationDetailTemplates';
import { ProstateVolumeFromPreExam } from '../../components/AltaSegmentationPanel/components/optionalCards/ProstateVolumeFromLastExam';

const LesionConfigProvider = ({ segmentationOrigin }) => {
  const getInteractionConfig = () => {
    switch (segmentationOrigin) {
      case SEGMENTATION_ORIGIN.SEMIAUTOMATIC:
      case SEGMENTATION_ORIGIN.MANUAL:
        return {
          enableAdd: true,
          enableGlobalEdit: true,
          enableMergeSegment: true,
          enableDeleteSegment: true,
          enableDeleteSegmentation: true,
          fullyExpanded: false,
          segmentsExpandable: true,
          enableSave: true,
        };
      case SEGMENTATION_ORIGIN.AUTOMATIC:
      default:
        return {
          enableAdd: false,
          enableGlobalEdit: false,
          enableMergeSegment: true,
          enableDeleteSegment: false,
          enableDeleteSegmentation: true,
          fullyExpanded: false,
          segmentsExpandable: false,
          enableSave: false,
        };
    }
  };

  const getOptionalCardProviders = () => {
    return segmentationOrigin !== SEGMENTATION_ORIGIN.AUTOMATIC
      ? {
          preHeader: [],
          postHeader: [],
        }
      : {
          preHeader: [],
          postHeader: [],
        };
  };

  return {
    ...getInteractionConfig(),
    optionalCardProviders: getOptionalCardProviders(),
    segmentDetailCardCompnents: {
      title: 'Lesion information',
      detailTemplateProvider: () => LesionDetailCardTemplate,
    },
    onSaveCallback: ({ segmentationMetadata }) => {
      // each segment contains the neccesarry information
      const saveAllowed = segmentationMetadata.segmentsComplete();

      segmentationMetadata.editingInfo.confidenceLevel =
        CONFIDENCE_LEVEL.PROCESSED;

      return saveAllowed;
    },
    missingSegmentationDialog: {
      showMarkAsHealty: true,
      showAISegmentation: false,
    },
    initSegmentationExtension: () => {},
    segmentCardComponents: ({ segmentData }) => {
      return {
        title: (
          <LesionTitleExtension
            diagnosticClass={segmentData.getDiagnosticClass()}
          />
        ),
        right: <LesionVolumeField volume={segmentData.getVoxelVolume()} />,
        left: (
          <>
            <OriginInformation data={segmentData.getOrigin()} />
            <ClassDistributionTable
              data={segmentData.getClassDistributionTableData()}
            />
          </>
        ),
      };
    },
  };
};

const ZoneConfigProvider = ({ segmentationOrigin }) => {
  const getInteractionConfig = () => {
    switch (segmentationOrigin) {
      case SEGMENTATION_ORIGIN.SEMIAUTOMATIC:
      case SEGMENTATION_ORIGIN.MANUAL:
        return {
          enableAdd: false,
          enableGlobalEdit: true,
          enableMergeSegment: false,
          enableDeleteSegment: true,
          enableDeleteSegmentation: true,
          fullyExpanded: false,
          segmentsExpandable: false,
          enableSave: true,
        };
      case SEGMENTATION_ORIGIN.AUTOMATIC:
      default:
        return {
          enableAdd: false,
          enableGlobalEdit: false,
          enableMergeSegment: false,
          enableDeleteSegment: false,
          enableDeleteSegmentation: false,
          fullyExpanded: false,
          segmentsExpandable: false,
          enableSave: false,
        };
    }
  };

  const getOptionalCardProviders = () => {
    return segmentationOrigin !== SEGMENTATION_ORIGIN.AUTOMATIC
      ? {
          preHeader: [],
          postHeader: [
            () => <CombinedVolumeCard key={'CombinedVolumeCard'} />,
            () => <ProstateVolumeFromPreExam key={'PreExamVolumeFormCard'} />,
            () => <PartialVolumeFormCard key={'PartialVolumeFormCard'} />,
          ],
        }
      : {
          preHeader: [],
          postHeader: [],
        };
  };

  return {
    ...getInteractionConfig(),
    optionalCardProviders: getOptionalCardProviders(),
    segmentDetailCardCompnents: {
      title: 'Zone information',
      detailTemplateProvider: ({ isPeripheral }) => {
        return isPeripheral
          ? PeripheralZoneDetailCardTemplate
          : TransitionZoneDetailCardTemplate;
      },
    },
    onSaveCallback: ({ segmentationMetadata }) => {
      segmentationMetadata.editingInfo.confidenceLevel =
        CONFIDENCE_LEVEL.PROCESSED;
      return true;
    },
    missingSegmentationDialog: {
      showMarkAsHealty: false,
      showAISegmentation: false,
    },
    initSegmentationExtension: () => {},
    segmentCardComponents: ({ segmentData }) => ({
      title: null,
      right: (
        <ZoneVolumeField
          volume={segmentData.getVoxelVolume()}
          trackingUID={segmentData.trackingUID}
        />
      ),
      left: <OriginInformation data={segmentData.getOrigin()} />,
    }),
  };
};

export const getSegmentationTypeConfig = ({
  segmentationType,
  segmentationOrigin,
}) => {
  switch (segmentationType) {
    case SEGMENTATION_TYPE.LESION:
      return LesionConfigProvider({ segmentationOrigin });
    case SEGMENTATION_TYPE.ZONE:
      return ZoneConfigProvider({ segmentationOrigin });
  }
};

export const StudyContextProvider = () => {
  return [];
};

export const AdditionalSegmentationPanelComponents = [
  () => <BladderComponent key="bladder-opt" />,
];
