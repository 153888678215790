import React from 'react';
import ConnectedSequenceSelectionTable from './components/ConnectedSequenceSelectionTable';

export default {
  /**
   * Only required property. Should be a unique value across all extensions.
   */
  id: 'sequence-selection',
  get version() {
    return window.version;
  },

  preRegistration() {},

  getPanelModule({ servicesManager }) {
    const ExtendedConnectedSequenceSelectionTable = () => (
      <ConnectedSequenceSelectionTable />
    );

    return {
      menuOptions: [
        {
          icon: 'list',
          label: 'Sequenzauswahl',
          target: 'sequence-selection',
        },
      ],
      components: [
        {
          id: 'sequence-selection',
          component: ExtendedConnectedSequenceSelectionTable,
        },
      ],
      defaultContext: ['VIEWER'],
    };
  },
};
