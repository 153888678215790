import React from 'react';

import cornerstoneTools from 'cornerstone-tools';
import { useReportData } from '../../../report/reportContext';
import './LesionLocationUI.styl';
import { ProstateSVGCanvas } from './ProstateSVGCanvas';

import {
  CORONAL_MAPPING,
  PROSTATE_AREAS_APEX,
  PROSTATE_AREAS_BASE,
  PROSTATE_AREAS_MID,
  PROSTATE_AREAS_SV,
} from '../../../components/AltaSegmentationPanel/components/SegmentDetailCard/components/ProstateZoneSelector/config';

const LESION_SORT_RELEVANCE = [
  'Confirmed prostate cancer',
  'Urgent suspicion of prostate cancer',
  'Suspected prostate cancer',
  'Findings in need of clarification',
  'Findings in need of monitoring',
  'Cronic prostatitis',
  'Suspected chronic prostatitis',
  'Suspected expired, chronic prostatitis',
];

const prostateLesionSegmentSort = (seg1, seg2) => {
  if (seg1.detailInfo.location.isWholeGland) {
    return -1;
  }

  const indexA = LESION_SORT_RELEVANCE.indexOf(seg1.detailInfo.assessment);
  const indexB = LESION_SORT_RELEVANCE.indexOf(seg2.detailInfo.assessment);

  return indexA - indexB;
};

const extractLesionData = ({ segmentations }) => {
  const { state } = cornerstoneTools.getModule('segmentation');
  const colors = state.colorLutTables[0];

  const lesionSegmentation = segmentations.find(
    seg => seg.segmentType === 'lesion'
  );

  const sortedSegments = lesionSegmentation.segments
    .map((seg, index) => ({ segmentIndex: index + 1, ...seg }))
    .sort(prostateLesionSegmentSort)
    .reverse();

  const segments = sortedSegments.reduce(
    (acc, curr) => {
      const {
        segmentIndex,
        detailInfo: { location },
      } = curr;
      const color = colors[segmentIndex];

      if (location.isWholeGland) {
        acc.base = PROSTATE_AREAS_BASE.map(area => ({
          id: area.id,
          color,
          segmentIndex,
        }));
        acc.sv = PROSTATE_AREAS_SV.map(area => ({
          id: area.id,
          color,
          segmentIndex,
        }));
        acc.mid = PROSTATE_AREAS_MID.map(area => ({
          id: area.id,
          color,
          segmentIndex,
        }));
        acc.apex = PROSTATE_AREAS_APEX.map(area => ({
          id: area.id,
          color,
          segmentIndex,
        }));
        return acc;
      }

      const update = (area, id, color) => {
        const hasEntry = area.find(a => a.id === id);
        if (hasEntry) {
          area.splice(area.indexOf(hasEntry), 1);
        }
        area.push({ id, color, segmentIndex });
      };

      location.base.forEach(item => update(acc.base, item, color));
      location.sv.forEach(item => update(acc.sv, item, color));
      location.mid.forEach(item => update(acc.mid, item, color));
      location.apex.forEach(item => update(acc.apex, item, color));

      return acc;
    },
    {
      base: [],
      sv: [],
      mid: [],
      apex: [],
      coronal: [],
    }
  );

  const { base, sv, mid, apex } = segments;
  const combinedZones = [...base, ...sv, ...mid, ...apex];

  segments.coronal = combinedZones.reduce((acc, curr) => {
    const { id, color, segmentIndex } = curr;

    CORONAL_MAPPING.filter(m => m.externalID === id).forEach(item =>
      acc.push({ id: item.coronalID, color, segmentIndex })
    );

    return acc;
  }, []);

  // remove duplicates
  segments.coronal = segments.coronal.reduce((acc, curr) => {
    const hasEntry = acc.find(a => a.id === curr.id);
    if (!hasEntry) {
      acc.push(curr);
      return acc;
    }
    const currPriority = sortedSegments.indexOf(
      sortedSegments.find(seg => seg.segmentIndex === curr.segmentIndex)
    );
    const hasEntryPriority = sortedSegments.indexOf(
      sortedSegments.find(seg => seg.segmentIndex === hasEntry.segmentIndex)
    );

    if (currPriority > hasEntryPriority) {
      acc.splice(acc.indexOf(hasEntry), 1);
      acc.push(curr);
    }

    return acc;
  }, []);

  return segments;
};

const LesionLocationUI = ({ setSvgString }) => {
  const {
    reportData: { segmentations },
  } = useReportData();

  const { base, sv, mid, apex, coronal } = extractLesionData({ segmentations });

  return (
    <div className="canvas-container">
      <ProstateSVGCanvas
        name={'seminal-vesicles'}
        width={800}
        height={300}
        areaFillTable={sv}
        setSvgString={newString =>
          setSvgString(os => ({ ...os, SV: newString }))
        }
      />
      <ProstateSVGCanvas
        name={'base'}
        width={800}
        height={600}
        areaFillTable={base}
        setSvgString={newString =>
          setSvgString(os => ({ ...os, base: newString }))
        }
      />
      <ProstateSVGCanvas
        name={'mid'}
        width={700}
        height={600}
        areaFillTable={mid}
        setSvgString={newString =>
          setSvgString(os => ({ ...os, mid: newString }))
        }
      />
      <ProstateSVGCanvas
        name={'apex'}
        width={800}
        height={500}
        areaFillTable={apex}
        setSvgString={newString =>
          setSvgString(os => ({ ...os, apex: newString }))
        }
      />
      <ProstateSVGCanvas
        name={'coronal'}
        width={800}
        height={1000}
        areaFillTable={coronal}
        setSvgString={newString =>
          setSvgString(os => ({ ...os, coronal: newString }))
        }
      />
    </div>
  );
};

export { LesionLocationUI };
