import React from 'react';

import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { v4 as uuidv4 } from 'uuid';

const styling = StyleSheet.create({
  fontSize: '8px',
  Table: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    margin: 0,
    Col: {
      display: 'flex',
      flex: 1,
      textAlign: 'center',
      flexDirection: 'column',
    },
    Footnotes: {
      fontWeight: 100,
      fontSize: '6px',
      marginTop: '5px',
    },
  },
});

const Row = ({ value, customComponent, style }) => {
  return (
    <View
      key={uuidv4()}
      style={{
        ...style,
        padding: '2px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }}
    >
      {customComponent ? customComponent : <Text key={uuidv4()}>{value}</Text>}
    </View>
  );
};

const TableColumn = ({ values, index, style }) => {
  const height = `${values.length * 14}px`;

  return (
    <View key={values[0]} style={{ ...styling.Table.Col, ...style, height }}>
      {values.map((val, index) => {
        const { value, customComponent } = val;

        if (index === 0) {
          // Header
          return Row({ value: val, style: { backgroundColor: '#F3F3F3' } });
        } else if (index % 2 == 0) {
          return Row({
            value,
            customComponent,
            style: { backgroundColor: '#F7F7F7' },
          });
        }
        return Row({ value, customComponent });
      })}
    </View>
  );
};

const ClosedTable = ({ content, footnotes = null, style = null }) => {
  const keys = content.reduce((acc, curr) => {
    const keysCurrent = curr.map(item => item.key);
    for (var _key in keysCurrent) {
      if (!acc.includes(keysCurrent[_key])) {
        acc.push(keysCurrent[_key]);
      }
    }
    return acc;
  }, []);

  const columns = keys.map(_key => {
    const colValues = content.map(_row => {
      const val = _row.find(_val => _val.key === _key);

      if (!val) {
        return { value: '-' };
      }
      return { value: val.value, customComponent: val.customComponent };
    });

    return [_key, ...colValues];
  });

  return (
    <View style={styling}>
      <View style={{ ...styling.Table, ...style }}>
        {columns.map((item, index) => {
          return TableColumn({ values: item, index });
        })}
      </View>
      {footnotes && (
        <View
          style={{
            ...styling.Table.Footnotes,
          }}
        >
          <Text>
            {footnotes.reduce((acc, curr, index) => {
              acc += `${curr}`;
              if (index !== footnotes.length - 1) {
                acc += '; ';
              }
              return acc;
            }, '*')}
          </Text>
        </View>
      )}
    </View>
  );
};

export { ClosedTable };
