import React from 'react';

import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useResources } from '../ResourceContextProvider';
import { PanelMainHeading } from './general';
import {
  calculateBladderVolume,
  calculateCombinedPirads,
  calculateProstateVolumeInML,
} from './utils';

import { useTranslation } from 'react-i18next';
import { ZONE_TO_TEXT_MAPPING } from '../../components/AltaSegmentationPanel/components/SegmentDetailCard/components/ProstateZoneSelector/config';

const styling = StyleSheet.create({
  fontSize: '8px',
  Text: {
    textIndent: '-10px',
    paddingLeft: '10px',
  },
});

const createTextForSide = sides => {
  const includesRight = sides.includes('right');
  const includesLeft = sides.includes('left');

  if (includesRight && includesLeft) {
    return 'rechten und linken';
  } else if (includesRight) {
    return 'rechten';
  } else if (includesLeft) {
    return 'linken';
  } else {
    throw new Error('Failed to infer side from location: ', sides);
  }
};

const createTextForRegions = regions => {
  const includesApikal = regions.includes('apex');
  const includesBasal = regions.includes('base');
  const includesMedial = regions.includes('mid');

  if (includesApikal && includesBasal) {
    return 'basal bis apikal';
  } else if (includesBasal && includesMedial) {
    return 'basal bis mitte';
  } else if (includesMedial && includesApikal) {
    return 'mitte bis apikal';
  } else if (includesApikal) {
    return 'apikal';
  } else if (includesBasal) {
    return 'basal';
  } else if (includesMedial) {
    return 'mittig';
  } else {
    throw new Error('Failed to infer region from location: ', regions);
  }
};

const createTextForZone = zones => {
  const includesTransitional = zones.includes('transitional');
  const includesPeripheral = zones.includes('peripher');
  const includesCentral = zones.includes('central');

  if (includesTransitional && includesPeripheral && includesCentral) {
    return 'peripheren, zentralen und transitionalen Zone';
  } else if (includesTransitional && includesPeripheral) {
    return 'peripheren und transitionalen Zone';
  } else if (includesPeripheral && includesCentral) {
    return 'peripheren und zentralen Zone';
  } else if (includesTransitional && includesCentral) {
    return 'zentralen und transitionalen Zone';
  } else if (includesCentral) {
    return 'zentralen Zone';
  } else if (includesPeripheral) {
    return 'peripheren Zone';
  } else if (includesTransitional) {
    return 'transitionalen Zone';
  } else {
    throw new Error('Failed to infer zone from location: ', zones);
  }
};

const extractDataFromZoneToText = (locations, type) => {
  return locations
    .map(loc => {
      try {
        return ZONE_TO_TEXT_MAPPING[loc][type];
      } catch (e) {
        console.error(`Can not find data for type ${type} in location ${loc}`);
        return null;
      }
    })
    .filter(Boolean);
};

const getSVLocation = sv => {
  const hasSVR = sv.includes('SVR');
  const hasSVL = sv.includes('SVRL');

  let location = null;
  if (hasSVR && hasSVL) {
    location = 'linken/rechten';
  } else if (hasSVL) {
    location = 'linken';
  } else if (hasSVR) {
    location = 'rechten';
  }
  return location;
};

const hasOnlySvMarked = location => {
  return (
    location.sv.length > 0 &&
    location.apex.length === 0 &&
    location.base.length === 0 &&
    location.mid.length === 0
  );
};

const mapLocationToSideZoneRegion = location => {
  const locations = [
    ...location.apex,
    ...location.base,
    ...location.mid,
    ...location.sv,
  ];
  const sides = extractDataFromZoneToText(locations, 'side');
  const regions = extractDataFromZoneToText(locations, 'region');
  const zones = extractDataFromZoneToText(locations, 'zone');

  // displayTexts
  const textForSide = createTextForSide(sides);
  const textForRegion = createTextForRegions(regions);
  const textForZone = createTextForZone(zones);

  return { side: textForSide, zone: textForZone, region: textForRegion };
};

const createAssessment = ({ t, segmentations, bladder }) => {
  const lesionSegmentation = segmentations.find(
    seg => seg.segmentType === 'lesion'
  );

  const zoneSegmentation = segmentations.find(
    seg => seg.segmentType === 'zone'
  );

  // lesions
  const assessments = lesionSegmentation.segments.map((seg, index) => {
    const { piradsT2W, piradsADC, piradsDCE, assessment } = seg.detailInfo;
    const combinedPirads = calculateCombinedPirads(
      piradsT2W,
      piradsADC,
      piradsDCE
    );
    const volume = seg.generalInfo.voxelVolume;

    if (hasOnlySvMarked(seg.detailInfo.location)) {
      return `${t(assessment)} mit beginnender Infiltration der ${getSVLocation(
        seg.detailInfo.location.sv
      )} Samenblase und einem Volumen von ${volume} ml. PIRADS ${combinedPirads}, V2.1 (Läsion ${index +
        1}).`;
    }

    const { side, zone, region } = mapLocationToSideZoneRegion(
      seg.detailInfo.location
    );

    const svLocation = getSVLocation(seg.detailInfo.location.sv);
    const svText = svLocation
      ? `, sowie beginnender Infiltration der ${svLocation} Samenblase`
      : '';

    return `${t(
      assessment
    )} der ${side} ${zone} ${region}${svText} mit einem Volumen von ${volume} ml. PIRADS ${combinedPirads}, V2.1 (Läsion ${index +
      1}).`;
  });

  // bladder
  if (bladder) {
    const bladderVolume = calculateBladderVolume({ bladder });
    if (bladderVolume > 100) {
      assessments.push(
        `Verdacht auf ${
          bladderVolume > 300 ? 'starke' : 'mäßige'
        } Blasenentleerungsstörungen mit einem Restharnvolumen von ${bladderVolume}.`
      );
    }
  }

  const { total, transitional } = calculateProstateVolumeInML({
    segmentation: zoneSegmentation,
  });

  if (total > 30) {
    const transitionalPortion = ((transitional / total) * 100).toFixed(0);
    const hyperplasiaText = `Noduläre Hyperplasie der Transitionszone mit einem Volumen von ${transitional} ml (${transitionalPortion}%) bei einem Gesamtprostatavolumen von ${total.toFixed(
      1
    )} ml`;
    assessments.push(hyperplasiaText);
  }

  return assessments;
};

const Paragraph = ({ index, data, style }) => {
  return (
    <Text
      key={data}
      style={{ ...styling.Text, ...style }}
    >{`${index}. ${data}`}</Text>
  );
};

const Assessment = () => {
  const [t] = useTranslation('SegmentationPanel');
  const {
    reportData: { segmentations },
    getComponentByType,
  } = useResources();

  const assessment = createAssessment({
    t,
    segmentations,
    bladder: getComponentByType({ type: 'bladder' }),
  });

  return (
    <View style={styling}>
      <PanelMainHeading heading={'5. Beurteilung'} />
      {assessment.map((data, index) => {
        return index === 0
          ? Paragraph({ index: index + 1, data })
          : Paragraph({ index: index + 1, data, style: { marginTop: '3px' } });
      })}
    </View>
  );
};

export { Assessment };
