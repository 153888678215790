import React from 'react';

import './SegmentCardComponents.styl';

export const LesionVolumeField = ({ volume }) => {
  return (
    <div className="segment-card-field">
      <div className="sc-body">
        <div className="volume">
          <span>{`${volume.toFixed(1)} ml`}</span>
        </div>
      </div>
    </div>
  );
};
