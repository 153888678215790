import React, { useContext, useEffect, useState } from 'react';

export const ReportContext = React.createContext(null);

const requestResources = async ({ studyInstanceUID }) => {
  const { apiInterface } = window.ohif.app;
  const reportPromise = apiInterface.getReportData({ studyInstanceUID });
  const segmentationPromise = apiInterface.getSegmentationList({
    studyInstanceUID,
    complete: true,
  });
  const studyPromise = apiInterface.getStudy({
    studyInstanceUID,
  });
  return Promise.all([reportPromise, segmentationPromise, studyPromise]);
};

export const ReportContextProvider = ({ children, studyInstanceUID }) => {
  const [reportData, setReportData] = useState(null);

  useEffect(() => {
    requestResources({ studyInstanceUID })
      .then(response => {
        setReportData({
          report: response[0],
          segmentations: response[1],
          study: response[2],
        });
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <ReportContext.Provider value={reportData}>
      {reportData && children}
    </ReportContext.Provider>
  );
};

export const useReportData = () => {
  return useContext(ReportContext);
};
