export const selectorTypeConfig = {
  main: [
    { title: 'T2 (TRA) Sequenz', modality: 't2w', orientation: 'tra' },
    { title: 'ADC Sequenz', modality: 'adc', orientation: 'tra' },
    { title: 'DWI Sequenz', modality: 'dwi', orientation: 'tra' },
  ],
  hidden: [
    { title: 'T2 (SAG) Sequenz', modality: 't2w', orientation: 'sag' },
    { title: 'T2 (COR) Sequenz', modality: 't2w', orientation: 'cor' },
    { title: 'ADC (SAG) Sequenz', modality: 'adc', orientation: 'sag' },
    { title: 'ADC (COR) Sequenz', modality: 'adc', orientation: 'cor' },
    { title: 'DWI (SAG) Sequenz', modality: 'dwi', orientation: 'sag' },
    { title: 'DWI (COR) Sequenz', modality: 'dwi', orientation: 'cor' },
  ],
};
