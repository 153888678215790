import classNames from 'classnames';
import React from 'react';
import { v4 } from 'uuid';

const infoPanelHeader = title => {
  return <div className="text-header">{title}</div>;
};

const infoPanelTextBody = (body, align = 'column') => {
  return Array.isArray(body) ? (
    body.map((item, index) => (
      <div
        className={classNames('text-body', { row: align === 'row' })}
        key={`text-body-${v4()}`}
      >
        {index === body.length - 1 ? item : `${item},`}
      </div>
    ))
  ) : (
    <div className="text-body">{body}</div>
  );
};

const getTransAndPeripheralZone = ({ segmentation }) => {
  const transZone = segmentation.segments.find(
    seg => seg.generalInfo.furtherInfo.zone === 'transitional'
  );
  const peripheralZone = segmentation.segments.find(
    seg => seg.generalInfo.furtherInfo.zone === 'peripheral'
  );

  return {
    transitional: transZone,
    peripheral: peripheralZone,
  };
};

const calculateProstateVolumeInML = ({ segmentation }) => {
  // check whether we have manual measurements
  const hasManualMeasurements = segmentation.segments.some(
    seg => seg.generalInfo.furtherInfo.manualMeasurements
  );

  const { transitional, peripheral } = getTransAndPeripheralZone({
    segmentation,
  });

  let transVolume, periVolume;

  if (hasManualMeasurements) {
    const transMeasures = transitional.generalInfo.manualVolumeMeasures;
    // Note: The peripheral zone stores the measures of the whole prostate
    const combinedMeasures = peripheral.generalInfo.manualVolumeMeasures;

    transVolume = (1 / 2) * transMeasures.c * transMeasures.a * transMeasures.m;
    periVolume =
      (1 / 2) * combinedMeasures.c * combinedMeasures.a * combinedMeasures.m -
      transVolume;
  } else {
    transVolume = transitional.generalInfo.voxelVolume;
    periVolume = peripheral.generalInfo.voxelVolume;
  }

  return {
    total: transVolume + periVolume,
    peripheral: periVolume,
    transitional: transVolume,
  };
};

export { calculateProstateVolumeInML, infoPanelHeader, infoPanelTextBody };
