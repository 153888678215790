import React from 'react';
import { useSequenceSelection } from './useSequenceSelection';

export const SequenceSelectionContext = React.createContext(null);

export const SequenceSelectionProvider = ({ children, studyData }) => {
  const sequenceSelection = useSequenceSelection(studyData);

  return (
    <SequenceSelectionContext.Provider value={sequenceSelection}>
      {children}
    </SequenceSelectionContext.Provider>
  );
};
