import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@ohif/ui';
import './CustomOptionPicker.styl';
import { flatten, compare } from './utils';

const OptionTag = ({ label, selected, handleOnClick, onDropSelection }) => {
  const [t] = useTranslation('StudyList');

  const handleOnClickCallback = e => {
    e.stopPropagation();
    handleOnClick();
  };

  return (
    <div
      className={`option-tag  ${selected ? 'selected' : ''}`}
      onClick={handleOnClickCallback}
    >
      <span>{t(label)}</span>
      {selected && (
        <Icon
          className="icon"
          name="times"
          width="10px"
          height="10px"
          onClick={onDropSelection}
        />
      )}
    </div>
  );
};

const OptionTagArea = ({ reference, optionTags, onCollapse }) => {
  return (
    <div className="option-tag-container" ref={reference}>
      <div className="option-tag-area">{optionTags}</div>

      <div className="option-tag-area-footer">
        <Icon
          className="icon"
          name="check"
          width="10px"
          height="10px"
          onClick={onCollapse}
        />
      </div>
    </div>
  );
};

const CustomOptionPicker = ({
  selectedValues,
  optionLayout,
  onOptionsSelect,
}) => {
  const { options, optionGroups, singletons, allowMultiSelect } = optionLayout;
  const [selectedOptions, setSelectedOptions] = useState(selectedValues);
  const [expanded, setExpanded] = useState(false);
  const detailRef = useRef(null);

  const handleClickOutside = event => {
    if (detailRef.current && !detailRef.current.contains(event.target)) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    if (expanded) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [expanded]);

  useEffect(() => {
    // Hack: If multiselect is not allowed we assume a single element list and hence return the first element
    allowMultiSelect
      ? onOptionsSelect(selectedOptions)
      : onOptionsSelect(selectedOptions.length === 0 ? '' : selectedOptions);
  }, [selectedOptions]);

  const handleOptionTagOnClick = opt => {
    if (selectedOptions.includes(opt)) {
      return;
    }
    // replace singleton
    if (singletons.includes(selectedOptions[0])) {
      setSelectedOptions([opt]);
      return;
    }
    // remove other group members
    const assignedGroups = flatten(
      optionGroups.filter(group => group.includes(opt))
    );
    if (assignedGroups.length) {
      const cleanSelectedOptions = [...selectedOptions].filter(
        opt => !assignedGroups.includes(opt)
      );
      setSelectedOptions([...cleanSelectedOptions, opt]);
      return;
    }

    if (singletons.includes(opt) || selectedOptions.length === 0) {
      setSelectedOptions([opt]);
      return;
    }

    setSelectedOptions([...selectedOptions, opt]);
  };

  const dropSelection = selectionToDrop => {
    setSelectedOptions(
      selectedOptions.filter(option => option !== selectionToDrop)
    );
  };

  const generateOptionTags = () => {
    const optionTags = options.map(opt => {
      return (
        <OptionTag
          key={opt}
          label={opt}
          selected={selectedOptions.includes(opt)}
          onDropSelection={() => dropSelection(opt)}
          handleOnClick={() => handleOptionTagOnClick(opt)}
        ></OptionTag>
      );
    });

    return optionTags.sort(compare);
  };

  const generatePreviewTag = () => {
    return (
      <OptionTag
        label={selectedOptions[0]}
        selected={true}
        onDropSelection={() => dropSelection(selectedOptions[0])}
        handleOnClick={() => {}}
      />
    );
  };

  return (
    <div className="option-picker-area" onClick={() => setExpanded(!expanded)}>
      {!expanded && (
        <>
          <div className="selected-options-area">
            {selectedOptions.length !== 0 ? (
              generatePreviewTag()
            ) : (
              <span style={{ opacity: 0.5 }}>No active filters</span>
            )}
            {selectedOptions.length > 1 && (
              <span className="overshoot">{`+${selectedOptions.length -
                1} more `}</span>
            )}
          </div>

          <Icon
            className="icon"
            name="arrow-down"
            width="10px"
            height="10px"
            style={{ marginRight: '5px' }}
            onClick={() => setExpanded(!expanded)}
          />
        </>
      )}

      {expanded && (
        <OptionTagArea
          reference={detailRef}
          optionTags={generateOptionTags()}
          onCollapse={() => {
            setExpanded(false);
          }}
        />
      )}
    </div>
  );
};

export { CustomOptionPicker };
