import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const styling = StyleSheet.create({
  fontSize: '9px',
  flexDirection: 'row',
  flexGrow: 2,
  marginBottom: '5px',
  Col: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    Heading: {
      backgroundColor: '#EDEDED',
      textAlign: 'center',
      padding: '2px',
    },
    Content: {
      textAlign: 'center',
      fontSize: '8px',
    },
  },
});

const TableCol = ({ key, value, customComponent, style }) => {
  return (
    <View key={`${key}-${uuidv4()}`} style={{ ...styling.Col, ...style }}>
      <Text style={styling.Col.Heading}>{key}</Text>
      {customComponent ? (
        customComponent
      ) : (
        <Text style={styling.Col.Content}>{value}</Text>
      )}
    </View>
  );
};

const OpenTable = ({ content, style }) => {
  return (
    <View style={{ ...styling, ...style }}>
      {content.map((item, index) => {
        return index === 0
          ? TableCol({ key: item.key, value: item.value })
          : TableCol({
              ...item,
              style: { marginLeft: '3px' },
            });
      })}
    </View>
  );
};

export { OpenTable };
