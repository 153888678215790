const defaultTemplateBuilder = t => {
  return {
    largeTableMeta: [
      {
        displayText: t('PatientName'),
        fieldName: 'PatientName',
        inputType: 'text',
        size: 330,
      },
      {
        displayText: t('MRN'),
        fieldName: 'PatientID',
        inputType: 'text',
        size: 378,
      },
      {
        displayText: t('AccessionNumber'),
        fieldName: 'AccessionNumber',
        inputType: 'text',
        size: 180,
      },
      {
        displayText: t('StudyDate'),
        fieldName: 'StudyDate',
        inputType: 'date-range',
        size: 300,
      },
      {
        displayText: t('Modality'),
        fieldName: 'modalities',
        inputType: 'text',
        size: 114,
      },
      {
        displayText: t('StudyDescription'),
        fieldName: 'StudyDescription',
        inputType: 'text',
        size: 335,
      },
    ],

    mediumTableMeta: [
      {
        displayText: `${t('PatientName')} / ${t('MRN')}`,
        fieldName: 'patientNameOrId',
        inputType: 'text',
        size: 250,
      },
      {
        displayText: t('Description'),
        fieldName: 'accessionOrModalityOrDescription',
        inputType: 'text',
        size: 350,
      },
      {
        displayText: t('StudyDate'),
        fieldName: 'StudyDate',
        inputType: 'date-range',
        size: 300,
      },
    ],

    smallTableMeta: [
      {
        displayText: t('Search'),
        fieldName: 'allFields',
        inputType: 'text',
        size: 100,
      },
    ],
  };
};

export const altaTemplateBuilder = t => {
  return {
    largeTableMeta: [
      {
        displayText: t('PatientName'),
        fieldName: 'PatientName',
        inputType: 'text',
        size: 330,
      },
      {
        displayText: t('MRN'),
        fieldName: 'PatientID',
        inputType: 'text',
        size: 378,
      },
      {
        displayText: t('StudyDescription'),
        fieldName: 'StudyDescription',
        inputType: 'text',
        size: 335,
      },
      {
        displayText: t('BirthDate'),
        fieldName: 'PatientBirthDate',
        inputType: 'date-range',
        size: 300,
      },
      {
        displayText: t('StudyDate'),
        fieldName: 'StudyDate',
        inputType: 'date-range',
        size: 300,
      },
    ],

    mediumTableMeta: [
      {
        displayText: `${t('PatientName')} / ${t('MRN')}`,
        fieldName: 'patientNameOrId',
        inputType: 'text',
        size: 250,
      },
      {
        displayText: t('Description'),
        fieldName: 'accessionOrModalityOrDescription',
        inputType: 'text',
        size: 350,
      },
      {
        displayText: t('StudyDate'),
        fieldName: 'StudyDate',
        inputType: 'date-range',
        size: 300,
      },
    ],

    smallTableMeta: [
      {
        displayText: t('Search'),
        fieldName: 'allFields',
        inputType: 'text',
        size: 100,
      },
    ],
  };
};

export const studentTemplateBuilder = t => {
  return {
    largeTableMeta: [
      {
        displayText: t('PatientName'),
        fieldName: 'PatientName',
        inputType: 'text',
        size: 200,
      },
      {
        displayText: t('MRN'),
        fieldName: 'PatientID',
        inputType: 'text',
        size: 250,
      },
      {
        displayText: t('Lesion'),
        fieldName: 'LesionState',
        inputType: 'option-picker',
        optionLayout: 'SegmentProcessingState',
        size: 220,
      },
      {
        displayText: t('Zone'),
        fieldName: 'ZoneState',
        inputType: 'option-picker',
        optionLayout: 'SegmentProcessingState',
        size: 220,
      },
      {
        displayText: t('AssignedUser'),
        fieldName: 'AssignedUser',
        inputType: 'text',
        size: 200,
      },
      {
        displayText: t('StudyDate'),
        fieldName: 'StudyDate',
        inputType: 'date-range',
        size: 300,
      },
    ],

    mediumTableMeta: [
      {
        displayText: `${t('PatientName')} / ${t('MRN')}`,
        fieldName: 'patientNameOrId',
        inputType: 'text',
        size: 150,
      },
      {
        displayText: t('Lesion'),
        fieldName: 'LesionState',
        inputType: 'option-picker',
        optionLayout: 'SegmentProcessingState',
        size: 220,
      },
      {
        displayText: t('Zone'),
        fieldName: 'ZoneState',
        inputType: 'option-picker',
        optionLayout: 'SegmentProcessingState',
        size: 220,
      },
      {
        displayText: t('AssignedUser'),
        fieldName: 'AssignedUser',
        inputType: 'text',
        size: 200,
      },
    ],

    smallTableMeta: [
      {
        displayText: t('Search'),
        fieldName: 'allFields',
        inputType: 'text',
        size: 100,
      },
    ],
  };
};

const selectStudyListTemplate = (template, translator) => {
  switch (template) {
    case 'alta': {
      return altaTemplateBuilder(translator);
    }
    case 'student ': {
      return altaTemplateBuilder(translator);
    }
    default: {
      return defaultTemplateBuilder(translator);
    }
  }
};

export { selectStudyListTemplate };
