import moment from 'moment';
import React from 'react';

import './StudySummary.styl';

const formatDateString = dateString => {
  const date = moment(dateString, 'YYYYMMDD').toDate();
  return date.toLocaleDateString('de-DE', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

const StudySummary = props => {
  const { studyMetadata } = props;
  return (
    <div className="study-info-summary">
      <div className="sis-row">
        <span>
          <span>{studyMetadata.PatientName}</span>
          <span
            style={{ marginLeft: '10px' }}
          >{`(${studyMetadata.PatientID})`}</span>
        </span>
        <span>{formatDateString(studyMetadata.PatientBirthdate)}</span>
      </div>
      <div className="sis-row" style={{ opacity: '0.5' }}>
        <span>{studyMetadata.StudyDescription}</span>
        <span>{formatDateString(studyMetadata.StudyDate)}</span>
      </div>
    </div>
  );
};

export { StudySummary };
