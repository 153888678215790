import classnames from 'classnames';
import React, { useState, useRef, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Icon } from '@ohif/ui';

import {
  useMetadata,
  useSegment,
  useSegmentationConfig,
} from '../../../../context/SegmentationPanelContext';
import { SegmentDetailCard } from '../SegmentDetailCard/SegmentDetailCard';
import './SegmentCard.styl';
import { SegmentMergeSection } from './components/SegmentMergeSection';
import { useMergeSegment } from '../../../../context/hooks/useMergeSement';

export const SegmentCard = ({ editModeActive, segmentMeta }) => {
  const [t] = useTranslation('SegmentationPanel');

  const {
    toggleSegmentVisibility,
    centerSegment,
    isSegmentActive,
    setActiveSegment,
    onDeleteSegment,
  } = useSegment();

  const { segmentMergeModeActive } = useMergeSegment();

  const { getSegmentMetadata } = useMetadata();
  const { segmentationConfig, isT2 } = useSegmentationConfig();

  const [expanded, setExpanded] = useState(false);
  const segmentCardRef = useRef(null);

  useEffect(() => {
    document.addEventListener('newSegment', displayNewTag);
    return () => {
      document.removeEventListener('newSegment', displayNewTag);
    };
  }, []);

  const segmentSelected = isSegmentActive({
    segmentIndex: segmentMeta.segmentNumber,
  });
  const segmentData = getSegmentMetadata({
    trackingUID: segmentMeta.trackingUID,
  });

  const getAdditionalUIMetadata = () =>
    segmentationConfig.segmentCardComponents({
      segmentData,
    });
  const additionalUIMetadata = getAdditionalUIMetadata();

  const displayNewTag = e => {
    if (e.detail.trackingUID !== segmentMeta.trackingUID) return;

    segmentCardRef.current.classList.add('new-segment');

    setTimeout(() => {
      segmentCardRef.current.classList.add('fade-out-keyframe');
    }, 2000);

    setTimeout(() => {
      segmentCardRef.current.classList.remove('new-segment');
      segmentCardRef.current.classList.remove('fade-out-keyframe');
    }, 5000);
  };

  const expandAllowed =
    !segmentationConfig.fullyExpanded && segmentationConfig.segmentsExpandable;

  return (
    <div
      className={classnames('segment-card', {
        selected: editModeActive && segmentSelected,
        editMode: editModeActive,
      })}
      data-before-content={t('New')}
      onClick={() => {
        if (editModeActive) {
          setActiveSegment({ segmentIndex: segmentMeta.segmentNumber });
        }
      }}
      ref={segmentCardRef}
    >
      <div className="segment-overview-card-wrapper">
        {segmentMergeModeActive && (
          <SegmentMergeSection segmentMeta={segmentMeta} />
        )}

        <div className="segment-overview-card">
          <div className="segment-overview-card-header">
            <div className="soch left">
              <div className="title small">
                <span>
                  {segmentData.Title({
                    index: segmentMeta.segmentIndex,
                  })}
                </span>
                {additionalUIMetadata.title}
              </div>
            </div>
            <div style={{ minWidth: '120px' }} className="soch right">
              <div className="icon-group">
                <Icon
                  className={classnames('icon eye-icon', {
                    inactive: !isT2,
                  })}
                  name={segmentMeta.isVisible ? 'eye' : 'eye-closed'}
                  style={{ color: `rgb(${segmentMeta.color.join(',')})` }}
                  onClick={e => {
                    e.stopPropagation();
                    toggleSegmentVisibility({
                      targetSegmentNumber: segmentMeta.segmentNumber,
                    });
                  }}
                />
                <Icon
                  className={classnames('icon icon-group-element', {
                    inactive: !isT2 || !segmentMeta.isVisible,
                  })}
                  name="center"
                  onClick={e => {
                    e.stopPropagation();
                    centerSegment({ segmentNumber: segmentMeta.segmentNumber });
                  }}
                />
                {segmentationConfig.enableDeleteSegment && (
                  <Icon
                    className={classnames('icon icon-group-element', {
                      inactive: !isT2,
                    })}
                    name="trash"
                    onClick={() => {
                      onDeleteSegment({
                        segmentIndex: segmentMeta.segmentIndex,
                      });
                    }}
                  ></Icon>
                )}
                {expandAllowed && (
                  <Icon
                    className="icon icon-group-element"
                    name={expanded ? 'arrow-up' : 'arrow-down'}
                    onClick={e => {
                      e.stopPropagation();
                      setExpanded(!expanded);
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="additional-information">
            <div className="further-information left">
              {additionalUIMetadata.left}
            </div>
            <div className="further-information right">
              {additionalUIMetadata.right}
            </div>
          </div>
        </div>
      </div>
      {(segmentationConfig.fullyExpanded || expanded) && (
        <SegmentDetailCard segmentMeta={segmentMeta} />
      )}
      <div className="segment-card-footer"></div>
    </div>
  );
};
