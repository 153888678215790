import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getContentFromUseMediaValue } from '../utils';

const DefaultTableRow = props => {
  const { isHighlighted, study, onClick: handleClick, displaySize } = props;

  const { t } = useTranslation('StudyList');
  const studyInfos = {
    AccessionNumber: study.AccessionNumber || '',
    modalities: study.modalities,
    PatientID: study.PatientID || '',
    PatientName: study.PatientName || '',
    StudyDate: study.StudyDate,
    StudyDescription: study.StudyDescription || '',
    StudyInstanceUID: study.StudyInstanceUID,
  };

  const largeRowTemplate = (
    <tr
      onClick={() => handleClick(studyInfos.StudyInstanceUID)}
      className={classNames({ active: isHighlighted })}
    >
      <td className={classNames({ 'empty-value': !studyInfos.PatientName })}>
        {studyInfos.PatientName || `(${t('Empty')})`}
      </td>
      <td>{studyInfos.PatientID}</td>
      <td>{studyInfos.AccessionNumber}</td>
      <td>{studyInfos.StudyDate}</td>
      <td className={classNames({ 'empty-value': !studyInfos.modalities })}>
        {studyInfos.modalities || `(${t('Empty')})`}
      </td>
      <td>{studyInfos.StudyDescription}</td>
    </tr>
  );

  const mediumRowTemplate = (
    <tr
      onClick={() => handleClick(studyInfos.StudyInstanceUID)}
      className={classNames({ active: isHighlighted })}
    >
      <td className={classNames({ 'empty-value': !studyInfos.PatientName })}>
        {studyInfos.PatientName || `(${t('Empty')})`}
        <div style={{ color: '#60656f' }}>{studyInfos.PatientID}</div>
      </td>
      <td>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* DESCRIPTION */}
          <div
            className="hide-xs"
            style={{
              whiteSpace: 'pre-wrap',
              flexGrow: 1,
            }}
          >
            {studyInfos.StudyDescription}
          </div>

          {/* MODALITY & ACCESSION */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '80px',
              width: '80px',
            }}
          >
            <div
              className={classNames({
                modalities: studyInfos.modalities,
                'empty-value': !studyInfos.modalities,
              })}
              aria-label={studyInfos.modalities}
              title={studyInfos.modalities}
            >
              {studyInfos.modalities || `(${t('Empty')})`}
            </div>
            <div
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
              aria-label={studyInfos.AccessionNumber}
              title={studyInfos.AccessionNumber}
            >
              {studyInfos.AccessionNumber}
            </div>
          </div>
        </div>
      </td>
      {/* DATE */}
      <td style={{ textAlign: 'center' }}>{studyInfos.StudyDate}</td>
    </tr>
  );

  const smallRowTemplate = (
    <tr
      onClick={() => handleClick(studyInfos.StudyInstanceUID)}
      className={classNames({ active: isHighlighted })}
    >
      <td style={{ position: 'relative', overflow: 'hidden' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* NAME AND ID */}
          <div
            className={classNames({ 'empty-value': !studyInfos.PatientName })}
            style={{ width: '150px', minWidth: '150px' }}
          >
            <div style={{ fontWeight: 500, paddingTop: '3px' }}>
              {studyInfos.PatientName || `(${t('Empty')})`}
            </div>
            <div style={{ color: '#60656f' }}>{studyInfos.PatientID}</div>
          </div>

          {/* DESCRIPTION */}
          <div
            className="hide-xs"
            style={{
              whiteSpace: 'pre-wrap',
              flexGrow: 1,
              paddingLeft: '35px',
            }}
          >
            {studyInfos.StudyDescription}
          </div>

          {/* MODALITY & DATE */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '80px',
              width: '80px',
            }}
          >
            <div
              className={classNames({
                modalities: studyInfos.modalities,
                'empty-value': !studyInfos.modalities,
              })}
              aria-label={studyInfos.modalities}
              title={studyInfos.modalities}
            >
              {studyInfos.modalities || `(${t('Empty')})`}
            </div>
            <div>{studyInfos.StudyDate}</div>
          </div>
        </div>
      </td>
    </tr>
  );

  const rowTemplate = getContentFromUseMediaValue(
    displaySize,
    {
      large: largeRowTemplate,
      medium: mediumRowTemplate,
      small: smallRowTemplate,
    },
    smallRowTemplate
  );

  return rowTemplate;
};

DefaultTableRow.propTypes = {
  isHighlighted: PropTypes.bool,
  studyInfos: PropTypes.array.isRequired,
  displaySize: PropTypes.string,
};

DefaultTableRow.defaultProps = {
  isHighlighted: false,
};

export { DefaultTableRow };
