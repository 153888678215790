import React, { useContext, useMemo, useState } from 'react';

import { LoadingSpinner } from '@ohif/ui';
import {
  SequenceSelectionContext,
  SequenceSelectionProvider,
} from '../utils/sequenceSelectionContext';

import { useTrackMessagingFrequency } from '../utils/useTrackMessagingFrequency';
import { SequenceSelector } from './SequenceSelector';
import { selectorTypeConfig } from './selectorTypeConfig';

import './SequenceSelectionTable.styl';

const SequenceSelectionTable = ({ studies, viewports, timepoints }) => {
  const { sendMessage } = useTrackMessagingFrequency();

  const studyData = useMemo(() => {
    if (!Object.keys(viewports.viewportSpecificData).length) {
      return null;
    }

    const studyInstanceUID = viewports.viewportSpecificData[0].StudyInstanceUID;
    const seriesList = studies.studyData[studyInstanceUID].series.filter(
      s => !s.isSegmentation
    );

    // check for duplicates and remind the user
    if (new Set(seriesList).size !== seriesList.length) {
      sendMessage({
        id: 1,
        type: 'warning',
        title: 'Dupliacte series descriptions',
        message:
          'We found duplicate series descriptions in your study. This will make the sequence selection ambigious!',
      });
    }

    return { studyInstanceUID, seriesList: [...new Set(seriesList)] };
  }, [studies, viewports]);

  return (
    <div className="sequenceTableWrapper">
      {studyData === null ? (
        <div className="loading-div">
          <LoadingSpinner />
        </div>
      ) : (
        <SequenceSelectionProvider studyData={studyData}>
          <SequenceList />
        </SequenceSelectionProvider>
      )}
    </div>
  );
};

const SequenceList = () => {
  const { sendMessage } = useTrackMessagingFrequency();
  const [extendedContainerID, setExtendedContainerID] = useState(null);
  const [showMoreExtended, setShowMoreExtended] = useState(false);
  const { saveSequenceMapping } = useContext(SequenceSelectionContext);

  const extendedDialog = () => {
    return (
      <div className="hidden-selectors">
        {selectorTypeConfig.hidden.map((config, index) => {
          index += selectorTypeConfig.main.length;
          return (
            <SequenceSelector
              {...config}
              key={`${config.modality}-${config.orientation}`}
              extended={extendedContainerID === index}
              setExtended={() =>
                extendedContainerID === index
                  ? setExtendedContainerID(null)
                  : setExtendedContainerID(index)
              }
            />
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div className="sequence-list">
        <div className="heading big">Sequenzenauswahl</div>
        {selectorTypeConfig.main.map((config, index) => {
          return (
            <SequenceSelector
              {...config}
              key={`${config.modality}-${config.orientation}`}
              extended={extendedContainerID === index}
              setExtended={() =>
                extendedContainerID === index
                  ? setExtendedContainerID(null)
                  : setExtendedContainerID(index)
              }
            />
          );
        })}
        <div
          className="show-more-toggle"
          onClick={() => {
            setShowMoreExtended(!showMoreExtended);
          }}
        >
          {showMoreExtended ? <span>Show Less</span> : <span>Show More</span>}
        </div>
        {showMoreExtended && extendedDialog()}
      </div>
      <button
        className="submit-btn"
        type="submit"
        onClick={() => {
          saveSequenceMapping()
            .then(res => {
              sendMessage({
                type: 'success',
                title: 'Success',
                message: 'Sequence Selection Update successful',
              });
            })
            .catch(e => {
              sendMessage({
                type: 'error',
                title: e.title,
                message: e.message,
              });
            });
        }}
      >
        Bestätigen
      </button>
    </>
  );
};

export default SequenceSelectionTable;
