import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Range } from '@ohif/ui';

import cornerstoneTools from 'cornerstone-tools';
import { enabledElement } from '../../../cornerstone/src/state';
import cornerstone from 'cornerstone-core';

import './BrushSizeScaler.styl';

const refreshCornerstoneViewports = () => {
  cornerstone.getEnabledElements().forEach(enabledElement => {
    if (enabledElement.image) {
      cornerstone.updateImage(enabledElement.element);
    }
  });
};

const SLIDER = {
  MIN: 1,
  MAX: 20,
  STEP: 1.0,
};

const ToolbarLabel = props => {
  const { label } = props;
  return <div className="toolbar-button-label">{label}</div>;
};

ToolbarLabel.propTypes = {
  label: PropTypes.string.isRequired,
};

const ToolbarSlider = props => {
  const { value, min, max, onChange } = props;

  return (
    <div className="toolbar-slider-container">
      <label htmlFor="toolbar-slider">{value}</label>
      <Range
        value={value}
        min={min}
        max={max}
        step={SLIDER.STEP}
        onChange={onChange}
        id="toolbar-slider"
      />
    </div>
  );
};

ToolbarSlider.propTypes = {
  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

const INITIAL_OPTION_INDEX = 0;

function BrushSizeScaler({
  parentContext,
  toolbarClickCallback,
  button,
  activeButtons,
  isActive,
  className,
  showButton,
}) {
  const { configuration, setters } = cornerstoneTools.getModule('segmentation');
  const csRadius = setters.radius;
  const [radius, setRadius] = useState(configuration.radius || 10);
  const { label } = button;

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboardInput);
    return () => {
      document.removeEventListener('keydown', handleKeyboardInput);
    };
  });

  useEffect(() => {
    configuration.radius = radius;
    refreshCornerstoneViewports();
  }, [radius]);

  const handleKeyboardInput = e => {
    switch (e.key) {
      case '-':
        if (radius <= SLIDER.MIN) return;
        setRadius(Number(radius) - 1);
        break;
      case '+':
        if (radius >= SLIDER.MAX) return;
        setRadius(Number(radius) + 1);
        break;
    }
  };

  return (
    <>
      {showButton && (
        <div className="brushSizeSlider">
          <div className="container">
            <ToolbarSlider
              value={radius}
              min={SLIDER.MIN}
              max={SLIDER.MAX}
              onChange={e => {
                setRadius(Number(e.target.value));
              }}
            />
            <ToolbarLabel key="toolbar-label" label={label} />
          </div>
        </div>
      )}
    </>
  );
}

BrushSizeScaler.propTypes = {
  parentContext: PropTypes.object.isRequired,
  toolbarClickCallback: PropTypes.func.isRequired,
  button: PropTypes.object.isRequired,
  activeButtons: PropTypes.array.isRequired,
  isActive: PropTypes.bool,
  className: PropTypes.string,
};

export { BrushSizeScaler };
