import { connect } from 'react-redux';
import SequenceSelectionTable from './SequenceSelectionTable';

const convertTimepointsToTableData = timepoints => {
  if (!timepoints || !timepoints.length) {
    return [];
  }

  return timepoints;
};

const mapStateToProps = state => {
  const { studies, viewports } = state;
  const { timepoints } = state.timepointManager;

  return {
    studies,
    viewports,
    timepoints: convertTimepointsToTableData(timepoints),
  };
};

const ConnectedSequenceSelectionTable = connect(mapStateToProps)(
  SequenceSelectionTable
);

export default ConnectedSequenceSelectionTable;
