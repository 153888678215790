import React from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getContentFromUseMediaValue } from '../utils';

const AltaTableRow = props => {
  const { isHighlighted, study, onClick: handleClick, displaySize } = props;

  const { t } = useTranslation('StudyList');

  const largeRowTemplate = (
    <tr
      onClick={() => handleClick(study.StudyInstanceUID)}
      className={classNames({ active: isHighlighted })}
    >
      <td className={classNames({ 'empty-value': !study.PatientName })}>
        {study.PatientName || `(${t('Empty')})`}
      </td>
      <td>{study.PatientID}</td>
      <td>{study.StudyDescription}</td>
      <td>{study.PatientBirthDate}</td>
      <td>{study.StudyDate}</td>
      {/* <td>{study.alreadyReviewed || 'False'}</td> */}
    </tr>
  );

  const mediumRowTemplate = (
    <tr
      onClick={() => handleClick(study.StudyInstanceUID)}
      className={classNames({ active: isHighlighted })}
    >
      <td className={classNames({ 'empty-value': !study.PatientName })}>
        {study.PatientName || `(${t('Empty')})`}
        <div style={{ color: '#60656f' }}>{study.PatientID}</div>
      </td>
      <td>{study.StudyDescription}</td>
      <td>{study.StudyDate}</td>
      {/* <td>{study.alreadyReviewed || 'False'}</td> */}
    </tr>
  );

  const smallRowTemplate = (
    <tr
      onClick={() => handleClick(study.StudyInstanceUID)}
      className={classNames({ active: isHighlighted })}
    >
      <td style={{ position: 'relative', overflow: 'hidden' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* NAME AND ID */}
          <div
            className={classNames({ 'empty-value': !study.PatientName })}
            style={{ width: '150px', minWidth: '150px', marginLeft: '20px' }}
          >
            <div style={{ fontWeight: 500, paddingTop: '3px' }}>
              {study.PatientName || `(${t('Empty')})`}
            </div>
            <div style={{ color: '#60656f' }}>{study.PatientID}</div>
          </div>

          {/* DATE */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '80px',
              width: '80px',
              marginRight: '20px',
            }}
          >
            <div>{study.StudyDate}</div>
          </div>
        </div>
      </td>
    </tr>
  );

  const rowTemplate = getContentFromUseMediaValue(
    displaySize,
    {
      large: largeRowTemplate,
      medium: mediumRowTemplate,
      small: smallRowTemplate,
    },
    smallRowTemplate
  );

  return rowTemplate;
};

AltaTableRow.propTypes = {
  isHighlighted: PropTypes.bool,
  study: PropTypes.object.isRequired,
  displaySize: PropTypes.string,
};

AltaTableRow.defaultProps = {
  isHighlighted: false,
};

export { AltaTableRow };
