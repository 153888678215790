import { Icon } from '@ohif/ui';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './Selector.styl';

export const IncrementalSelector = ({
  label,
  title,
  options,
  minValue,
  maxValue,
  defaultValue,
  selectedOption,
  onSelect,
}) => {
  const [t] = useTranslation('SegmentationPanel');
  const [value, setValue] = useState(
    selectedOption === undefined ? defaultValue : selectedOption
  );

  useEffect(() => {
    onSelect({ value });
  }, []);

  const optionsMapSet = options && options.length > 0;

  return (
    <div key={label} className="segment-panel-box selector incremental">
      <div className="selector-overview">
        <div className="selector-left">
          <div className="title">{t(title)}</div>
          <div style={{ fontSize: '18px' }} className="selected-option emph">
            {optionsMapSet ? options[value] : value}
          </div>
        </div>
        <div className="selector-right">
          <div className="icon-group">
            <Icon
              className={classnames('icon', {
                inactive: value >= maxValue,
              })}
              name="plus"
              onClick={e => {
                e.stopPropagation();
                setValue(() => Math.min(maxValue, value + 1));
                if (optionsMapSet) {
                  onSelect({ value: options[Math.min(maxValue, value + 1)] });
                }
                onSelect({ value: Math.min(maxValue, value + 1) });
              }}
            />
            <Icon
              className={classnames('icon', {
                inactive: value <= minValue,
              })}
              name="minus"
              onClick={e => {
                e.stopPropagation();
                setValue(() => Math.max(minValue, value - 1));
                if (optionsMapSet) {
                  onSelect({ value: options[Math.max(minValue, value - 1)] });
                }
                onSelect({ value: Math.max(minValue, value - 1) });
              }}
            />
          </div>
        </div>
      </div>
      <div className="selector-expanded"></div>
    </div>
  );
};
