import React from 'react';

import './LoadingSpinnerNotificationCard.styl';

const LoadingSpinnerNotificationCard = ({ text }) => {
  return (
    <div className="loading-spinner-notifiaction-card">
      {text && <div className="lsnc-text">{text}</div>}
      <div className="loading-spinner-wrapper">
        <div className="loading-spinner"></div>
      </div>
    </div>
  );
};

export { LoadingSpinnerNotificationCard };
