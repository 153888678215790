export const CONFIDENCE_LEVEL = Object.freeze({
  UNPROCESSED: 'unprocessed',
  UNSURE: 'unsure',
  PROCESSED: 'processed',
  PERFECT: 'perfect',
  fromString: string => {
    switch (string) {
      case 'unprocessed':
        return CONFIDENCE_LEVEL.UNPROCESSED;
      case 'unsure':
        return CONFIDENCE_LEVEL.UNSURE;
      case 'processed':
        return CONFIDENCE_LEVEL.PROCESSED;
      case 'perfect':
        return CONFIDENCE_LEVEL.PERFECT;
      default:
        throw new Error(`${string} can not be parsed to CONFIDENCE_LEVEL`);
    }
  },
});
