export const defaultState = {
  editMode: false,
  oldViewports: {},
  activeReportTool: false,
  studyListMode: 'alta',
};

const viewerStates = (state = defaultState, action) => {
  // switch (action.type) {
  //   case 'SET_EDIT_MODE':
  //     return {
  //       ...state,
  //       editMode: action.activated,
  //       oldViewports: action.activated ? action.viewports : {},
  //     };

  //   default:
  //     return state;
  // }
  return state;
};

export default viewerStates;
