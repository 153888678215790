/** Action Creators:
 *  https://redux.js.org/basics/actions#action-creators
 */

import {
  CLEAR_VIEWPORT,
  SET_ACTIVE_SPECIFIC_DATA,
  SET_SERVERS,
  SET_USER_PREFERENCES,
  SET_VIEWPORT,
  SET_VIEWPORT_ACTIVE,
  SET_VIEWPORT_LAYOUT,
  SET_VIEWPORT_LAYOUT_AND_DATA,
} from './constants/ActionTypes.js';

/**
 * The definition of a viewport layout.
 *
 * @typedef {Object} ViewportLayout
 * @property {number} numRows -
 * @property {number} numColumns -
 * @property {array} viewports -
 */

/**
 * VIEWPORT
 */
export const setViewportSpecificData = (
  viewportIndex,
  viewportSpecificData
) => ({
  type: SET_VIEWPORT,
  viewportIndex,
  viewportSpecificData,
});

export const setViewportActive = viewportIndex => ({
  type: SET_VIEWPORT_ACTIVE,
  viewportIndex,
});

/**
 * @param {ViewportLayout} layout
 */
export const setLayout = ({ numRows, numColumns, viewports }) => ({
  type: SET_VIEWPORT_LAYOUT,
  numRows,
  numColumns,
  viewports,
});

/**
 * @param {number} layout.numRows
 * @param {number} layout.numColumns
 * @param {array} viewports
 */
export const setViewportLayoutAndData = (
  { numRows, numColumns, viewports },
  viewportSpecificData
) => ({
  type: SET_VIEWPORT_LAYOUT_AND_DATA,
  numRows,
  numColumns,
  viewports,
  viewportSpecificData,
});

export const clearViewportSpecificData = viewportIndex => ({
  type: CLEAR_VIEWPORT,
  viewportIndex,
});

export const setActiveViewportSpecificData = viewportSpecificData => ({
  type: SET_ACTIVE_SPECIFIC_DATA,
  viewportSpecificData,
});

/**
 * NOT-VIEWPORT
 */
export const setUserPreferences = state => ({
  type: SET_USER_PREFERENCES,
  state,
});

export const setGeneralPreferences = ({ generalPreferences }) => ({
  type: 'SET_GENERAL_PREFERENCES',
  generalPreferences,
});

export const setExtensionData = (extension, data) => ({
  type: 'SET_EXTENSION_DATA',
  extension,
  data,
});

export const setTimepoints = state => ({
  type: 'SET_TIMEPOINTS',
  state,
});

export const setMeasurements = state => ({
  type: 'SET_MEASUREMENTS',
  state,
});

export const setStudyData = (StudyInstanceUID, data) => ({
  type: 'SET_STUDY_DATA',
  StudyInstanceUID,
  data,
});

export const setSequenceSelectionState = (
  StudyInstanceUID,
  sequenceSelectionState
) => ({
  type: 'SET_SEQUENCE_SELECTION_STATE',
  StudyInstanceUID,
  sequenceSelectionState,
});

export const setExtensionAccessible = (StudyInstanceUID, extension, ready) => ({
  type: 'SET_EXTENSION_ACCESSIBLE',
  StudyInstanceUID,
  extension,
  ready,
});

export const setServers = servers => ({
  type: SET_SERVERS,
  servers,
});

export const setEditMode = (activated, viewports) => ({
  type: 'SET_EDIT_MODE',
  activated,
  viewports,
});

export const setActiveSegmentation = activeSegmentation => ({
  type: 'SET_ACTIVE_SEGMENTATION',
  activeSegmentation,
});

const actions = {
  /**
   * VIEWPORT
   */
  setViewportActive,
  setViewportSpecificData,
  setViewportLayoutAndData,
  setLayout,
  setEditMode,
  clearViewportSpecificData,
  setActiveViewportSpecificData,

  /**
   * NOT-VIEWPORT
   */
  setUserPreferences,
  setGeneralPreferences,
  setExtensionData,
  setTimepoints,
  setMeasurements,
  setStudyData,
  setSequenceSelectionState,
  setServers,
  setActiveSegmentation,
  setExtensionAccessible,
};

export default actions;
