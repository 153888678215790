import React from 'react';
import { useTranslation } from 'react-i18next';

import { InformationPanel, Icon } from '@ohif/ui';

import { useReportData } from '../context/reportContext';
import { infoPanelHeader, infoPanelTextBody } from './utils';

const IPSSScore = ({ ipssScore }) => {
  let indicatorArguments = { rotation: null, color: null };

  if (ipssScore >= 20) {
    indicatorArguments.rotation = -45;
    indicatorArguments.color = '#BD0026';
  } else if (ipssScore >= 8) {
    indicatorArguments.rotation = 0;
    indicatorArguments.color = '#DA7164';
  } else {
    indicatorArguments.rotation = 45;
    indicatorArguments.color = '#F0C693';
  }

  return (
    <>
      <span className="text-body">{ipssScore || '-'}</span>
      {ipssScore && (
        <Icon
          name="circle-arrow-right"
          style={{
            color: indicatorArguments.color,
            transform: `rotate(${indicatorArguments.rotation}deg)`,
            marginLeft: '10px',
          }}
        />
      )}
    </>
  );
};

const IIEFcore = ({ iiefScore }) => {
  let indicatorArguments = { rotation: null, color: null };

  if (iiefScore >= 26) {
    indicatorArguments.rotation = 90;
    indicatorArguments.color = '#FFFFB2';
  } else if (iiefScore >= 22) {
    indicatorArguments.rotation = 45;
    indicatorArguments.color = '#F0C693';
  } else if (iiefScore >= 17) {
    indicatorArguments.rotation = 0;
    indicatorArguments.color = '#DA7164';
  } else if (iiefScore >= 11) {
    indicatorArguments.rotation = -45;
    indicatorArguments.color = '#CC3945';
  } else {
    indicatorArguments.rotation = -90;
    indicatorArguments.color = '#BD0026';
  }

  return (
    <>
      <span className="text-body">{iiefScore || '-'}</span>
      {iiefScore && (
        <Icon
          name="circle-arrow-right"
          style={{
            color: indicatorArguments.color,
            transform: `rotate(${indicatorArguments.rotation}deg)`,
            marginLeft: '10px',
          }}
        />
      )}
    </>
  );
};

export const UrologicArea = () => {
  const { t } = useTranslation('ReportPanel');
  const {
    report: { urologicInfos },
  } = useReportData();

  return (
    <div className="panel-area">
      <div className="card-heading">{t('Urological parameters')}</div>
      <div className="card-table">
        <div className="card-row">
          <InformationPanel
            title={infoPanelHeader(t('IPSS-Score'))}
            body={<IPSSScore ipssScore={urologicInfos.ipssScore} />}
          />
          <InformationPanel
            title={infoPanelHeader(t('IIEF-Score'))}
            body={<IIEFcore iiefScore={urologicInfos.iiefScore} />}
          />
        </div>
        <div className="card-row">
          <InformationPanel
            title={infoPanelHeader(t('Palpation'))}
            body={infoPanelTextBody(urologicInfos.palpation)}
          />
          <InformationPanel
            title={infoPanelHeader(t('Ultrasound scan'))}
            body={infoPanelTextBody(urologicInfos.ultrasound)}
          />
        </div>
      </div>
    </div>
  );
};
