import { Image, StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';
import { useResources } from '../ResourceContextProvider';
import { OpenTable, PanelMainHeading } from './general';
import { calculateProstateVolumeInML } from './utils';

const styling = StyleSheet.create({
  justifyContent: 'space-between',
  flexGrow: 1,
  Image: {
    marginBottom: '5px',
  },
});

/**
 * Build the large table for the PSA history
 */
const buildLargePSATable = ({ report, segmentations }) => {
  const prostate = segmentations.find(_seg => _seg.segmentType === 'zone');
  const prostateVolume = calculateProstateVolumeInML({
    segmentation: prostate,
  });

  const psaTable = report.psaValues.psaSeries.map(_entry => {
    return [
      { key: 'Datum', value: _entry.testDate },
      { key: 'PSA', value: _entry.psaValue },
      {
        key: 'PSA-Dichte',
        value: `${(_entry.psaValue / prostateVolume.total).toFixed(2)} ng/ml`,
      },
    ];
  });

  const freePSA = report.psaValues.freePsaValue;
  const lastPSA =
    report.psaValues.psaSeries[report.psaValues.psaSeries.length - 1].psaValue;

  const freePSATable = [
    { key: 'Freies PSA', value: freePSA.toFixed(2) },
    {
      key: 'PSA-Quotient',
      value: `${((freePSA / lastPSA) * 100).toFixed(2)}%`,
    },
  ];

  return { psaTable, freePSATable };
};

const buildSmallPSATable = ({ report, segmentations }) => {
  const prostate = segmentations.find(_seg => _seg.segmentType === 'zone');
  const prostateVolume = calculateProstateVolumeInML({
    segmentation: prostate,
  });

  const freePSA = report.psaValues.freePsaValue;
  const lastPSA =
    report.psaValues.psaSeries[report.psaValues.psaSeries.length - 1].psaValue;

  const smallPSATable = [
    { key: 'PSA', value: `${lastPSA.toFixed(2)} ng/ml` },
    { key: 'f-PSA', value: freePSA ? `${freePSA.toFixed(2)} ng/ml` : '-' },
    {
      key: 'PSA-Quotient',
      value: `${((freePSA / lastPSA) * 100).toFixed(2)}%`,
    },
    {
      key: 'PSA-Dichte',
      value: `${(lastPSA / prostateVolume.total).toFixed(2)} ng/ml`,
    },
  ];

  return smallPSATable;
};

const PSAHistory = () => {
  const { chart, reportData } = useResources();
  const smallPSATable = buildSmallPSATable(reportData);

  return (
    <View style={styling}>
      <PanelMainHeading heading={'2. PSA-Verlauf'} />
      <Image source={chart} style={styling.Image}></Image>
      {/* {reportData.report.psaValues.freePsaValue && (
        <OpenTable content={freePSATable} />
      )}
      <ClosedTable content={psaTable} /> */}
      <View style={styling.Table}>
        <OpenTable content={smallPSATable} />
      </View>
    </View>
  );
};

export { PSAHistory };
