import React from 'react';

import { useTranslation } from 'react-i18next';

import { MultiOptionSelector } from '@ohif/ui';
import { useReportData } from '../reportContext';
import { arraysContainSameStrings } from './utils';

export const RecommendationArea = ({ selectedValues }) => {
  const {
    reportData: { segmentations },
    setReportData,
  } = useReportData();
  const [t] = useTranslation('ReportPanel');

  const choices = [
    'MRI control in 6 months',
    'MRI control in 12 months',
    'PSA value determination in 3 months',
    'PSA value determination in 6 months',
    'Prostate Biopsy',
    'HoLEP',
  ];

  const onSelect = _selectedValues => {
    const { apiInterface } = window.ohif.app;

    if (arraysContainSameStrings(selectedValues || [], _selectedValues)) return;

    apiInterface
      .patchReportRecommendation({
        studyInstanceUID: segmentations[0].studyInstanceUID,
        therapyRecommendation: _selectedValues,
      })
      .then(resp => {
        setReportData(prev => ({
          ...prev,
          report: {
            ...prev.report,
            recommendation: resp,
          },
        }));
      });
  };

  return (
    <div className="panel-area">
      <MultiOptionSelector
        t={t}
        choices={choices}
        singletons={[]}
        selectedValues={selectedValues}
        onSelect={onSelect}
      />
    </div>
  );
};
