import React, { useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { ScrollableArea } from '@ohif/ui';

const Dropzone = props => {
  const { files, setFiles, onUpload } = props;

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone();

  const dropzoneRef = useRef();

  useEffect(() => {
    if (!dropzoneRef.current) {
      return;
    }
    const className = `dropzone${isFocused ? ' isFocused' : ''}${
      isDragReject ? ' isDragReject' : ''
    }${isDragAccept ? ' isDragAccept' : ''}`;

    const dropzone = dropzoneRef.current;
    dropzone.className = className;
  }, [isFocused, isDragAccept, isDragReject]);

  useEffect(() => {
    if (onUpload) {
      return;
    }
    setFiles(acceptedFiles);
  }, [acceptedFiles]);

  const onClear = () => {
    setFiles(null);
  };

  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone', ref: dropzoneRef })}>
        <input {...getInputProps()} />
        <p>
          Drag and drop some files here, or{' '}
          <span style={{ fontWeight: 700 }}> click </span> to select files
        </p>
      </div>
      <aside>
        <div className="file-area-header">
          <h4>Files</h4>
          {files && files.length > 0 && (
            <button
              className={`clear-button ${onUpload ? 'non-active' : 'active'}`}
              type="reset"
              onClick={onClear}
            >
              Clear Files
            </button>
          )}
        </div>
        <ScrollableArea>
          <ul>
            {files && files.length > 0 ? (
              files.map(file => (
                <li key={file.path}>
                  {file.path} - {file.size} bytes
                </li>
              ))
            ) : (
              <span>There are currently no files to upload</span>
            )}
          </ul>
        </ScrollableArea>
      </aside>
    </section>
  );
};

export default Dropzone;
