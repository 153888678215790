import React from 'react';

import { useTranslation } from 'react-i18next';

import { MultiOptionSelector } from '@ohif/ui';
import { useReportData } from '../reportContext';
import { arraysContainSameStrings } from './utils';

export const MailArea = ({ selectedValues }) => {
  const {
    reportData: { segmentations },
    setReportData,
  } = useReportData();
  const [t] = useTranslation('ReportPanel');

  const choices = [
    'None',
    'DaVinci',
    'HoLEP',
    'Prostate biopsy',
    'TULSA PRO for BPH',
    'TULSA PRO for cancer',
    'PIRADS 3 + HoLEP',
  ];

  const onSelect = _selectedValues => {
    const { apiInterface } = window.ohif.app;

    if (arraysContainSameStrings(selectedValues || [], _selectedValues)) return;

    apiInterface
      .patchReportRecommendation({
        studyInstanceUID: segmentations[0].studyInstanceUID,
        mailType: _selectedValues,
      })
      .then(resp => {
        setReportData(prev => ({
          ...prev,
          report: {
            ...prev.report,
            recommendation: resp,
          },
        }));
      });
  };

  return (
    <div className="panel-area">
      <MultiOptionSelector
        t={t}
        choices={choices}
        singletons={['None']}
        selectedValues={selectedValues}
        onSelect={onSelect}
      />
    </div>
  );
};
