import { useContext } from 'react';
import { SegmentationPanelContext } from '../SegmentationPanelContext';

export const useEditingInfo = () => {
  const { segmentationState, setSegmentationState } = useContext(
    SegmentationPanelContext
  );

  const getEditingInfo = () => {
    return segmentationState.segmentationMetadata.getEditingInfo();
  };

  const updateSegmentationErrors = ({ segmentationErrors }) => {
    const newSegmentationMetadata = segmentationState.segmentationMetadata;
    newSegmentationMetadata
      .getEditingInfo()
      .setSegmentationErrors({ segmentationErrors });

    setSegmentationState(state => ({
      ...state,
      segmentationMetadata: newSegmentationMetadata,
    }));
  };

  const setComment = ({ comment }) => {
    const newSegmentationMetadata = segmentationState.segmentationMetadata;
    newSegmentationMetadata.getEditingInfo().setComment({ comment });

    setSegmentationState(state => ({
      ...state,
      segmentationMetadata: newSegmentationMetadata,
    }));
  };

  const setConfidenceLevel = ({ confidenceLevelString }) => {
    const newSegmentationMetadata = segmentationState.segmentationMetadata;
    newSegmentationMetadata
      .getEditingInfo()
      .setConfidenceLevel({ confidenceLevelString });

    setSegmentationState(state => ({
      ...state,
      segmentationMetadata: newSegmentationMetadata,
    }));
  };

  return {
    getEditingInfo,
    setComment,
    setConfidenceLevel,
    updateSegmentationErrors,
  };
};
