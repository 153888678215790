import React from 'react';

import { Svg } from '@ohif/ui';
import { useEffect } from 'react';

const rbgaArrayToExpression = rgbaArray => {
  return `rgba(${rgbaArray.join(',')})`;
};

const ProstateSVGCanvas = ({
  name,
  width,
  height,
  areaFillTable,
  setSvgString,
}) => {
  const fillAreas = () => {
    const container = document.getElementById(`report-prostate-svg-${name}`);

    areaFillTable.forEach(({ id, color }) => {
      try {
        var element = container.getElementById(id);
        element.style.fill = rbgaArrayToExpression(color);
        element.style.opacity = 0.5;
      } catch (e) {
        console.log(e);
      }
    });
  };

  useEffect(() => {
    fillAreas();

    const svgElement = document.getElementById(`report-prostate-svg-${name}`);
    if (svgElement) {
      const svgString = svgElement.outerHTML;
      setSvgString(svgString);
    }
  }, []);

  return (
    <div className="area">
      <Svg
        id={`report-prostate-svg-${name}`}
        name={name}
        width={width}
        height={height}
      />
    </div>
  );
};

export { ProstateSVGCanvas };
