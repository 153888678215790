import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';

const StudyListLoadingText = () => {
  const [t] = useTranslation('StudyList');
  return (
    <div className="loading-text">
      <div style={{ position: 'relative', minHeight: '200px' }}>
        <LoadingSpinner />
      </div>
    </div>
  );
};

export { StudyListLoadingText };
