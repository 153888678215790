import { CONFIDENCE_LEVEL } from '../constants/ConfidenceLevel';

export class EditingInfo {
  constructor({ editingInfo, possibleSegmentationErrors }) {
    this.comment = editingInfo.comment;
    this.confidenceLevel = CONFIDENCE_LEVEL.fromString(
      editingInfo.confidenceLevel
    );
    this.user = editingInfo.user;
    this.segmentationErrors = {
      possibleSegmentationErrors,
      selectedErrors: editingInfo.segmentationErrors || [],
    };
  }

  hasRealUserAssigned() {
    return this.user && this.user !== 'default-user';
  }

  setUser({ user }) {
    this.user = user;
  }

  getUser() {
    return this.user;
  }

  getComment() {
    if (!this.comment) {
      return '';
    }

    return this.comment;
  }

  setComment({ comment }) {
    if (!comment) {
      comment = '';
    }
    this.comment = comment;
  }

  getConfidenceLevel() {
    if (!this.confidenceLevel) {
      return CONFIDENCE_LEVEL.UNPROCESSED;
    }

    return this.confidenceLevel;
  }

  setConfidenceLevel({ confidenceLevelString }) {
    const newConfidenceLevel = CONFIDENCE_LEVEL.fromString(
      confidenceLevelString
    );
    this.confidenceLevel = newConfidenceLevel;
  }

  confidenceLevelWasSet() {
    return this.confidenceLevel !== CONFIDENCE_LEVEL.UNPROCESSED;
  }

  getSegmentationErrors() {
    return this.segmentationErrors;
  }

  setSegmentationErrors({ segmentationErrors }) {
    this.segmentationErrors = segmentationErrors;
  }

  toJson() {
    return {
      comment: this.comment,
      confidenceLevel: this.confidenceLevel,
      segmentationErrors: this.segmentationErrors.selectedErrors,
    };
  }
}
