import React, { useState, useEffect } from 'react';
import './PanelHeader.styl';

const formatDateString = dateString => {
  const parsedDate = new Date(dateString);
  return parsedDate.toLocaleDateString('de-DE', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

const PanelHeader = props => {
  const { timepoints, Button } = props;

  const [timePoint, setTimePoint] = useState('01. Januar 2000');

  useEffect(() => {
    const timepoint = timepoints[0];
    if (!timepoint || !timepoint.PatientID) {
      return;
    }
    setTimePoint(formatDateString(timepoint.earliestDate));
  }, [timepoints]);

  return (
    <div className="PanelHeader">
      <div className="PanelHeaderItem">
        <div className="timepointLabel">Study Date</div>
        <div className="timepointDate">{timePoint}</div>
      </div>
      {Button && Button}
    </div>
  );
};

export default PanelHeader;
