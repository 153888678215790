import { StudentTableRow } from '@ohif/ui';
import {
  AdditionalSegmentationPanelComponents,
  getSegmentationTypeConfig,
} from '../../../extensions/dicom-segmentation/src/config/AnnotationSegmentPanelConfig';
import { studentTemplateBuilder } from '../../ui/src/components/studyList/studyListTemplates';

export const ZoneConfigProvider = () => {
  return {
    layoutOption: 'center',
    defaultViewportLayout: {},
    customModalStyle: {},
    studyList: {
      tableFilterBuilder: studentTemplateBuilder,
      TableRow: StudentTableRow,
      settings: {
        respectUserRoles: false,
        findEditableStudies: true,
        showStudiesWOReport: false,
        removeUnusableStudies: false,
      },
      showStudySkipper: true,
    },
    segmentations: {
      showLesions: false,
      showZones: true,
      segmentationPanelConfigProvider: getSegmentationTypeConfig,
    },
    studyContextArea: {
      showStudyContext: false,
      showMarkAsInvalid: false,
      segmentationStudyContext: () => [],
    },
    canCreateReport: false,
    additionalSegmentationPanelComponents: AdditionalSegmentationPanelComponents,
  };
};
