import React, { useEffect, useState } from 'react';

import { utils } from '@ohif/core';

const { studyMetadataManager } = utils;

const hasStudyLoaded = studyInstanceUID => {
  const studyMetadata = studyMetadataManager.get(studyInstanceUID);
  return studyMetadata.hasLoaded();
};

export const useLoadingStudy = studies => {
  const studyInstanceUID = studies.length && studies[0].StudyInstanceUID;

  const [isLoading, setIsLoading] = useState(
    !(studyInstanceUID && hasStudyLoaded(studyInstanceUID))
  );

  useEffect(() => {
    document.addEventListener('studyLoaded', () => setIsLoading(false));

    return () => {
      document.removeEventListener('studyLoaded', noop);
    };
  }, []);

  return isLoading;
};

const noop = () => {};
