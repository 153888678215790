import OHIFCornerstoneExtension from '@ohif/extension-cornerstone';
import cornerstoneTools from 'cornerstone-tools';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { hot } from 'react-hot-loader/root';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { OidcProvider } from 'redux-oidc';
import ReportExtension from '../../../extensions/report-tool';
import SequenceSelectionExtension from '../../../extensions/sequence-selection';
import APIInterface from '../../../server';

import {
  DialogProvider,
  ErrorBoundary,
  LoggerProvider,
  ModalProvider,
  OHIFModal,
  SnackbarProvider,
} from '@ohif/ui';

import {
  CommandsManager,
  ExtensionManager,
  HotkeysManager,
  LoggerService,
  MeasurementService,
  ServicesManager,
  UIDialogService,
  UIModalService,
  UINotificationService,
  redux,
  utils,
} from '@ohif/core';

import i18n from '@ohif/i18n';

// TODO: This should not be here
//import './config';
import { setConfiguration } from './config';

/** Utils */
import {
  getUserManagerForOpenIdConnectClient,
  initWebWorkers,
} from './utils/index.js';

/** Extensions */
import { GenericViewerCommands, MeasurementsPanel } from './appExtensions';

/** Viewer */
import OHIFStandaloneViewer from './OHIFStandaloneViewer';

/** Store */
import store from './store';
import { getActiveContexts } from './store/layout/selectors.js';

/** Contexts */
import SequenceSelectionManager from '../../../extensions/sequence-selection/classes/SequenceSelectionManager';
import ViewerConfigProvider from '../config/ViewerConfigProvider';
import { AppProvider, CONTEXTS, useAppContext } from './context/AppContext';
import UserManagerContext from './context/UserManagerContext';
import WhiteLabelingContext from './context/WhiteLabelingContext';

/** ~~~~~~~~~~~~~ Application Setup */
const commandsManagerConfig = {
  getAppState: () => store.getState(),
  getActiveContexts: () => getActiveContexts(store.getState()),
};

/** Managers */
const commandsManager = new CommandsManager(commandsManagerConfig);
const servicesManager = new ServicesManager();
const hotkeysManager = new HotkeysManager(commandsManager, servicesManager);
const sequenceSelectionManager = new SequenceSelectionManager();
let apiInterface;
let extensionManager;
/** ~~~~~~~~~~~~~ End Application Setup */

// TODO[react] Use a provider when the whole tree is React
window.store = store;

window.ohif = window.ohif || {};
window.ohif.app = {
  commandsManager,
  hotkeysManager,
  servicesManager,
  extensionManager,
  sequenceSelectionManager,
  apiInterface,
};

class App extends Component {
  static propTypes = {
    config: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({
        routerBasename: PropTypes.string.isRequired,
        oidc: PropTypes.array,
        whiteLabeling: PropTypes.shape({
          createLogoComponentFn: PropTypes.func,
        }),
        extensions: PropTypes.array,
      }),
    ]).isRequired,
    defaultExtensions: PropTypes.array,
  };

  static defaultProps = {
    config: {
      showStudyList: true,
      oidc: [],
      extensions: [],
    },
    defaultExtensions: [],
  };

  _appConfig;
  _userManager;

  constructor(props) {
    super(props);

    const { config, defaultExtensions } = props;

    const appDefaultConfig = {
      disableMeasurementPanel: true,
      showStudyList: true,
      cornerstoneExtensionConfig: {},
      extensions: [],
      routerBasename: '/',
    };

    this._appConfig = {
      ...appDefaultConfig,
      ...(typeof config === 'function' ? config({ servicesManager }) : config),
    };

    const {
      servers,
      hotkeys: appConfigHotkeys,
      cornerstoneExtensionConfig,
      extensions,
      oidc,
      segmentations,
    } = this._appConfig;

    setConfiguration(this._appConfig);

    this.initUserManager(oidc);
    _initServices([
      UINotificationService,
      UIModalService,
      UIDialogService,
      MeasurementService,
      LoggerService,
    ]);
    _initExtensions(
      [
        SequenceSelectionExtension,
        ...defaultExtensions,
        ...extensions,
        ReportExtension,
      ],
      cornerstoneExtensionConfig,
      this._appConfig
    );
    _initAPIInterface();

    /*
     * Must run after extension commands are registered
     * if there is no hotkeys from localStorage set up from config.
     */
    _initHotkeys(appConfigHotkeys);
    _initSegmentationSettings(segmentations);
    _initServers(servers);
    _initSequenceSelectionManager();
    initWebWorkers();
  }

  render() {
    const { whiteLabeling, routerBasename } = this._appConfig;
    const {
      UINotificationService,
      UIDialogService,
      UIModalService,
      MeasurementService,
      LoggerService,
    } = servicesManager.services;

    if (this._userManager) {
      return (
        <ErrorBoundary context="App">
          <OidcProvider store={store} userManager={this._userManager}>
            <Provider store={store}>
              <AppProvider config={this._appConfig}>
                <I18nextProvider i18n={i18n}>
                  <UserManagerContext.Provider value={this._userManager}>
                    <Router basename={routerBasename}>
                      <WhiteLabelingContext.Provider value={whiteLabeling}>
                        <LoggerProvider service={LoggerService}>
                          <SnackbarProvider service={UINotificationService}>
                            <DialogProvider service={UIDialogService}>
                              <ViewerConfigProvider
                                configTarget={this._appConfig.userMode}
                              >
                                <ModalProvider
                                  modal={OHIFModal}
                                  service={UIModalService}
                                >
                                  <OHIFStandaloneViewer
                                    userManager={this._userManager}
                                  />
                                </ModalProvider>
                              </ViewerConfigProvider>
                            </DialogProvider>
                          </SnackbarProvider>
                        </LoggerProvider>
                      </WhiteLabelingContext.Provider>
                    </Router>
                  </UserManagerContext.Provider>
                </I18nextProvider>
              </AppProvider>
            </Provider>
          </OidcProvider>
        </ErrorBoundary>
      );
    }

    return (
      <ErrorBoundary context="App">
        <Provider store={store}>
          <AppProvider config={this._appConfig}>
            <I18nextProvider i18n={i18n}>
              <Router basename={routerBasename}>
                <WhiteLabelingContext.Provider value={whiteLabeling}>
                  <LoggerProvider service={LoggerService}>
                    <SnackbarProvider service={UINotificationService}>
                      <DialogProvider service={UIDialogService}>
                        <ModalProvider
                          modal={OHIFModal}
                          service={UIModalService}
                        >
                          <OHIFStandaloneViewer />
                        </ModalProvider>
                      </DialogProvider>
                    </SnackbarProvider>
                  </LoggerProvider>
                </WhiteLabelingContext.Provider>
              </Router>
            </I18nextProvider>
          </AppProvider>
        </Provider>
      </ErrorBoundary>
    );
  }

  initUserManager(oidc) {
    if (oidc && !!oidc.length) {
      const firstOpenIdClient = this._appConfig.oidc[0];

      const { protocol, host } = window.location;
      const { routerBasename } = this._appConfig;
      const baseUri = `${protocol}//${host}${routerBasename}`;

      const redirect_uri = firstOpenIdClient.redirect_uri || '/callback';
      const silent_redirect_uri =
        firstOpenIdClient.silent_redirect_uri || '/silent-refresh.html';
      const post_logout_redirect_uri =
        firstOpenIdClient.post_logout_redirect_uri || '/';

      const openIdConnectConfiguration = Object.assign({}, firstOpenIdClient, {
        redirect_uri: _makeAbsoluteIfNecessary(redirect_uri, baseUri),
        silent_redirect_uri: _makeAbsoluteIfNecessary(
          silent_redirect_uri,
          baseUri
        ),
        post_logout_redirect_uri: _makeAbsoluteIfNecessary(
          post_logout_redirect_uri,
          baseUri
        ),
      });

      this._userManager = getUserManagerForOpenIdConnectClient(
        store,
        openIdConnectConfiguration
      );
    }
  }
}

function _initServices(services) {
  servicesManager.registerServices(services);
}

/**
 * @param
 */
function _initExtensions(extensions, cornerstoneExtensionConfig, appConfig) {
  extensionManager = new ExtensionManager({
    commandsManager,
    servicesManager,
    appConfig,
    api: {
      contexts: CONTEXTS,
      hooks: {
        useAppContext,
      },
    },
  });

  const requiredExtensions = [
    GenericViewerCommands,
    [OHIFCornerstoneExtension, cornerstoneExtensionConfig],
  ];

  if (appConfig.disableMeasurementPanel !== true) {
    /* WARNING: MUST BE REGISTERED _AFTER_ OHIFCornerstoneExtension */
    requiredExtensions.push(MeasurementsPanel);
  }

  const mergedExtensions = requiredExtensions.concat(extensions);
  extensionManager.registerExtensions(mergedExtensions);
}

function _initAPIInterface() {
  apiInterface = new APIInterface();
  window.ohif.app = { ...window.ohif.app, apiInterface };
}

function _initSegmentationSettings(config) {
  const { localStorage } = redux;
  const {
    configuration,
    state,
    setters: { colorForSegmentIndexOfColorLUT },
  } = cornerstoneTools.getModule('segmentation');
  const localState = localStorage.loadState();

  // Dupe colorLUT
  if (state.colorLutTables.length <= 1) {
    state.colorLutTables.push([...state.colorLutTables[0]]);
  }
  if (config && config.colorLUT) {
    const { lesion, zone } = config.colorLUT;

    if (lesion) {
      lesion.forEach((color, index) => {
        colorForSegmentIndexOfColorLUT(0, index + 1, color);
      });
    }

    if (zone) {
      zone.forEach((color, index) => {
        colorForSegmentIndexOfColorLUT(1, index + 1, color);
      });
    }
  }

  if (localState && Object.keys(localState).includes('segmentationConfig')) {
    for (let _config in localState['segmentationConfig']) {
      configuration[_config] = localState['segmentationConfig'][_config];
    }
  } else {
    const {
      outlineWidth,
      shouldRenderInactiveLabelmaps,
      outlineAlpha,
      fillAlpha,
    } = config.settings;

    configuration.shouldRenderInactiveLabelmaps = shouldRenderInactiveLabelmaps;
    configuration.outlineWidth = outlineWidth;
    configuration.outlineAlpha = outlineAlpha;
    configuration.fillAlpha = fillAlpha;
  }
}

function _initSequenceSelectionManager() {
  sequenceSelectionManager.init();
}

/**
 *
 * @param {Object} appConfigHotkeys - Default hotkeys, as defined by app config
 */
function _initHotkeys(appConfigHotkeys) {
  // TODO: Use something more resilient
  // TODO: Mozilla has a special library for this
  const userPreferredHotkeys = JSON.parse(
    localStorage.getItem('hotkey-definitions') || '{}'
  );

  // TODO: hotkeysManager.isValidDefinitionObject(/* */)
  const hasUserPreferences =
    userPreferredHotkeys && Object.keys(userPreferredHotkeys).length > 0;
  if (hasUserPreferences) {
    hotkeysManager.setHotkeys(userPreferredHotkeys);
  } else {
    hotkeysManager.setHotkeys(appConfigHotkeys);
  }

  hotkeysManager.setDefaultHotKeys(appConfigHotkeys);
}

function _initServers(servers) {
  if (servers) {
    utils.addServers(servers, store);
  }
}

function _isAbsoluteUrl(url) {
  return url.includes('http://') || url.includes('https://');
}

function _makeAbsoluteIfNecessary(url, base_url) {
  if (_isAbsoluteUrl(url)) {
    return url;
  }

  /*
   * Make sure base_url and url are not duplicating slashes.
   */
  if (base_url[base_url.length - 1] === '/') {
    base_url = base_url.slice(0, base_url.length - 1);
  }

  return base_url + url;
}

/*
 * Only wrap/use hot if in dev.
 */
const ExportedApp = process.env.NODE_ENV === 'development' ? hot(App) : App;

export default ExportedApp;
export { commandsManager, extensionManager, hotkeysManager, servicesManager };
