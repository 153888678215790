import { Font, StyleSheet } from '@react-pdf/renderer';
import RobotoBlack from './resources/fonts/Roboto-Black.ttf';
import RobotoBold from './resources/fonts/Roboto-Bold.ttf';
import RobotoLight from './resources/fonts/Roboto-Light.ttf';
import RobotoMedium from './resources/fonts/Roboto-Medium.ttf';
import RobotoRegular from './resources/fonts/Roboto-Regular.ttf';
import RobotoThin from './resources/fonts/Roboto-Thin.ttf';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoThin,
      fontWeight: 100,
    },
    {
      src: RobotoLight,
      fontWeight: 300,
    },
    {
      src: RobotoRegular,
      fontWeight: 400,
    },
    {
      src: RobotoMedium,
      fontWeight: 500,
    },
    {
      src: RobotoBold,
      fontWeight: 700,
    },
    {
      src: RobotoBlack,
      fontWeight: 900,
    },
  ],
});

const styling = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: '20px 15px',
    margin: 0,
    fontFamily: 'Roboto',
    fontSize: '11px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '4px',
  },
  rowElement: {
    width: '50%',
  },
  longRowElement: {
    width: '100%',
  },
});

export default styling;
