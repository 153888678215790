export const defaultState = {
  filteredStudies: [],
  filteredAppointments: [],
  selectedTab: 'study',
};

const tabs = (state = defaultState, action) => {
  return state;
};

export default tabs;
