import React, { useContext, useEffect, useState } from 'react';

export const OptionalStudyComponentsContext = React.createContext(null);

const requestResources = async ({ studyInstanceUID }) => {
  const { apiInterface } = window.ohif.app;
  return apiInterface.getOptionalComponents({ studyInstanceUID });
};

export const OptionalStudyComponentsContextProvider = ({
  children,
  studyInstanceUID,
}) => {
  const { apiInterface } = window.ohif.app;

  const [components, setComponents] = useState(null);

  useEffect(() => {
    requestResources({ studyInstanceUID })
      .then(response => {
        setComponents(response);
      })
      .catch(error => {
        setComponents([]);
      });
  }, []);

  const getComponentByType = ({ type }) => {
    return components.find(comp => comp.componentType === type);
  };

  const setComponentByType = ({ type, update }) => {
    setComponents(os => {
      const newState = [...os];
      const hasType = newState.find(item => item.componentType === type);

      if (hasType) {
        return newState.map(item => {
          if (item.componentType === type) {
            return update;
          }

          return item;
        });
      }

      return [...newState, update];
    });

    return apiInterface.patchOptionalComponents({
      studyInstanceUID,
      args: { componentType: update.componentType },
      body: update,
    });
  };

  return (
    <OptionalStudyComponentsContext.Provider
      value={{ components, getComponentByType, setComponentByType }}
    >
      {components && children}
    </OptionalStudyComponentsContext.Provider>
  );
};

export const useOptionalComponents = () => {
  return useContext(OptionalStudyComponentsContext);
};
