class UINotificationCompatibleError extends Error {
  constructor(title, message, type = 'error') {
    super(message);
    this.type = type;
    this.message = message;
    this.title = typeof title !== 'undefined' ? title : 'Error';
  }
}

class ServerError extends Error {
  constructor(title, message, type = 'error') {
    super(message);
    this.type = type;
    this.message = message;
    this.title = typeof title !== 'undefined' ? title : 'Error';
  }
}

class ResourceError extends Error {
  constructor(title, message, type = 'error') {
    super(message);
    this.type = type;
    this.message = message;
    this.title = typeof title !== 'undefined' ? title : 'Error';
  }
}

export { UINotificationCompatibleError, ServerError, ResourceError };
