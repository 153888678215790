import React from 'react';

import { useTranslation } from 'react-i18next';

import './EditingInformation.styl';
import { CurrentEditingInformation } from './CurrentEditingInformation';
import { EvaluationButtonGroup } from './EvaluationButtonGroup';
import { FurtherEditingInformationSection } from './FurtherEditingInformationSection';

export const EditingInformation = () => {
  const [t] = useTranslation('SegmentationPanel');

  return (
    <div className="segment-panel-box editing-info">
      <span className="heading small">{t('Editing Information')}</span>
      <CurrentEditingInformation />
      <EvaluationButtonGroup />
      <FurtherEditingInformationSection />
    </div>
  );
};
