import OHIF, { metadata } from '@ohif/core';
import React, { useEffect, useState } from 'react';
import { useReportData } from '../../../report/reportContext';

import { MRIPreviewImage } from './MRIPreviewImage';

import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import setActiveLabelmap from '../../../utils/setActiveLabelMap';
import { HeatmapPreviewImage } from './HeatmapPreviewImage';

const { studyMetadataManager } = OHIF.utils;

export const HEATMAP_CONFIG = {
  CI: 'Heatmap CI',
  GS6: 'Heatmap GS6',
  CSPC: 'Heatmap csPCa',
};

const hasVoi = image => {
  return (
    image._data &&
    image._data.metadata &&
    image._data.metadata.WindowCenter &&
    image._data.metadata.WindowWidth
  );
};

const getSegmentCenter = (labelmaps2D, segmentNumber) => {
  const validIndexList = [];

  labelmaps2D.forEach((labelMap2D, index) => {
    if (labelMap2D.segmentsOnLabelmap.includes(segmentNumber)) {
      validIndexList.push(index);
    }
  });

  const avg = array => array.reduce((a, b) => a + b, 0) / array.length;
  const average = avg(validIndexList);
  const closest = validIndexList.reduce((prev, curr) => {
    return Math.abs(curr - average) < Math.abs(prev - average) ? curr : prev;
  });

  return closest;
};

const restoreLesionVisibility = studyInstanceUID => {
  const studyData = studyMetadataManager.get(studyInstanceUID);
  const displaySet = studyData.findDisplaySet(
    ds => ds.Modality === 'SEG' && ds.SeriesDescription === 'Lesion'
  );

  const { state } = cornerstoneTools.getModule('segmentation');
  const studies = studyMetadataManager.all();

  const referencedDisplaySet = metadata.StudyMetadata.getReferencedDisplaySet(
    displaySet,
    studies
  );
  const referencedImageId = studyData.getFirstImageId(
    referencedDisplaySet.displaySetInstanceUID
  );

  const targetState = state.series[referencedImageId];
  targetState.labelmaps3D.forEach(lm => {
    const { segmentsHidden } = lm;

    for (let i = 1; i < segmentsHidden.length; i++) {
      segmentsHidden[i] = false;
    }
  });

  return;
};

const getLesionInformation = segmentations => {
  const lesionSegmentation = segmentations.find(
    seg => seg.segmentType === 'lesion'
  );

  const studyData = studyMetadataManager.get(
    lesionSegmentation.studyInstanceUID
  );
  const displaySet = studyData.findDisplaySet(
    ds => ds.Modality === 'SEG' && ds.SeriesDescription === 'Lesion'
  );

  const { state } = cornerstoneTools.getModule('segmentation');
  const studies = studyMetadataManager.all();

  const referencedDisplaySet = metadata.StudyMetadata.getReferencedDisplaySet(
    displaySet,
    studies
  );
  const referencedImageId = studyData.getFirstImageId(
    referencedDisplaySet.displaySetInstanceUID
  );

  const getCenteredSegments = () => {
    const { activeLabelmapIndex, labelmaps3D } = state.series[
      referencedImageId
    ];
    const labelmaps2D = labelmaps3D[activeLabelmapIndex].labelmaps2D;
    return lesionSegmentation.segments.map((seg, index) =>
      getSegmentCenter(labelmaps2D, index + 1)
    );
  };

  const toggleVisibility = segmentNumber => {
    const { activeLabelmapIndex, labelmaps3D } = state.series[
      referencedImageId
    ];

    const { segmentsHidden } = labelmaps3D[activeLabelmapIndex];

    for (let i = 1; i < segmentsHidden.length; i++) {
      segmentsHidden[i] = i !== segmentNumber;
    }
    return;
  };

  if (
    !displaySet.isLoaded ||
    state.series[referencedImageId].activeLabelmapIndex !==
      displaySet.labelmapIndex
  ) {
    return new Promise(async resolve => {
      const studyDicts = studies.map(s => s._data);

      if (!displaySet.isLoaded) {
        await displaySet.load(referencedDisplaySet, studyDicts);
      }

      if (
        state.series[referencedImageId].activeLabelmapIndex !==
        displaySet.labelmapIndex
      ) {
        setActiveLabelmap(referencedDisplaySet, studyDicts, displaySet);
        document.dispatchEvent(
          new CustomEvent('selectedSegmentationFromThumbnail', {
            detail: { seriesInstanceUID: displaySet.SeriesInstanceUID },
          })
        );
      }

      const centeredSlices = getCenteredSegments();
      resolve(
        lesionSegmentation.segments.map((seg, index) => ({
          ...seg,
          center: centeredSlices[index],
          renderSegment: () => toggleVisibility(index + 1),
        }))
      );
    });
  }

  return new Promise(resolve => {
    const centeredSlices = getCenteredSegments();
    resolve(
      lesionSegmentation.segments.map((seg, index) => ({
        ...seg,
        center: centeredSlices[index],
        renderSegment: () => toggleVisibility(index + 1),
      }))
    );
  });
};

const getImageStack = ({
  studyData,
  displaySet,
  customRenderOptions = null,
}) => {
  const renderOptions = customRenderOptions;

  if (hasVoi(displaySet.images[0])) {
    renderOptions.voi = {
      windowCenter: displaySet.images[0]._data.metadata.WindowCenter,
      windowWidth: displaySet.images[0]._data.metadata.WindowWidth,
    };
  }

  const stack = studyData.getAllImageIds(displaySet.displaySetInstanceUID);

  return { stack, renderOptions };
};

/**
 * Initializes a null-dict for all the heatmaps.
 */
const containingHeatmaps = ({ renderHeatmaps, studyInstanceUID }) => {
  if (!renderHeatmaps) {
    return {};
  }

  const studyData = studyMetadataManager.get(studyInstanceUID);

  const foundHeatmaps = {};

  for (const [sequence, config] of Object.entries(HEATMAP_CONFIG)) {
    const displaySet = studyData.findDisplaySet(
      ds => ds.SeriesDescription === config
    );

    if (displaySet) {
      foundHeatmaps[sequence] = null;
    }
  }

  return foundHeatmaps;
};

/**
 * Get the display sets for the heatmaps.
 * If renderHeatmaps is false, return null.
 */
const getHeatmaps = ({ studyInstanceUID }) => {
  const studyData = studyMetadataManager.get(studyInstanceUID);

  const foundHeatmaps = {};

  for (const [sequence, config] of Object.entries(HEATMAP_CONFIG)) {
    const displaySet = studyData.findDisplaySet(
      ds => ds.SeriesDescription === config
    );

    if (displaySet) {
      const customRenderOptions = {
        voi: { windowCenter: 127, windowWidth: 255 },
        color: true,
      };

      foundHeatmaps[sequence] = getImageStack({
        studyData,
        displaySet,
        customRenderOptions,
      });
    }
  }

  return foundHeatmaps;
};

const getSequences = ({ study }) => {
  const { sequenceSelectionManager } = window.ohif.app;

  const sequenceSelection = sequenceSelectionManager.getSequenceMapping(
    study.studyInstanceUID
  );

  const studyData = studyMetadataManager.get(study.studyInstanceUID);

  const getImageData = ({ modality }) => {
    const sequenceMap = sequenceSelection.selectAtomicMapping({
      modality: modality,
      orientation: 'tra',
    });

    const displaySet = studyData.findDisplaySet(
      ds => ds.OriginalSeriesInstanceUID === sequenceMap.seriesInstanceUID()
    );

    return getImageStack({
      studyData,
      displaySet,
      customRenderOptions: { color: false },
    });
  };

  const t2wData = getImageData({ modality: 't2w' });
  const adcData = getImageData({ modality: 'adc' });
  const dwiData = getImageData({ modality: 'dwi' });

  return {
    t2: t2wData,
    adc: adcData,
    dwi: dwiData,
  };
};

const MRIPreviewStack = ({
  index,
  data,
  setDataURL,
  setRendered,
  renderSegment,
}) => {
  const [renderedModalities, setRenderedModalities] = useState(0);

  const enableViewport = viewportElement => {
    if (viewportElement) {
      cornerstone.enable(viewportElement);
    }
  };

  const disableViewport = viewportElement => {
    if (viewportElement) {
      cornerstone.disable(viewportElement);
    }
  };

  useEffect(() => {
    renderSegment();
  }, []);

  useEffect(() => {
    if (renderedModalities === 3) {
      setRendered(index + 1);
    }
  }, [renderedModalities]);

  return (
    <>
      {data.heatmaps && (
        // Render Heatmaps
        <>
          {data.heatmaps.CI && (
            <HeatmapPreviewImage
              label="CI"
              data={{
                ...data.heatmaps.CI,
                center: data.center,
              }}
              enableViewport={enableViewport}
              disableViewport={disableViewport}
              setDataURL={url => {
                setDataURL({
                  modality: 'CI',
                  segmentIndex: index,
                  isHeatmap: true,
                })(url);
              }}
            />
          )}
          {data.heatmaps.GS6 && (
            <HeatmapPreviewImage
              label="GS6"
              data={{
                ...data.heatmaps.GS6,
                center: data.center,
              }}
              enableViewport={enableViewport}
              disableViewport={disableViewport}
              setDataURL={url => {
                setDataURL({
                  modality: 'GS6',
                  segmentIndex: index,
                  isHeatmap: true,
                })(url);
              }}
            />
          )}
          {data.heatmaps.CSPC && (
            <HeatmapPreviewImage
              label="CSPC"
              data={{
                ...data.heatmaps.CSPC,
                center: data.center,
              }}
              enableViewport={enableViewport}
              disableViewport={disableViewport}
              setDataURL={url => {
                setDataURL({
                  modality: 'CSPC',
                  segmentIndex: index,
                  isHeatmap: true,
                })(url);
              }}
            />
          )}
        </>
      )}
      {renderedModalities === 0 && (
        <MRIPreviewImage
          label="T2"
          data={{
            ...data.t2,
            center: data.center,
          }}
          enableViewport={enableViewport}
          disableViewport={disableViewport}
          setDataURL={url => {
            setDataURL({ modality: 't2', segmentIndex: index })(url);
            setRenderedModalities(1);
          }}
        />
      )}
      {renderedModalities === 1 && (
        <MRIPreviewImage
          label="ADC"
          data={{
            ...data.adc,
            center: data.center,
          }}
          enableViewport={enableViewport}
          disableViewport={disableViewport}
          setDataURL={url => {
            setDataURL({ modality: 'adc', segmentIndex: index })(url);
            setRenderedModalities(2);
          }}
        />
      )}
      {renderedModalities === 2 && (
        <MRIPreviewImage
          label="DWI"
          data={{
            ...data.dwi,
            center: data.center,
          }}
          enableViewport={enableViewport}
          disableViewport={disableViewport}
          setDataURL={url => {
            setDataURL({ modality: 'dwi', segmentIndex: index })(url);
            setRenderedModalities(3);
          }}
        />
      )}
    </>
  );
};

export const MRIPreviewImages = ({ setMRIImages }) => {
  const [segments, setSegments] = useState(null);
  const {
    reportData: { segmentations, study },
    reportSettings: { renderHeatmaps },
  } = useReportData();
  const [rendered, setRendered] = useState(0);

  const { t2, adc, dwi } = getSequences({ study });

  useEffect(() => {
    const { configuration } = cornerstoneTools.getModule('segmentation');
    const oldConfig = { ...configuration };

    // change configuration for better visualization
    configuration.fillAlpha = 0.2;
    configuration.outlineAlpha = 1.0;

    getLesionInformation(segmentations).then(segments => {
      setMRIImages(os => {
        const images = segments.map(_ => ({
          t2: null,
          adc: null,
          dwi: null,
          heatmaps: containingHeatmaps({
            renderHeatmaps,
            studyInstanceUID: study.studyInstanceUID,
          }),
        }));
        return { ...os, images };
      });
      setSegments(segments);
    });

    return () => {
      // restore configuration
      configuration.fillAlpha = oldConfig.fillAlpha;
      configuration.outlineAlpha = oldConfig.outlineAlpha;

      restoreLesionVisibility(study.studyInstanceUID);
    };
  }, []);

  const setDataURL = ({ modality, segmentIndex, isHeatmap = false }) => {
    return dataURL =>
      setMRIImages(os => {
        if (isHeatmap) {
          os.images[segmentIndex]['heatmaps'][modality] = dataURL;
        } else {
          os.images[segmentIndex][modality] = dataURL;
        }
        return { ...os };
      });
  };

  const _getHeatmaps = () => {
    return renderHeatmaps
      ? getHeatmaps({
          studyInstanceUID: study.studyInstanceUID,
        })
      : null;
  };

  return (
    <div className="mri-preview-images">
      {segments &&
        segments.map((segment, index) => (
          <div
            key={segment.trackingUID}
            className="row"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {rendered === index ? (
              <MRIPreviewStack
                index={index}
                setDataURL={setDataURL}
                data={{
                  t2,
                  adc,
                  dwi,
                  heatmaps: _getHeatmaps(),
                  center: segment.center,
                }}
                setRendered={setRendered}
                renderSegment={segment.renderSegment}
              />
            ) : null}
          </div>
        ))}
    </div>
  );
};
