import { Icon } from '@ohif/ui';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  useSegmentationConfig,
  useSegmentationHeader,
} from '../../../../context/SegmentationPanelContext';
import './SegmentationCardHeader.styl';
import { useMergeSegment } from '../../../../context/hooks/useMergeSement';

export const SegmentationCardHeader = ({ editModeActive }) => {
  const [t] = useTranslation('SegmentationPanel');
  const {
    toggleSegmentationVisibility,
    isSegmentationVisible,
    numberOfSegments,
    toggleEditMode,
    onCreateSegment,
    onDeleteSegmentation,
  } = useSegmentationHeader();

  const {
    segmentMergeModeActive,
    onMergeSegmentModeToggle,
  } = useMergeSegment();

  const { segmentationConfig, isT2 } = useSegmentationConfig();

  return (
    <div className="segment-panel-box segmentation-card-header">
      <span className="title">{t('Segments')}</span>

      <div className="icon-group">
        {segmentationConfig.enableMergeSegment && (
          <Icon
            className={classnames('icon-group-element icon', {
              inactive: !isT2,
            })}
            name={segmentMergeModeActive ? 'merge-exit' : 'merge-enter'}
            onClick={() => {
              onMergeSegmentModeToggle();
            }}
          />
        )}
        {segmentationConfig.enableDeleteSegmentation && (
          <Icon
            className={classnames('icon-group-element icon', {
              inactive: !isT2,
            })}
            name="trash"
            onClick={() => {
              onDeleteSegmentation();
            }}
          />
        )}
        {segmentationConfig.enableAdd && (
          <Icon
            className={classnames('icon-group-element icon', {
              inactive: !isT2,
            })}
            name="plus"
            onClick={() => {
              onCreateSegment();
            }}
          />
        )}
        {segmentationConfig.enableGlobalEdit && (
          <Icon
            className={classnames('icon-group-element icon', {
              inactive: !isT2,
            })}
            name={editModeActive ? 'killEdit' : 'edit'}
            onClick={() => toggleEditMode({ editModeActive: !editModeActive })}
          />
        )}
        <Icon
          className={classnames('icon-group-element icon', {
            inactive: !isT2,
          })}
          name={isSegmentationVisible ? 'eye' : 'eye-closed'}
          onClick={() => {
            toggleSegmentationVisibility({ isVisible: false });
          }}
        />
        <span className="icon-group-element segment-count">
          {numberOfSegments}
        </span>
      </div>
    </div>
  );
};
