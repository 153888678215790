import React, { useEffect, useRef, useState } from 'react';

import { LoadingSpinner } from '@ohif/ui';

import { PDFViewer, usePDF } from '@react-pdf/renderer';

import { Report } from './Report';
import { ResourceContextProvider } from './ResourceContextProvider';
import { PSADiagram } from './resources/PSADiagram';

import { useOptionalComponents } from '../context/OptionalStudyComponentsContext';
import { useReportData } from '../report/reportContext';
import './PDFViewerModal.styl';
import { LesionLocationUI } from './resources/LesionLocationUI';
import { MRIPreviewImages } from './resources/MRIPreviewImages/MRIPreviewImages';

const blobToBase64 = blob => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => resolve(reader.result.split(',')[1]);
    reader.onerror = error => reject(error);
  });
};

const CustomDownloadLink = ({ document, filename, studyInstanceUID }) => {
  const { apiInterface } = window.ohif.app;

  const [instance, _] = usePDF({ document });
  const [blobData, setBlobData] = useState(null);

  if (instance.loading) return <div>Loading ...</div>;

  if (instance.blob) {
    blobToBase64(instance.blob)
      .then(base64Encoding => {
        setBlobData(base64Encoding);
      })
      .catch(e => {
        console.error(e);
      });
  }

  return (
    <a
      id="download-link"
      href={instance.url}
      download={filename}
      onClick={() => {
        apiInterface.postReportPdfData({
          studyInstanceUID,
          filename: filename.split('.pdf')[0],
          file: blobData,
        });
      }}
    >
      Download here
    </a>
  );
};

export const PDFViewerModal = () => {
  const reportData = useReportData();
  const { getComponentByType } = useOptionalComponents();

  const [render, setRender] = useState(false);
  const [finishedRendering, setFinishedRendering] = useState(false);
  const [svgString, setSvgString] = useState({});
  const [mriImages, setMRIImages] = useState({
    images: null,
    finishedRendering: false,
  });

  const imageRef = useRef(null);

  const svgStringComplete = () => {
    const incString = s => Object.keys(svgString).includes(s);

    const hasBase = incString('base');
    const hasApex = incString('apex');
    const hasMid = incString('mid');
    const hasCor = incString('coronal');
    const hasSV = incString('SV');
    return hasBase && hasApex && hasMid && hasCor && hasSV;
  };

  useEffect(() => {
    if (render) return;
    if (
      imageRef.current &&
      finishedRendering &&
      svgStringComplete() &&
      mriImages.finishedRendering
    ) {
      setRender(true);
    }
  }, [imageRef, svgString, finishedRendering, mriImages]);

  useEffect(() => {
    if (!mriImages.images || mriImages.finishedRendering) return;

    const hasEmptyValues = mriImages.images.reduce((acc, curr) => {
      return (
        acc ||
        (Object.values(curr).some(val => val === null) ||
          Object.values(curr.heatmaps).some(val => val === null))
      );
    }, false);

    if (hasEmptyValues) return;

    setMRIImages({ ...mriImages, finishedRendering: true });
  }, [mriImages]);

  const MemoizedPSADiagram = React.useMemo(() => {
    return (
      <div className="diagram">
        <PSADiagram
          reference={imageRef}
          setFinishedRendering={setFinishedRendering}
        />
      </div>
    );
  }, []);

  const MemoizedMRIPreviewImages = React.useMemo(() => {
    return (
      <div className="mri-images">
        <MRIPreviewImages setMRIImages={setMRIImages} />
      </div>
    );
  }, []);

  const ConnectedReport = () => {
    return (
      <ResourceContextProvider
        resources={{
          chart: imageRef.current.toBase64Image(),
          lesionUI: svgString,
          reportData: reportData.reportData, // We have to wrap the context again because the Viewer consumes the given contexts
          getComponentByType,
          mriImages: mriImages.images,
          userManager: reportData.userManager,
          user: reportData.user,
        }}
      >
        <Report title={getReportFileName()} />
      </ResourceContextProvider>
    );
  };

  const getReportFileName = () => {
    const { patientName, studyDate } = reportData.reportData.study;

    return `${patientName}_${studyDate}_MRT_Prostata_Befund.pdf`;
  };

  return (
    <div className="report-modal">
      {render ? (
        <>
          <CustomDownloadLink
            document={<ConnectedReport />}
            filename={getReportFileName()}
            studyInstanceUID={reportData.reportData.study.studyInstanceUID}
          />
          <PDFViewer style={{ width: '100%', height: '80vh' }}>
            <ConnectedReport />
          </PDFViewer>
        </>
      ) : (
        <LoadingSpinner />
      )}

      <div className="hidden">
        {MemoizedPSADiagram}
        <div className="lesion-ui">
          <LesionLocationUI setSvgString={setSvgString} />
        </div>
        {MemoizedMRIPreviewImages}
      </div>
    </div>
  );
};
