import React, { useState } from 'react';
import classnames from 'classnames';
import { useMergeSegment } from '../../../../../context/hooks/useMergeSement';

export const SegmentMergeSection = ({ segmentMeta }) => {
  const [checked, setCheckecd] = useState(false);
  const {
    addSegmentByTrackingUID,
    removeSegmentByTrackingUID,
  } = useMergeSegment();

  return (
    <div className="merge-section">
      <div
        className={classnames('custom-checkbox', {
          checked: checked,
        })}
        onClick={() => {
          if (checked) {
            removeSegmentByTrackingUID(segmentMeta.trackingUID);
          } else {
            addSegmentByTrackingUID(segmentMeta.trackingUID);
          }
          setCheckecd(!checked);
        }}
      ></div>
    </div>
  );
};
