import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';

export const DEFAULT_SIZE = 512;
export const REFRESH_VIEWPORT_TIMEOUT = 100;
export const MAX_TEXTURE_SIZE = 10000;

export const loadImage = ({ stack, renderOptions, center, viewportElement }) =>
  new Promise(resolve => {
    if (viewportElement) {
      const viewport = {
        translation: {
          x: 0,
          y: 0,
        },
      };

      if ('voi' in renderOptions) {
        viewport.voi = renderOptions.voi;
      }

      cornerstone.loadImage(stack[center]).then(image => {
        const vpStack = {
          currentImageIdIndex: center,
          imageIds: stack,
        };

        cornerstoneTools.addStackStateManager(viewportElement, ['stack']);
        cornerstoneTools.addToolState(viewportElement, 'stack', vpStack);

        cornerstone.setViewport(viewportElement, viewport);
        cornerstone.displayImage(viewportElement, image);
        cornerstone.resize(viewportElement, true);

        const newWidth = Math.min(
          DEFAULT_SIZE || image.width,
          MAX_TEXTURE_SIZE
        );
        const newHeight = Math.min(
          DEFAULT_SIZE || image.height,
          MAX_TEXTURE_SIZE
        );

        resolve({ image, width: newWidth, height: newHeight });
      });
    }
  });

export const updateViewportPreview = (viewportElement, downloadCanvas) =>
  new Promise(resolve => {
    const viewport = cornerstone.getViewport(viewportElement);

    cornerstone.fitToWindow(viewportElement);
    // zoom in on center
    cornerstone.setViewport(viewportElement, {
      ...viewport,
      scale: viewport.scale * 2,
    });

    viewportElement.addEventListener(
      'cornerstoneimagerendered',
      function updateViewport(event) {
        const enabledElementRaw = cornerstone.getEnabledElement(event.target);
        const enabledElement = enabledElementRaw.element;

        const type = 'image/png';
        const dataUrl = downloadCanvas.toDataURL(type, 1);

        let newWidth = enabledElement.offsetHeight;
        let newHeight = enabledElement.offsetWidth;

        if (newWidth > DEFAULT_SIZE || newHeight > DEFAULT_SIZE) {
          const multiplier = DEFAULT_SIZE / Math.max(newWidth, newHeight);
          newHeight *= multiplier;
          newWidth *= multiplier;
        }

        resolve({ dataUrl, width: newWidth, height: newHeight });

        viewportElement.removeEventListener(
          'cornerstoneimagerendered',
          updateViewport
        );
      }
    );
  });
