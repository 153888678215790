import { constants } from '@ohif/core';

const { CERTAINTY_LEVEL, AVAILABLE_SEGMENTATIONS } = constants;

const SegmentProcessingOptionLayout = {
  options: [
    CERTAINTY_LEVEL.UNAVAILABLE,
    CERTAINTY_LEVEL.UNPROCESSED,
    CERTAINTY_LEVEL.UNSURE,
    CERTAINTY_LEVEL.PROCESSED,
    CERTAINTY_LEVEL.PERFECT,
  ],
  singletons: [
    CERTAINTY_LEVEL.UNAVAILABLE,
    CERTAINTY_LEVEL.UNPROCESSED,
    CERTAINTY_LEVEL.UNSURE,
    CERTAINTY_LEVEL.PROCESSED,
    CERTAINTY_LEVEL.PERFECT,
  ],
  optionGroups: [],
  allowMultiSelect: false,
};

const AvailableSegmentationsOptionLayout = {
  options: [
    AVAILABLE_SEGMENTATIONS.NONE,
    AVAILABLE_SEGMENTATIONS.ZONE,
    AVAILABLE_SEGMENTATIONS.LESION,
    AVAILABLE_SEGMENTATIONS.LESIONZONE,
  ],
  singletons: [
    AVAILABLE_SEGMENTATIONS.NONE,
    AVAILABLE_SEGMENTATIONS.ZONE,
    AVAILABLE_SEGMENTATIONS.LESION,
    AVAILABLE_SEGMENTATIONS.LESIONZONE,
  ],
  optionGroups: [],
  allowMultiSelect: true,
};

const selectOptionLayout = layoutName => {
  switch (layoutName) {
    case 'SegmentProcessingState': {
      return SegmentProcessingOptionLayout;
    }
    case 'AvailableSegmentationsState': {
      return AvailableSegmentationsOptionLayout;
    }
  }
};

export { selectOptionLayout };
