import React from 'react';

import './ProgressBar.styl';

const ProgressBar = props => {
  const { progress } = props;
  return (
    <div className="progress-bar">
      <div className="loading-percentage">{`${Math.round(progress)}%`} </div>
      <div className="loading-bar" style={{ width: `${progress}%` }}></div>
    </div>
  );
};

export default ProgressBar;
