export const PROCESSING_STATUS = Object.freeze({
  UNPROCESSED: 'unprocessed',
  PROCESSING: 'processing',
  DONE: 'done',
});

export const SEGMENTATION_TYPE = Object.freeze({
  LESION: 'lesion',
  ZONE: 'zone',
});

export const SEGMENTATION_ORIGIN = Object.freeze({
  AUTOMATIC: 'automatic',
  SEMIAUTOMATIC: 'semiautomatic',
  MANUAL: 'manual',
});

export const ORIENTATION = Object.freeze({
  TRANSVERSAL: 'transversal',
  SAGITAL: 'sagital',
  CORONAL: 'coronal',
});

export const CLASS_MAPPING_LOOKUP_TABLE = {
  BG: 'Background',
  CI: 'Cronic Inflammation',
  GS6: 'Gleason 6',
  csPCa: 'Clinically significant prostate cancer',
  GS7a: 'Gleason 7a',
  GS7b: 'Gleason 7b',
  GS8: 'Gleason 8',
  GS9_10: 'Gleason 9-10',
};

export const CLASS_MAPPING_LOOKUP_TABLE_SHORT = {
  CI: 'Inflammation',
  GS6: 'Gleason 6',
  csPCa: '≥ Gleason 7a',
};
