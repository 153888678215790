import React from 'react';

import './OpenSelector.styl';

export const OpenSelector = ({ options, onSelect }) => {
  return (
    <div className="open-selector">
      {options.map((opt, index) => {
        return (
          <div
            key={index}
            className="open-selector-opt"
            onClick={() => {
              onSelect(opt);
            }}
          >
            {opt}
          </div>
        );
      })}
    </div>
  );
};
