import React from 'react';

import cornerstoneTools from 'cornerstone-tools';

import SegmentationSettings from '../../SegmentationSettings/SegmentationSettings';
import { useSegmentationSettings } from '../../../context/SegmentationPanelContext';

export const ConncetedSegmentationSettings = ({ isVTK, onBack }) => {
  const { updateConfiguration } = useSegmentationSettings();

  const { configuration } = cornerstoneTools.getModule('segmentation');

  const disabledConfigurationFields = [
    'outlineAlpha',
    'shouldRenderInactiveLabelmaps',
  ];
  return (
    <SegmentationSettings
      disabledFields={isVTK ? disabledConfigurationFields : []}
      configuration={configuration}
      onBack={onBack}
      onChange={updateConfiguration}
    />
  );
};
